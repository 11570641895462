import { matchPath } from 'react-router-dom';
import { FormFieldDataType } from 'models/enum';
import { ContentItem } from 'models/ui';
import { AppConstant, CaseStudyPaths, SimChartStandalone } from 'constants/app.constant';
import { getCaseStudyCatalog } from 'services/case.study.service';
import { getCourse } from 'services/course.service';
import flatten from 'lodash/flatten';

export const resetCaseStudyDemoStorage = () => {
  const globalSimsNGState = JSON.parse(localStorage.getItem(AppConstant.PERSIST_KEY));
  if (globalSimsNGState?.instructorState?.caseStudyDemo) {
    globalSimsNGState.instructorState.caseStudyDemo = null;
    localStorage.setItem(AppConstant.PERSIST_KEY, JSON.stringify(globalSimsNGState));
  }
};

export const resetNewAssignmentStorage = (value) => {
  const globalSimsNGState = JSON.parse(localStorage.getItem(AppConstant.PERSIST_KEY));
  if (globalSimsNGState?.instructorState?.newAssignment) {
    globalSimsNGState.instructorState.newAssignment = { ...globalSimsNGState.instructorState.newAssignment, ...value };
    localStorage.setItem(AppConstant.PERSIST_KEY, JSON.stringify(globalSimsNGState));
  }
};

export const isCaseStudyStep = (pathname): boolean => {
  if (!pathname) return false;
  return !!matchPath(pathname, CaseStudyPaths)?.path;
};

export const getCaseStudyProductsMapping = async (courseId) => {
  const [courseResponse, catalogResponse] = await Promise.all([getCourse(courseId), getCaseStudyCatalog(courseId)]);
  const course = courseResponse.data ?? null;
  const catalog = catalogResponse.data ?? [];

  const isStandalone = String(course?.courseName).includes(SimChartStandalone);

  const isbnProductMapping = course?.entitlements?.reduce(
    (map, item) => {
      const productTitle = JSON.parse(item?.data)?.title;
      Object.assign(
        map,
        { mapping: { ...map.mapping, [item.isbn]: productTitle } },
        { products: map.products.concat({ [String(productTitle).replace(/[-’ ]/g, '_').toUpperCase()]: productTitle }) }
      );
      return map;
    },
    { mapping: {}, products: [] }
  );

  const caseStudyProductMapping = catalog?.reduce((map, { attributes }) => {
    Object.assign(map, { [attributes?.contentId]: { isbn: attributes?.isbn, name: isbnProductMapping.mapping[attributes?.isbn] } });
    return map;
  }, {});

  return { isStandalone, caseStudyProductMapping, products: isbnProductMapping.products };
};

export const getStandaloneProductItems = (products): ContentItem[] => {
  return products.map((product, index) => {
    const [key, value] = flatten(Object.entries(product));
    return {
      dataType: FormFieldDataType.CHOICE,
      displayOrder: index,
      id: String(key).toLocaleLowerCase(),
      name: String(value),
      label: String(value),
      onChange: null,
      isActive: true,
      selected: false,
      value: String(key)
    };
  });
};
