/* eslint-disable @typescript-eslint/no-explicit-any */
import { ELSCommonConfig, ELSLoggingService, ELSRestClientHelper } from '@els/els-ui-common-react';
import { Event } from 'constants/app.constant';
import { eventHelper } from './index';

const eolsUrl = () => ELSCommonConfig.buildUrl;
const facadeUrl = () => `${ELSCommonConfig.buildUrl}/api/simchart`;

export const eolsApi = {
  get: (url, options = {}) => ELSRestClientHelper.get(`${eolsUrl()}${url}`, options),
  post: (url, options = {}) => ELSRestClientHelper.post(`${eolsUrl()}${url}`, options),
  put: (url, options = {}) => ELSRestClientHelper.put(`${eolsUrl()}${url}`, options),
  patch: (url, options = {}) => ELSRestClientHelper.patch(`${eolsUrl()}${url}`, options),
  delete: (url, options = {}) => ELSRestClientHelper.delete(`${eolsUrl()}${url}`, options)
};

export const facadeApi = {
  get: (url, options = {}) => ELSRestClientHelper.get(`${facadeUrl()}${url}`, options),
  post: (url, options = {}) => ELSRestClientHelper.post(`${facadeUrl()}${url}`, options),
  put: (url, options = {}) => ELSRestClientHelper.put(`${facadeUrl()}${url}`, options),
  patch: (url, options = {}) => ELSRestClientHelper.patch(`${facadeUrl()}${url}`, options),
  delete: (url, options = {}) => ELSRestClientHelper.delete(`${facadeUrl()}${url}`, options)
};

export const showApiError = (message: string) => {
  eventHelper.eventEmitter.emit(Event.AppError.API, message);
};

export const apiErrorHandler = (error: Error, defaultIfError: object) => {
  ELSLoggingService.error('', error.message, error);
  showApiError(error.message);
  return defaultIfError;
};

export const withApiErrorHandler = (promiseFunction: () => Promise<any>, defaultIfError: object) =>
  promiseFunction()
    .then((res) => res.data)
    .catch((err) => apiErrorHandler(err, defaultIfError));

export const getDefaultRequestHeaders = (headers = {}) => {
  return {
    headers: {
      accept: 'application/json, text/plain, */*',
      ...headers
    }
  };
};
