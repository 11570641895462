import { DateFormatByLocale, DateTimeFormatByLocale, GRAPH_DATE_TIME_FORMAT, GRAPH_DATE_TIME_FORMAT_ANZ } from 'constants/app.constant';

export const CommonLanguageKeys = {
  GRAPH_DATE_TIME_FORMAT: 'GRAPH_DATE_TIME_FORMAT',
  MARK_ALL_THAT_APPLY: 'Mark all that apply',
  SAVE_AND_CONTINUE: 'Save and Continue',
  CANCEL: 'Cancel',
  DATE_TIME_FORMAT: 'DATE_TIME_FORMAT',
  DATE_FORMAT: 'DATE_FORMAT',
  DATE_LABEL: 'Date (mm-dd-yyyy)',
  TIME_LABEL: 'Time'
};

export type CommonMessageType = Partial<Record<keyof typeof CommonLanguageKeys, string>>;

export const CommonEnUs: CommonMessageType = {
  [CommonLanguageKeys.GRAPH_DATE_TIME_FORMAT]: GRAPH_DATE_TIME_FORMAT,
  [CommonLanguageKeys.DATE_FORMAT]: DateFormatByLocale.en_US,
  [CommonLanguageKeys.DATE_TIME_FORMAT]: DateTimeFormatByLocale.en_US,
  [CommonLanguageKeys.DATE_LABEL]: 'Date (mm-dd-yyyy)'
};

export const CommonEnAu: CommonMessageType = {
  [CommonLanguageKeys.GRAPH_DATE_TIME_FORMAT]: GRAPH_DATE_TIME_FORMAT_ANZ,
  [CommonLanguageKeys.DATE_FORMAT]: DateFormatByLocale.en_AU,
  [CommonLanguageKeys.DATE_TIME_FORMAT]: DateTimeFormatByLocale.en_AU,
  [CommonLanguageKeys.DATE_LABEL]: 'Date (DD/MM/YYYY)'
};
