import convert from 'convert-units';
import { toInteger } from 'lodash';

export const toDigitDecimal = (number, digit = 2) => number.toFixed(digit);

export const convertCelsiusToFahrenheit = (celsius: string) => {
  const num = (parseFloat(celsius) * 9) / 5 + 32;
  return toDigitDecimal(num, 1);
};

export const convertFahrenheitToCelsius = (fahrenheit: string) => {
  const num = ((parseFloat(fahrenheit) - 32) * 5) / 9;
  return toDigitDecimal(num, 1);
};

export const convertInchToCm = (inch: number) => (inch * 2.54).toFixed(1);

export const convertCmToInch = (cm: number) => Math.round(convert(cm).from('cm').to('in'));

export const inchToFeetInch = (inchInput: number) => {
  let feet = Math.floor(inchInput / 12);
  let inch = Math.round(inchInput % 12);
  if (inch === 12) {
    feet += 1;
    inch = 0;
  }
  return [feet, inch];
};

export const convertHeight = (feetText: string, inchText: string, cmText: string, feetInchToCm: boolean) => {
  let inch;
  let cm;
  let newFeet;
  let newInch;
  if (feetInchToCm && !feetText && !inchText) {
    return { heightFeet: '', heightInch: '', heightCm: '', heightTotalInInches: '' };
  }
  if (!feetInchToCm && !cmText) {
    return { heightFeet: '', heightInch: '', heightCm: '', heightTotalInInches: '' };
  }

  if (feetInchToCm) {
    const feet = feetText ? parseInt(Number(feetText).toString(), 10) : 0;
    inch = inchText ? Math.round(parseFloat(inchText)) : 0;
    const totalInInches = feet * 12 + inch;
    cm = convertInchToCm(totalInInches);
    [newFeet, newInch] = inchToFeetInch(totalInInches);
    return { heightFeet: `${newFeet}`, heightInch: `${newInch}`, heightCm: `${cm}`, heightTotalInInches: `${totalInInches}` };
  }

  cm = parseFloat(cmText) || 0;
  inch = convertCmToInch(cm);
  [newFeet, newInch] = inchToFeetInch(inch);
  return { heightFeet: `${newFeet}`, heightInch: `${newInch}`, heightCm: `${cm}`, heightTotalInInches: `${inch}` };
};

export const ounceToGram = (ounce: number) => ounce * 28.349523125;

export const gramToOunce = (gram: number) => gram / 28.349523125;

export const ounceToPoundOunce = (ounceInput: number) => {
  let pound = parseInt((ounceInput / 16).toString(), 10);
  let ounce = Math.round(ounceInput % 16);
  if (ounce === 16) {
    pound += 1;
    ounce = 0;
  }
  return [pound, ounce];
};

export const ounceToKiloGram = (ounceInput: number) => {
  let gram = ounceToGram(ounceInput);
  const kg = parseInt((gram / 1000).toString(), 10);
  gram %= 1000;
  return [kg, gram];
};

export const gramToKgGram = (gramInput: number) => {
  const kg = parseInt((gramInput / 1000).toString(), 10);
  const gram = gramInput % 1000;
  return [kg, gram];
};

export const gramToPoundOunce = (gramInput: number) => {
  const ounce = gramToOunce(gramInput);
  return ounceToPoundOunce(ounce);
};

export const convertWeight = (poundText: string, ounceText: string, kgText: string, gramText: string, poundOunceToKgGram: boolean) => {
  const result = { weightPound: '', weightOunce: '', weightKg: '', weightGram: '', weightTotalInOunces: '' };
  if (poundOunceToKgGram && poundText === '' && ounceText === '') {
    return result;
  }
  if (!poundOunceToKgGram && kgText === '' && gramText === '') {
    return result;
  }
  if (poundOunceToKgGram) {
    const pound = toInteger(poundText) || 0;
    let ounce = Math.round(parseFloat(ounceText)) || 0;
    ounce = ounce < 16 ? pound * 16 + ounce : ounce;
    const [kg, gram] = ounceToKiloGram(ounce);
    const [newPound, newOunce] = ounceToPoundOunce(ounce);
    const totalInGrams = kg * 1000 + gram;
    const totalInOunces = gramToOunce(totalInGrams);

    return { weightPound: `${newPound}`, weightOunce: `${newOunce}`, weightKg: `${kg}`, weightGram: `${toDigitDecimal(gram, 2)}`, weightTotalInOunces: `${totalInOunces}` };
  }
  if (!poundOunceToKgGram) {
    const kg = toInteger(kgText) || 0;
    let gram = parseFloat(gramText) || 0;
    gram = gram < 1000 ? kg * 1000 + gram : gram;
    const [pound, pounce] = gramToPoundOunce(gram);
    const [newKg, newGram] = gramToKgGram(gram);
    const totalInGrams = kg * 1000 + gram;
    const totalInOunces = gramToOunce(totalInGrams);

    return { weightPound: `${pound}`, weightOunce: `${pounce}`, weightKg: `${newKg}`, weightGram: `${toDigitDecimal(newGram, 2)}`, weightTotalInOunces: `${totalInOunces}` };
  }
  return result;
};

export const getDisplayValue = (values: [string, string], type: string): string => {
  const units = {
    height: ["'", '"'],
    weight: [' lb', ' oz'],
    weightMetric: [' kg', ' g']
  };

  // if values doesn't have 2 entries or if the a type isn't one of the keys of units, exit early and return an empty string
  if (!Object.keys(units).includes(type)) {
    return '';
  }

  // map over the values array
  // if a value is 0 return an empty string
  // else return the value as a string and append the appropriate unit based on the type and the value's index
  // lastly join the new array values with space (and convert to a string)
  return values
    .map((value, index) => (!parseFloat(value) ? '' : `${value}${units[type][index]}`))
    .join(' ')
    .trim();
};
