import { isUndefined, orderBy } from 'lodash';
import { matchPath } from 'react-router';
import { v4 } from 'uuid';
import { ChartFragment, Record, Section } from 'models/api-response';
import { FormFieldDataType, FormFieldInputType, FragmentType } from 'models/enum';
import { BuildFragmentRecordProps, FormFieldBuilderProps } from 'models/helper';
import {
  ChartFieldContent,
  ChartMetaFormField,
  ChartMetaFormFieldParams,
  ContentItem,
  CreateChartMetaFormFieldParams,
  ErrorField,
  HandleCloseSidebarFuncProps,
  NavigationItem,
  ScaleRollUpContent
} from 'models/ui';
import { ChartLabel, DefaultScaleRollUpContent, SectionNavIdPatterns } from 'constants/app.constant';
import { chartService } from 'services';
import { FormField } from 'components/features/chart/order-entry/pharmacy/constants';

// recursively look up the latest height and weight values
export const getLatestHeightWeightValues = (fragments: ChartFragment[]) => {
  const activeFragments = fragments.filter((fragment) => fragment.active);
  const result = { height: null, weight: null, weightDateRecorded: null };

  // if there are no more records, return the accumulator
  if (activeFragments.length === 0) {
    return result;
  }

  const sortedFramentsByChartingAt = orderBy(activeFragments, [(fragment) => new Date(fragment.chartingAt)], ['desc']);

  const fragmentHasHeight = sortedFramentsByChartingAt.find((fragment) => fragment.chartData.height !== null);
  const fragmentHasWeight = sortedFramentsByChartingAt.find((fragment) => fragment.chartData.weight !== null);

  // if the accumulator's height or weight is null and the currentRecord has it, reassign the accumulator's value
  if (fragmentHasHeight) {
    result.height = {
      centimeters: fragmentHasHeight.chartData.height.centimeters,
      feet: fragmentHasHeight.chartData.height.feet,
      inches: fragmentHasHeight.chartData.height.inches
    };
  }
  if (fragmentHasWeight) {
    if (fragmentHasWeight.chartData.weight.kilograms) {
      const kg: number = fragmentHasWeight.chartData.weight.kilograms * 1000;
      const g = Number(fragmentHasWeight.chartData.weight.grams);
      result.weight = (kg + g).toString();
    } else {
      result.weight = `${fragmentHasWeight.chartData.weight.grams}`;
    }
    result.weightDateRecorded = fragmentHasWeight.chartingAt;
  }

  return result;
};

/**
 * Takes a name and type and returns a ChartMetaFormField object
 *
 * The single parameter object allows us to use object destructuring syntax,
 *  set default values, and ensure the proper use of the function by enforcing
 *  the use of named parameters.
 */
export const createFormField = ({
  contentIds = [],
  scaleContent = [],
  disabled = false,
  label = '',
  name,
  onChangePreValidation = undefined,
  onChangePostValidation = undefined,
  onGroupChange = undefined,
  type,
  value = '',
  defaultValue = '',
  errorLabel = '',
  parentLabel = '',
  fieldIdsToClearValue,
  labelMultilineTooltip
}: CreateChartMetaFormFieldParams): ChartMetaFormField => {
  const defaultMultiselectDropdownLabel = ChartLabel.MARK_ALL_THAT_APPLY;
  let finalLabel = label;
  if (type === FormFieldInputType.MULTISELECT_DROPDOWN) {
    finalLabel = finalLabel || defaultMultiselectDropdownLabel;
  }
  return {
    contentIds,
    scaleContent,
    disabled,
    errors: [],
    inputType: type,
    isRequired: false,
    label: finalLabel,
    name,
    onChangePreValidation,
    onChangePostValidation,
    onGroupChange,
    value,
    defaultValue: defaultValue || value,
    warning: [],
    errorLabel,
    parentLabel,
    fieldIdsToClearValue,
    chartContent: [],
    labelMultilineTooltip
  };
};

/**
 * Used for looking up labels from an Array
 *
 * @param {array} chartFields - the array of chart fields objects from a chartMetadata response
 * @param {string} value - a string or an array of strings used for looking up the correct chartField content object from chartFields
 * @return {string} a string of one or more comma separated labels
 */
export const findContentFromMetadata = (chartFields: ChartFieldContent[], value: string | string[]): string => {
  // if the Array is empty or value is undefined
  // return an empty string
  if (chartFields.length === 0 || isUndefined(value)) {
    return '';
  }

  /**
   * if value is an Array
   * map over the items in value
   * find corresponding chartField object from Array
   * add it's label to an array
   * join labels array with a comma and space and return
   */
  if (value instanceof Array) {
    return value.map((currentValue) => chartFields.find((chartField) => chartField.value === currentValue)?.label || currentValue).join(', ');
  }

  /**
   * if value is a String
   * find corresponding chartField object from Array
   * if chartField object was found return it's label
   * else return value
   */
  return chartFields.find((chartField) => chartField.value === value)?.label || value;
};

/**
 * Used for looking up labels from a Map
 *
 * @param {map} data - typically chartMetaContentMap
 * @param {string | array} value - a string or an array of strings used for looking up the correct content string from chartFields
 * @return {string} a string of one or more comma separated labels
 */
export const findContentFromMap = (data: Map<string, string>, value: string | string[]): string => {
  // if the Map is empty or value is undefined
  // return an empty string
  if (data.size === 0 || !value) {
    return '';
  }

  /**
   * if value is an Array
   * map over the items in value
   * find corresponding content from Map
   * join items in content array with a comma and space and return
   */
  if (value instanceof Array) {
    return value.map((key) => data.get(key) || key).join(', ');
  }

  /**
   * if value is a String
   * find corresponding content from Map
   * if content was found return it
   * else return value
   */
  return data.get(value) || value;
};

export const chartHasErrors = (formFields: Map<string, ChartMetaFormField>): ConstrainBoolean => {
  if (!formFields) return false;

  const formFieldErrors: ErrorField[] = [];

  formFields.forEach((field: ChartMetaFormField) => {
    formFieldErrors.push(...field.errors);
  });

  return formFieldErrors.length > 0;
};

const isMultiRecordsField = (formField: ChartMetaFormField): boolean => {
  return (
    formField.inputType === FormFieldInputType.MULTISELECT_DROPDOWN || formField.inputType === FormFieldInputType.CHECK_BOX || formField.inputType === FormFieldInputType.SCALE
  );
};

export const buildRecord = (formField: ChartMetaFormField, customTitle?: string): Record[] => {
  // if field is value field, or single choice select field
  // or checkbox with only 1 option (Exp: No Assessment Required checkbox)
  if ((formField.inputType === FormFieldInputType.CHECK_BOX && formField.chartContent?.length === 1) || !isMultiRecordsField(formField)) {
    return [chartService.buildRecordFromField(formField, customTitle)];
  }

  // if field is multiple choice or scale component field
  return chartService.buildRecordsFromField(formField);
};

interface BuildSectionParams {
  fields: string[];
  formFieldMap: Map<string, ChartMetaFormField>;
  parentSectionTitle?: string;
  sectionTitle: string;
  customFormFieldTitleMap?: Map<string, string>;
}

/**
 * Used for building sections to prep for saving chart data
 *
 * The single parameter object allows us to use object destructuring syntax,
 *  set default values, and ensure the proper use of the function by enforcing
 *  the use of named parameters.
 */
export const buildSection = ({ fields, formFieldMap, parentSectionTitle = undefined, sectionTitle, customFormFieldTitleMap }: BuildSectionParams): Section => {
  const allRecords = [];
  fields.forEach((field) => {
    if (formFieldMap.has(field)) {
      const formField: ChartMetaFormField = formFieldMap.get(field);
      allRecords.push(...buildRecord(formField, customFormFieldTitleMap?.get(field)));
    }
  });

  if (parentSectionTitle === undefined) {
    return {
      sectionTitle,
      records: allRecords
    };
  }
  return {
    parentSectionTitle,
    sectionTitle,
    records: allRecords
  };
};

export const buildBreadcrumbItems = (pathname: string, navItems: NavigationItem[], parentPath = '', breadcrumbs: NavigationItem[] = []) => {
  let matchedItem = navItems.find(({ path }) => matchPath(pathname, { path, exact: true, strict: true }));

  // match exactly
  if (matchedItem) return [].concat(breadcrumbs, [matchedItem]);

  // not match exactly
  // find parent match
  matchedItem = navItems.find(({ path }) => path !== parentPath && matchPath(pathname, { path }));

  // not match anything
  if (!matchedItem) return breadcrumbs;

  // not found child path
  if (!matchedItem.children) return breadcrumbs;

  // find exact child match
  return buildBreadcrumbItems(pathname, matchedItem.children, matchedItem.path, [].concat(breadcrumbs, [matchedItem]));
};

export const buildRecordChartPathName = (chartPathName: string, recordChartTitle: string): string => {
  const chartPathArray = chartPathName.split(' - ');
  chartPathArray.pop();
  chartPathArray.push(recordChartTitle);
  return chartPathArray.join(' - ');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFragmentRecords = (fragment: ChartFragment, sectionTitle: string, formField: string, defaultValue = []): any[] => {
  const section = fragment?.chartData?.records?.find((item) => item.sectionTitle === sectionTitle);
  const subRecords = section?.records?.filter((item) => item.formField === formField);
  return subRecords || defaultValue;
};

export const getFragmentValue = (fragment: ChartFragment, sectionTitle: string, formField: string, defaultValue = ''): string =>
  getFragmentRecords(fragment, sectionTitle, formField)[0]?.value || defaultValue;

export const getFragmentValueByKey = (fragment: ChartFragment, sectionTitle: string, formField: string, keyValue = 'value', defaultValue = ''): string =>
  getFragmentRecords(fragment, sectionTitle, formField)[0]?.[keyValue] || defaultValue;

export const getFragmentContentIds = (fragment: ChartFragment, sectionTitle: string, formField: string, defaultValue = []): string[] =>
  getFragmentRecords(fragment, sectionTitle, formField).map((subRecord) => subRecord.contentId) || defaultValue;

export const getScaleContent = (fragment: ChartFragment, sectionTitle: string, defaultValue = []) => {
  const section = fragment?.chartData?.records?.find((item) => item.sectionTitle === sectionTitle);
  return section?.records?.map((item) => ({ formFieldId: item.formField, label: item.title, score: item.content })) || defaultValue;
};

export const isChartLocked = (fragments: ChartFragment[], navId: string): boolean => {
  if (!fragments?.length || !navId) {
    return false;
  }
  return fragments.filter((fragment) => fragment.navElementId === navId && fragment.fragmentType === FragmentType.STATUS && fragment.locked === true).length > 0;
};

export const findFragmentByNavId = (fragments: ChartFragment[], navId: string, fragmentType = FragmentType.CHARTING): ChartFragment => {
  if (!fragments?.length) {
    return null;
  }
  return fragments.find((fragment) => fragment.navElementId === navId && fragment.fragmentType === fragmentType) || null;
};

// Returns fragment status of provided navId. If it is missing, returns fragment status of its parent.
export const findStatusFragment = (fragments: ChartFragment[], navId: string, parentNavId: string): ChartFragment => {
  if (!fragments?.length) {
    return null;
  }

  const statusFragments = fragments.filter((fragment) => fragment.fragmentType === FragmentType.STATUS);
  const statusFragment = statusFragments.find((fragment) => fragment.navElementId === navId);
  return statusFragment || statusFragments.find((fragment) => fragment.navElementId === parentNavId);
};

export const getSummaryChartIds = () => [SectionNavIdPatterns.SYSTEM_ASSESSMENTS, SectionNavIdPatterns.SPECIAL_CHARTS];

export const getScaleRollUpWrapperId = (scaleFormFieldId: string): string => {
  return `${scaleFormFieldId}-scaleRollUpWrapper`;
};

export const getScaleRollUpWrapper = (field: ChartMetaFormField) => {
  return field.chartContent?.find((content) => content.formFieldId === getScaleRollUpWrapperId(field.name));
};

export const getScaleRollUpContentHighlight = (field: ChartMetaFormField) => {
  return field.chartContent?.find((content) => content.dataType === FormFieldDataType.SCALE_ROLL_UP_HIGHLIGHT && !content.isHidden);
};

export const getWoundSavedValue = (fragment: ChartFragment, formField: string, key: string) => {
  const records = fragment?.chartData.records[0].records.filter((item) => item.formField === formField);
  return records?.map((item) => item[key]);
};

export const groupFragmentsById = (fragments: ChartFragment[]): Map<string, ChartFragment[]> => {
  const fragmentGroupMap = new Map();
  fragments.forEach((fragment) => {
    const id = fragment.groupFragmentRef || fragment.linkedFragmentId || fragment.fragmentId;
    const fragmentGroup = fragmentGroupMap.get(id) ?? [];
    fragmentGroup.push(fragment);
    fragmentGroupMap.set(id, fragmentGroup);
  });
  return fragmentGroupMap;
};

export const getNestedFieldId = (contentValue: string) => {
  return `${contentValue}Other`;
};

export const createFragmentId = (navId: string): string => `${navId}#${v4()}`;

export const getOrderDescription = (fragment: ChartFragment): string => {
  return fragment?.chartData[FormField.ORDER_ITEM_OTHER] || fragment?.chartData[FormField.ORDER_ITEM];
};

export const isUnscoredQuestion = (chart: ChartMetaFormField): boolean => {
  return chart.chartContent?.every((item) => Number(item.value) === 0);
};

export const getMedicationName = (formFieldValue: string, chartContent: ContentItem[]) => {
  const obj = chartContent.find((element) => element.value === formFieldValue);
  return obj.name;
};

// For getDrugInfoURL API call, which will only accept the first word of the medicine's name
export const filterDrugByFirstName = (drugName: string): string => {
  const firstWord = drugName.split(' ')[0];
  let name;
  if (firstWord.includes('/')) {
    name = firstWord.split('/')[0];
  } else if (firstWord.includes(',')) {
    name = firstWord.split(',')[0];
  } else {
    name = firstWord;
  }
  return name;
};

export const handleCloseSidebar = ({ sidebarProps, onCloseClick }: HandleCloseSidebarFuncProps) => {
  sidebarProps().handleCloseAttempt(onCloseClick);
};

// The generic type is not working. Need to fix it.
// It returns value as a string now. But we can cast it after calling this function.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRecordContent = <T = any>({ fragment, sectionTitle, formField }: { fragment: ChartFragment; sectionTitle: string; formField: string }): T | undefined => {
  const fragmentRecord = fragment?.chartData?.records?.find((item) => item.sectionTitle === sectionTitle);
  const contentRecord = fragmentRecord?.records?.find((item) => item.formField === formField);
  return contentRecord?.content ? (contentRecord.content as T) : undefined;
};
export const getSubRecords = ({ fragment, sectionTitle }: { fragment: ChartFragment; sectionTitle: string }) => {
  const fragmentRecord = fragment?.chartData?.records?.find((item) => item.sectionTitle === sectionTitle);
  return fragmentRecord?.records;
};

export const createRollUpContent = (totalScore: number | undefined): ScaleRollUpContent => {
  const riskLevel = DefaultScaleRollUpContent.find((item) => item.range.includes(totalScore));

  return {
    content: DefaultScaleRollUpContent,
    riskLevel: riskLevel?.level,
    total: totalScore
  };
};

/**
 * Used for building form fields to prep for rendering form fields
 * @param params - FormFieldBuilderProps<T>
 * @returns Map<string, ChartMetaFormField>
 */
export const formFieldsBuilder = <T = object>({ getFormFieldItems, formFieldItemOptions }: FormFieldBuilderProps<T>): Map<string, ChartMetaFormField> => {
  const dataMap = new Map<string, ChartMetaFormField>();

  getFormFieldItems(formFieldItemOptions).forEach(({ isHidden, name, label, ...item }: ChartMetaFormFieldParams): void => {
    if (!isHidden) {
      const translatedLabel = formFieldItemOptions?.['intl']?.formatMessage({ id: label }) || label;
      const formField = createFormField({ ...item, name, label: translatedLabel });
      dataMap.set(name, formField);
    }
  });

  return dataMap;
};

/**
 * Used for building fragment records to prep for saving chart data
 * @param params - BuildFragmentRecordProps
 * @returns ChartFragment
 */
export const buildFragmentRecords = ({ chartingTime, formFieldMap, getRecords, customRecordsCleaner }: BuildFragmentRecordProps): ChartFragment => {
  const { createBaseFragment, systemAssessment } = chartService;
  const records = getRecords(formFieldMap);
  const recordCleaner = customRecordsCleaner ?? systemAssessment.removeEmptyRecords;
  return {
    ...createBaseFragment({ chartingTime }),
    chartData: recordCleaner(records)
  };
};
