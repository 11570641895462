export const PharmacyLanguageKeys = {
  PHARMACY: {
    PHARMACY: 'Pharmacy',
    SPECIAL_DOSE: 'Special Dose',
    ORDER_START: 'Order Start',
    ORDER_STOP: 'Order Stop',
    ORDER_ITEM: 'Order Description',
    ORDER_TYPE: 'Pharmacy Order Type',
    ROUTE: 'Route for Administration',
    MEDICATION_DOSE: 'Medication Dose',
    SPECIAL_DOSE_DOSE: 'Dose',
    SPECIAL_DOSE_UNIT: 'Unit',
    MEDICATION_FORM: 'Medication Form',
    FREQUENCY: 'Frequency of Administration',
    SPECIAL_INSTRUCTIONS: 'Special Instructions'
  }
};

export type PharmacyMessageType = Partial<Record<keyof typeof PharmacyLanguageKeys, string>>;

export const PharmacyEnUs: PharmacyMessageType = {};

export const PharmacyEnAu: PharmacyMessageType = {};
