import { AppConstant } from 'constants/app.constant';

export const put = (key: string, value) => {
  let appCacheString = sessionStorage.getItem(AppConstant.CACHE_KEY);
  if (!appCacheString) {
    appCacheString = JSON.stringify({});
    sessionStorage.setItem(AppConstant.CACHE_KEY, appCacheString);
  }
  const appCache = JSON.parse(appCacheString);
  appCache[key] = value;
  sessionStorage.setItem(AppConstant.CACHE_KEY, JSON.stringify(appCache));
};

export const get = (key: string) => {
  const appCacheString = sessionStorage.getItem(AppConstant.CACHE_KEY);
  if (appCacheString) {
    const appCache = JSON.parse(appCacheString);
    return appCache[key];
  }
  return null;
};

export const withCache = (key: string, promiseFunction: Function, skipCache = false) => {
  const cachedValue = get(key);
  if (!skipCache && cachedValue) {
    return Promise.resolve({ data: cachedValue });
  }
  return promiseFunction().then((res) => {
    put(key, res.data);
    return res;
  });
};
