/* eslint-disable sonarjs/no-duplicate-string */
export const SafetyLanguageKeys = {
  SAFETY: {
    SAFETY: 'Safety',
    FALLS_RISK: 'Falls Risk',
    MENTAL_STATUS: 'Mental Status',
    ACTIONS_IF_PATIENT_IS_CONFUSED_DISORIENTED_AGITATED_DEPRESSED: 'Actions {text} if patient is: confused, disoriented, agitated, depressed',
    VISION: 'Vision',
    ACTIONS_IF_PATIENT_HAS_VISUAL_IMPAIRMENT: 'Actions {text} if patient has visual impairment',
    TOILETING: 'Toileting',
    ACTIONS_IF_PATIENT_HAS_CONFUSION_URINARY_OR_FAECAL_FREQUENCY_INCONTINENCE_URGENCY_NOCTURIA_OR_OTHER_TOILETING_ISSUES:
      'Actions {text} if patient has confusion, urinary or faecal frequency, incontinence, urgency, nocturia or other toileting issues',
    HAS_FALL_RISK_BEEN_DISCUSSED_AND_INTERVENTION_DEVELOPED:
      'Has falls risk been discussed and intervention developed in partnership with patient/ family/carer & resource information provided?',
    COMMENTS: 'Comments',
    ACTIONS_RELATED_TO: 'Actions {action} related to {relatedTo}',
    FALL_PRECAUTIONS: 'Fall Precautions',
    LEVEL_OF_SAFETY_PRECAUTIONS: 'Potential Harm to Self or Others: Level of Safety Precautions',
    NURSE_PATIENT_RELATIONSHIP_STRATEGIES_FOR_SAFETY: 'Nurse-Patient Relationship Strategies for Safety',
    SAFE_IN_MILIEU: 'Safety in Milieu',
    GENERAL_SAFETY: 'General Safety',
    ROOM_PLAN_GRADATION: 'Room Plan Gradation for Safety/De-escalation of Agitated Behaviors',
    PATIENT_RESPONSE_TO_SAFETY_MEASURES: 'Patient Response to Safety Measures',
    PLANNED: 'planned',
    TAKEN: 'taken'
  }
};

export type SafetyMessageType = Partial<Record<keyof typeof SafetyLanguageKeys, string>>;

export const SafetyEnUs: SafetyMessageType = {
  [SafetyLanguageKeys.SAFETY.FALL_PRECAUTIONS]: 'Fall Precautions',
  [SafetyLanguageKeys.SAFETY.ACTIONS_IF_PATIENT_HAS_CONFUSION_URINARY_OR_FAECAL_FREQUENCY_INCONTINENCE_URGENCY_NOCTURIA_OR_OTHER_TOILETING_ISSUES]:
    'Actions {text} if patient has confusion, urinary or faecal frequency, incontinence, urgency, nocturia or other toileting issues',
  [SafetyLanguageKeys.SAFETY.ACTIONS_IF_PATIENT_HAS_VISUAL_IMPAIRMENT]: 'Actions {text} if patient has visual impairment',
  [SafetyLanguageKeys.SAFETY.ACTIONS_IF_PATIENT_IS_CONFUSED_DISORIENTED_AGITATED_DEPRESSED]: 'Actions {text} if patient is: confused, disoriented, agitated, depressed',
  [SafetyLanguageKeys.SAFETY.ACTIONS_RELATED_TO]: 'Actions {action} related to {relatedTo}'
};

export const SafetyEnAu: SafetyMessageType = {
  [SafetyLanguageKeys.SAFETY.FALL_PRECAUTIONS]: 'Other Fall Precautions',
  [SafetyLanguageKeys.SAFETY.ACTIONS_IF_PATIENT_HAS_CONFUSION_URINARY_OR_FAECAL_FREQUENCY_INCONTINENCE_URGENCY_NOCTURIA_OR_OTHER_TOILETING_ISSUES]:
    'Actions {text} if patient has confusion, urinary or faecal frequency, incontinence, urgency, nocturia or other toileting issues',
  [SafetyLanguageKeys.SAFETY.ACTIONS_IF_PATIENT_HAS_VISUAL_IMPAIRMENT]: 'Actions {text} if patient has visual impairment',
  [SafetyLanguageKeys.SAFETY.ACTIONS_IF_PATIENT_IS_CONFUSED_DISORIENTED_AGITATED_DEPRESSED]: 'Actions {text} if patient is: confused, disoriented, agitated, depressed',
  [SafetyLanguageKeys.SAFETY.ACTIONS_RELATED_TO]: 'Actions {action} related to {relatedTo}'
};
