export const mapElsCss = ({ className = '', els = '', xs = '', md = '', lg = '', xl = '', xsmd = '', mdlg = '', lgxl = '' }) => {
  let classNames = [];
  if (className) {
    classNames = classNames.concat(className);
  }
  if (els) {
    classNames = classNames.concat(els.split(' ').map((cssClass) => `u-els-${cssClass}`));
  }
  if (xs) {
    classNames = classNames.concat(xs.split(' ').map((cssClass) => `u-els-${cssClass}@mobile`));
  }
  if (md) {
    classNames = classNames.concat(md.split(' ').map((cssClass) => `u-els-${cssClass}@tablet`));
  }
  if (lg) {
    classNames = classNames.concat(lg.split(' ').map((cssClass) => `u-els-${cssClass}@desktop`));
  }
  if (xl) {
    classNames = classNames.concat(xl.split(' ').map((cssClass) => `u-els-${cssClass}@wide`));
  }
  if (xsmd) {
    classNames = classNames.concat(xsmd.split(' ').map((cssClass) => `u-els-${cssClass}@mobile`));
    classNames = classNames.concat(xsmd.split(' ').map((cssClass) => `u-els-${cssClass}@tablet`));
  }
  if (mdlg) {
    classNames = classNames.concat(mdlg.split(' ').map((cssClass) => `u-els-${cssClass}@tablet`));
    classNames = classNames.concat(mdlg.split(' ').map((cssClass) => `u-els-${cssClass}@desktop`));
  }
  if (lgxl) {
    classNames = classNames.concat(lgxl.split(' ').map((cssClass) => `u-els-${cssClass}@desktop`));
    classNames = classNames.concat(lgxl.split(' ').map((cssClass) => `u-els-${cssClass}@wide`));
  }

  return classNames.join(' ');
};
