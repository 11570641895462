export const MusculoskeletalAssessmentLanguageKeys = {
  SPECIAL_TEST_SIGNS: 'Special Test Signs'
};

export type MusculoskeletalAssessmentMessageType = Partial<Record<keyof typeof MusculoskeletalAssessmentLanguageKeys, string>>;

export const MusculoskeletalAssessmentEnUs: MusculoskeletalAssessmentMessageType = {
  [MusculoskeletalAssessmentLanguageKeys.SPECIAL_TEST_SIGNS]: '<htmlTag>Special Test Signs</htmlTag>'
};

export const MusculoskeletalAssessmentEnAu: MusculoskeletalAssessmentMessageType = {
  [MusculoskeletalAssessmentLanguageKeys.SPECIAL_TEST_SIGNS]: ''
};
