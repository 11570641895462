import produce from 'immer';
import queryString from 'query-string';
import { ELSCommonUIConstants } from '@els/els-ui-common-react';
import { AssignmentType, LinkingAction } from 'models/enum';
import { NavigationItem, PatientContext } from 'models/ui';
import { ParentSections, RouteParams, RoutePath, SavedPatientChartsRoutes } from 'constants/app.constant';
import { facadeApi } from 'helpers/api.helper';
import { resetEmptyEhrDemoStorage } from 'helpers/assignment.helper';
import history from 'helpers/history';
import { appService, cacheService } from 'services';

export const navigateToHome = (): void => {
  window.location.href = RoutePath.home;
};

export const navigateToAppLinkSource = (content: string | undefined) => {
  const actionPayload = { action: LinkingAction.SIMSNG_BACK_SHERPATH };
  resetEmptyEhrDemoStorage();
  return appService
    .getRedirectURL(actionPayload, content)
    .then((res) => res.data.redirectURL || res.data.redirectUrl)
    .then((url) => {
      window.location.href = url;
    });
};

// The menu items are cached in sessionStorage.
// So we need to skip the cache when we want to fetch the menu items again.
// And it just applied for the case of Author Mode.
export const fetchMenuItems = (patientContext: PatientContext, skipCache?: boolean) => {
  // navigation items can be difference between patientContext
  // so that the cache key depends on it
  const { hospitalFloors, ageInDays, assignmentType, gender, isAuthor } = patientContext;
  const suffix = `${assignmentType}-${ageInDays}-${gender}-${isAuthor}`;
  const cacheKey = hospitalFloors?.length > 0 ? `navigation--${hospitalFloors}-${suffix}` : `navigation-${suffix}`;

  return cacheService.withCache(
    cacheKey,
    () => {
      const params = queryString.stringify(patientContext);
      return facadeApi.get(`/content/navigation/getFull?${params}`);
    },
    skipCache
  );
};

export const filterAndFillMenuItems = (rootMenu: NavigationItem, assessmentId: number): NavigationItem[] =>
  rootMenu.children.map((item) => {
    if (item.level === 2 && item.path.includes(RouteParams.ASSESSMENT_ID)) {
      /**
       * if assessmentId is undefined (like in the case of Author Mode)
       * include the / at the beginning of replace's first arg
       * that way we don't wind up with // back to back when we replace it
       */
      const updatedPath = !assessmentId ? item.path.replace(`/${RouteParams.ASSESSMENT_ID}`, '') : item.path.replace(RouteParams.ASSESSMENT_ID, assessmentId.toString());

      return { ...item, path: updatedPath };
    }

    return item;
  });

export const mapTreeToMap = (navigationItems: NavigationItem[], map: Map<string, NavigationItem>): Map<string, NavigationItem> => {
  let navigationMap = map;

  if (!navigationMap) {
    navigationMap = new Map();
  }
  navigationItems.forEach((item) => {
    navigationMap.set(item.id, item);
    if (item.children?.length) {
      mapTreeToMap(item.children, navigationMap);
    }
  });

  return navigationMap;
};

export const buildChartFromNavigationItems = (navigationItems: NavigationItem[], parentPage: string, landingPage: string) => {
  const parentPageNavigationItems = navigationItems.find((navItem) => navItem.id === parentPage);
  const landingPageNavigationItems = parentPageNavigationItems.children.find((navItem) => navItem.id === landingPage);
  return landingPageNavigationItems.children
    .map((item) => {
      return {
        navId: item.id,
        name: item.text,
        hasData: false,
        shown: item.shown,
        routePath: item.path
      };
    })
    .filter((nav) => nav.shown);
};

export const filterShownMenuItems = (navigationItems: NavigationItem[]) => {
  const newNavigationItems = [];

  navigationItems.forEach((item) => {
    if (item.shown) {
      const newMenuItem = produce(item, (draft) => {
        draft.children = filterShownMenuItems(item.children);
      });
      newNavigationItems.push(newMenuItem);
    }
  });

  return newNavigationItems;
};

export const navigateToChart = (url: string, assessmentId: number): void => history.push(url.replace(RouteParams.ASSESSMENT_ID, assessmentId.toString()));

export const navigateToAssignmentStartingPage = (assignmentType: string, eolsAssignmentId: string, assessmentId: string): void => {
  if (assignmentType === AssignmentType.EMPTY_EHR) {
    history.push({
      pathname: RoutePath.student.blankChartingEntryPoint.replace(RouteParams.ASSIGNMENT_ID, eolsAssignmentId)
    });
  } else {
    history.push({
      pathname: RoutePath.student.caseStudyLanding.replace(RouteParams.ASSESSMENT_ID, assessmentId)
    });
  }
};

export const navigateToClinicalEntryPoint = (assignmentId: number): void =>
  history.push(RoutePath.student.clinicalEntryPoint.replace(RouteParams.ASSIGNMENT_ID, String(assignmentId)));

export const navigateToSavedPatientCharting = (assessmentId: number, navId: string): void => {
  const parentSections = Object.values(ParentSections);
  let routePath = `/${ELSCommonUIConstants.security.States.PageNotFound}`;
  const section = parentSections.find((item) => navId.includes(item));
  if (section) {
    routePath = SavedPatientChartsRoutes.get(section);
  }

  navigateToChart(routePath, assessmentId);
};

export const navigateToAdmissionHistory = (assessmentId: number): void => navigateToChart(RoutePath.student.admissionHistory.landing, assessmentId);

export const navigateToStudentSubmission = (assessmentId: number): void => navigateToChart(RoutePath.student.assignmentSubmission, assessmentId);
