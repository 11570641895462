export const IntakeOutputLanguageKeys = {
  AMOUNT_ML: 'Amount (ml)',
  AMOUNT_ML_PER_HOUR: 'Amount (ml/hour)',
  INTAKE_OUTPUT_CHART_INPUTS: 'Chart Inputs',
  INTAKE_OUTPUT: 'Intake - Output',
  INTAKE: 'Intake',
  OUTPUT: 'Output',
  MISCELLANEOUS: 'Miscellaneous',
  MISCELLANEOUS_TYPE: 'Miscellaneous Type',
  TYPE: 'Type',
  INTRAVENOUS_FLUIDS: 'Intravenous Fluids',
  MEALS: 'Meals',
  INTAKE_OUTPUT_IN_ML: 'Intake / Output in mL',
  FEED_PRESCRIBED: 'Feed Prescribed',
  NUMBER: 'Number',
  SIZE: 'Size',
  PERCENT_CONSUMED: '% Consumed',
  OTHER: 'Other',
  NOTES: 'Notes',
  UNITS: 'Units'
};

export type IntakeOutputMessageType = Partial<Record<keyof typeof IntakeOutputLanguageKeys, string>>;

export const IntakeOutputEnUs: IntakeOutputMessageType = {
  [IntakeOutputLanguageKeys.FEED_PRESCRIBED]: '',
  [IntakeOutputLanguageKeys.MISCELLANEOUS_TYPE]: 'Type'
};

export const IntakeOutputEnAu: IntakeOutputMessageType = {
  [IntakeOutputLanguageKeys.INTAKE_OUTPUT]: 'Fluid Balance Chart',
  [IntakeOutputLanguageKeys.INTAKE]: 'Per Oral (PO) Intake',
  [IntakeOutputLanguageKeys.MISCELLANEOUS]: 'Enteral Nutrition',
  [IntakeOutputLanguageKeys.MISCELLANEOUS_TYPE]: 'Route'
};
