/* eslint-disable global-require */

// images are hard-coded with ingest data
export const getStaticPatientAvatar = (imageName: string) => {
  let avatarUrl;
  switch (imageName) {
    case 'avatar-1.jpg':
      avatarUrl = require('assets/img/avatar-1.jpg');
      break;
    case 'avatar-2.jpg':
      avatarUrl = require('assets/img/avatar-2.jpg');
      break;
    case 'avatar-3.jpg':
      avatarUrl = require('assets/img/avatar-3.jpg');
      break;
    default:
      avatarUrl = '';
  }
  return avatarUrl;
};
