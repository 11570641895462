export enum FormField {
  PROTOCOL_IN_EFFECT = 'protocolInEffect'
}

export enum SectionTitle {
  FALL_RISK_ASSESSMENT = 'Fall Risk Assessment',
  MORSE_TOTAL_SCORE = 'Morse Total Score',
  FALL_PREVENTION_PROTOCOL = 'Fall Prevention Protocol'
}

export enum Label {
  HIGH_FALL_RISK_PROTOCOL = 'High-risk fall protocols should be in effect for patients with a fall assessment score greater than 45.',
  PROTOCOL_IN_EFFECT = 'Fall prevention protocol in effect'
}

export enum ScaleRollUpLabel {
  LOW_RISK = 'Low Risk: Score of 0 - 24. Implement Low Risk Fall Prevention Interventions: All admitted patients, orient to surroundings, patient and family education about risk, toileting program, bed in low position, evaluate medication response, personal items in reach, night light as appropriate, nonskid footware, decrease room clutter.',
  MEDIUM_RISK = 'Medium Risk: Score of 25 - 44. Implement Medium Risk Fall Prevention Interventions: All items in low prevention plus post fall program sign indicating risk, wrist band identification, ambulate with assistance, do not leave patient unattended in diagnostic or treatment area, make comfort rounds every 2 hours for toileting.',
  HIGH_RISK = 'High risk: Score of 45 and higher. Implement High Risk Fall Prevention Interventions: All items in medium prevention plus provide commode at bedside (if appropriate), urinal/bedpan within easy reach, place patient in room near nurses station (especially first 24-48 hours of admission) consider patient safety alarm, consider bedside mat.'
}
