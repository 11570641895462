export enum Title {
  ORDER_ENTRY = 'Order Entry',
  START_TIME_OFFSET = 'Start Time Offset',
  STOP_TIME_OFFSET = 'Stop Time Offset',
  RESULTS_RECEIVED_TIME_OFFSET = 'Results Received Time Offset'
}

export enum FormField {
  ORDER_START_TIME_OFFSET = 'orderStartTimeOffset',
  ORDER_STOP_TIME_OFFSET = 'orderStopTimeOffset',
  RESULTS_RECEIVED_TIME_OFFSET = 'resultsReceivedTimeOffset'
}
