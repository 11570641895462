export const AdmissionDataLanguageKeys = {
  ADMISSION_DATA: {
    ADMISSION_DATA: 'Admission Data',
    THIS_ADMISSION: 'This Admission',
    VERBAL_ADMISSION_HISTORY: 'Verbal Admission History Information Unavailable',
    PAST_ADMISSION: 'Past Admissions',
    INFORMANT_HISTORIAN: 'Informant / Historian',
    PREGNANCY_RELATED: 'Pregnancy Related',
    PREGNANCY_RELATED_SELECT_OTHER: 'Pregnancy Related Select Other',
    CONTRACTIONS_TIME_OF_ONSET: 'Time of onset',
    CONTRACTIONS_FREQUENCY: 'Frequency',
    CONTRACTIONS_DURATION: 'Duration',
    CONTRACTIONS_QUALITY: 'Quality',
    CONTRACTIONS_FLUID: 'Fluid',
    CONTRACTIONS_COLOR: 'Color',
    CONFIRM_IDENTIFICATION: 'Confirm Identification',
    NEXT_OF_KIN_DETAILS: 'Next of Kin (NoK) Details',
    NAME: 'Name',
    PHONE: 'Phone',
    REL_ORG: 'Relationship/Organisation',
    NOK_AWARE_OF_ADMISSION: 'NoK aware of admission?',
    PREFERRED_NAME: 'Preferred Name',
    PREFERRED_PRONOUNS: 'Preferred Pronouns',
    ADVANCED_CARE_PLANNING: 'Advanced Care Planning',
    DOES_PATIENT_HAVE_A_CARER: 'Does patient have a carer?',
    IS_PATIENT_OF_ATSI: 'Is patient of Aboriginal or Torres Strait Islander (ATSI) Descent?',
    IS_PATIENT_A_CARER: 'Is patient a carer for someone else?',
    ADMISSION_DATE: 'Admission Date',
    ESTIMATED_DISCHARGE_DATE: 'Estimated Discharge Date',
    ADMITTED_FROM: 'Admitted from',
    OTHER: 'Other',
    ARRIVED_BY: 'Arrived by',
    LAST_HOSPITAL_ADMISSION: 'Last hospital admission',
    REASON: 'Reason',
    WHERE: 'Where',
    INFORMATION_RECEIVED_FROM: 'Information received from',
    CHART_OMITTED: 'Chart Omitted'
  }
};

export type AdmissionDataMessageType = Partial<Record<keyof typeof AdmissionDataLanguageKeys, string>>;

export const AdmissionDataEnUs: AdmissionDataMessageType = {};

export const AdmissionDataEnAu: AdmissionDataMessageType = {};
