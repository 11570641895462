export const GenitourinaryAssessmentLanguageKeys = {
  GENITOURINARY_ASSESSMENT: {
    URINATION_MODE: 'Urination mode',
    DAY_OF_LAST_TREATMENT: 'Day of last treatment',
    DAY_OF_NEXT_TREATMENT: 'Day of next treatment',
    COLOR: 'Color',
    CHARACTERISTICS: 'Characteristics',
    ODOR: 'Odor',
    URINARY_ASSESSMENT: 'Urinary assessment',
    DIALYSIS: 'Dialysis',
    FEMALE: 'Female',
    FEMALE_EXTERNAL_GENITALIA_ASSESSMENT: 'Female external genitalia assessment',
    GENITOURINARY_ASSESSMENT: 'Genitourinary Assessment',
    MALE: 'Male',
    PENIS_ASSESSMENT: 'Penis assessment',
    PERITONEAL_DIALYSIS: 'Peritoneal dialysis',
    RENAL_DIALYSIS: 'Renal dialysis',
    SCROTUM_TESTES_ASSESSMENT: 'Scrotum/testes assessment',
    SYSTEM_ASSESSMENT: 'System Assessment',
    URINARY_DIVERSION: 'Urinary diversion',
    URINARY_SYSTEM_ASSESSMENT: 'Urinary System Assessment',
    URINE_COLOR_CHARACTERISTICS: 'Urine Color/Characteristics'
  }
};

export type GenitourinaryAssessmentMessageType = Partial<Record<keyof typeof GenitourinaryAssessmentLanguageKeys, string>>;

export const GenitourinaryAssessmentEnUs: GenitourinaryAssessmentMessageType = {};

export const GenitourinaryAssessmentEnAu: GenitourinaryAssessmentMessageType = {
  [GenitourinaryAssessmentLanguageKeys.GENITOURINARY_ASSESSMENT.URINE_COLOR_CHARACTERISTICS]: 'Urine Colour/Characteristics',
  [GenitourinaryAssessmentLanguageKeys.GENITOURINARY_ASSESSMENT.ODOR]: 'Odour'
};
