import { createSelector } from 'reselect';
import Immutable from 'seamless-immutable';
import { AssignmentType } from 'models/enum';
import { FilterConfig, PatientContext, TimeSpentPartial } from 'models/ui';
import { AppConstant, DefaultLocale } from 'constants/app.constant';
import { apiHelper } from 'helpers';
import { navigationService, patientContextService } from 'services';
import { createAction, handleActions } from 'redux-actions';
import { transformNavigations } from 'transformer/transformer';

const initialState = Immutable({
  actionsInProgress: [],
  loggedInUser: null,
  userRole: '',
  isAuthor: false,
  courseId: 0,
  menuItems: [],
  navId: '',
  assessment: null,
  patient: null,
  currentChartPathName: '',
  caseStudyId: '',
  filterConfig: {} as FilterConfig,
  timeSpentData: {} as TimeSpentPartial,
  locale: DefaultLocale,
  lastFocusableElement: 0
});

const setMenuItems = createAction('SIMS/SET_MENU_ITEMS');
const setPatientContext = createAction('SIMS/SET_PATIENT_CONTEXT');

// Allow to skip the cache when we want to fetch the menu items again.
const fetchMenuItems = (assessmentId: number, patientContext: PatientContext, skipCache?: boolean) => (dispatch) =>
  apiHelper
    .withApiErrorHandler(() => navigationService.fetchMenuItems(patientContext, skipCache), null)
    .then((data) => navigationService.filterAndFillMenuItems(data, assessmentId))
    .then((data) => transformNavigations(data))
    .then((menuItems) => dispatch(setMenuItems(menuItems)));

const fetchPatientContext = (simChartAssessmentId: string, assignmentType: AssignmentType) => (dispatch, getState) =>
  patientContextService.fetchPatientContext(simChartAssessmentId).then((patientContext) => {
    const { isAuthor, locale } = getState()[AppConstant.reduxResources.APP_STATE];
    const fullContext = { ...patientContext?.data, assignmentType, isAuthor, locale };
    dispatch(setPatientContext(fullContext));
    return fullContext;
  });

const actions = {
  setAwait: createAction('SIMS/AWAIT'),
  cleanAwait: createAction('SIMS/AWAIT_CLEAN'),
  doNothing: createAction('SIMS/EMPTY_ACTION'),
  restoreState: createAction('SIMS/RESTORE'),
  setLoggedInUser: createAction('SIMS/SET_LOGGED_IN_USER'),
  setUserRole: createAction('SIMS/SET_USER_ROLE'),
  setIsAuthor: createAction('SIMS/SET_IS_AUTHOR'),
  setSelectedCourse: createAction('SIMS/SET_SELECTED_COURSE'),
  setSelectedNavId: createAction('SIMS/SET_SELECTED_NAV_ID'),
  setAssessment: createAction('SIMS/SET_ASSESSMENT'),
  setPatient: createAction('SIMS/SET_PATIENT_DATA'),
  updatePatient: createAction('SIMS/UPDATE_PATIENT_DATA'),
  setCurrentChartPathName: createAction('SIMS/SET_CURRENT_CHART_PATH_NAME'),
  setCaseStudyId: createAction('STUDENT/SET_CASE_STUDY_ID'),
  setAuthoringData: createAction('SIMS/SET_AUTHORING_DATA'),
  setIsMultiStepsAuthoring: createAction('SIMS/SET_IS_MULTI_STEPS_AUTHORING'),
  setSkipLinkSections: createAction('SIMS/SET_SKIP_LINK_SECTIONS'),
  setFilterConfig: createAction('SIMS/SET_FILTER_CONFIG'),
  setTimeSpentData: createAction('SIMS/SET_TIME_SPENT_DATA'),
  setLocale: createAction('SIMS/SET_LOCALE'),
  setLastFocusableElement: createAction('SIMS/SET_LAST_FOCUSABLE_ELEMENT'),
  setMenuItems,
  setPatientContext,
  fetchMenuItems,
  fetchPatientContext
};

const reducer = handleActions(
  {
    [actions.setAwait]: (state, { payload: { isLoading }, meta }) => {
      let { actionsInProgress } = state;
      if (isLoading) {
        actionsInProgress = [...actionsInProgress, meta];
      } else {
        const index = actionsInProgress.findIndex((item) => item.actionType === meta.actionType);
        actionsInProgress = [...actionsInProgress.slice(0, index), ...actionsInProgress.slice(index + 1)];
      }

      return state.set('actionsInProgress', actionsInProgress);
    },
    [actions.cleanAwait]: (state) => state.set('actionsInProgress', []),
    [actions.restoreState]: (state, { payload }) => state.merge(payload),
    [actions.setLoggedInUser]: (state, { payload }) => state.set('loggedInUser', payload),
    [actions.setUserRole]: (state, { payload }) => state.set('userRole', payload),
    [actions.setIsAuthor]: (state, { payload }) => state.set('isAuthor', payload),
    [actions.setSelectedCourse]: (state, { payload }) => state.set('courseId', payload),
    [actions.setMenuItems]: (state, { payload }) => state.set('menuItems', payload),
    [actions.setSelectedNavId]: (state, { payload }) => state.set('navId', payload),
    [actions.setAssessment]: (state, { payload }) => state.set('assessment', payload),
    [actions.setPatient]: (state, { payload }) => state.set('patient', payload),
    [actions.updatePatient]: (state, { payload }) => state.merge({ patient: { ...payload } }, { deep: true }),
    [actions.setCurrentChartPathName]: (state, { payload }) => state.set('currentChartPathName', payload),
    [actions.setCaseStudyId]: (state, { payload }) => state.set('caseStudyId', payload),
    [actions.setPatientContext]: (state, { payload }) => state.set('patientContext', payload),
    [actions.setAuthoringData]: (state, { payload }) => state.set('authoringData', payload),
    [actions.setIsMultiStepsAuthoring]: (state, { payload }) => state.set('isMultiStepsAuthoring', payload),
    [actions.setSkipLinkSections]: (state, { payload }) => state.set('skipLinkSections', payload),
    [actions.setFilterConfig]: (state, { payload }) => state.set('filterConfig', payload),
    [actions.setTimeSpentData]: (state, { payload }) => state.set('timeSpentData', payload),
    [actions.setLocale]: (state, { payload }) => state.set('locale', payload),
    [actions.setLastFocusableElement]: (state, { payload }) => state.set('lastFocusableElement', payload)
  },
  initialState
);

const getAppState = (state) => state[AppConstant.reduxResources.APP_STATE];

const selectors = {
  getAwaitingStatus: createSelector(getAppState, (state) => state.actionsInProgress.length > 0),
  getLoggedInUser: createSelector(getAppState, (state) => state.loggedInUser),
  getUserId: createSelector(getAppState, (state) => state.getIn(['loggedInUser', 'id'])),
  getUserRole: createSelector(getAppState, (state) => state.userRole),
  getIsAuthor: createSelector(getAppState, (state) => state.isAuthor),
  getCourseId: createSelector(getAppState, (state) => state.courseId),
  getMenuItems: createSelector(getAppState, (state) => state.menuItems),
  getNavId: createSelector(getAppState, (state) => state.navId),
  getAssessment: createSelector(getAppState, (state) => state.assessment),
  getPatient: createSelector(getAppState, (state) => state.patient),
  getCurrentChartPathName: createSelector(getAppState, (state) => state.currentChartPathName),
  getCaseStudyId: createSelector(getAppState, (state) => state.caseStudyId),
  getPatientContext: createSelector(getAppState, (state) => state.patientContext),
  getAuthoringData: createSelector(getAppState, (state) => state.authoringData),
  getIsMultiStepsAuthoring: createSelector(getAppState, (state) => state.isMultiStepsAuthoring),
  getSkipLinkSections: createSelector(getAppState, (state) => state.skipLinkSections),
  getFilterConfig: createSelector(getAppState, (state) => state.filterConfig),
  getTimeSpentData: createSelector(getAppState, (state) => state.timeSpentData),
  getLocale: createSelector(getAppState, (state) => state.locale),
  getLastFocusableElement: createSelector(getAppState, (state) => state.lastFocusableElement)
};

const startBusy = (trackName) => ({ ...actions.setAwait({ isLoading: true }), meta: { actionType: trackName } });

const endBusy = (trackName) => ({ ...actions.setAwait({ isLoading: false }), meta: { actionType: trackName } });

const endBusyWithData = (trackName, dispatch, data) =>
  new Promise((resolve) => {
    dispatch({ ...actions.setAwait({ isLoading: false }), meta: { actionType: trackName } });
    resolve(data);
  });

export { initialState, actions as appActions, reducer, selectors as appSelectors, startBusy, endBusy, endBusyWithData };
