import { Component } from 'react';
import { Event } from 'constants/app.constant';
import { eventHelper } from 'helpers';
import './error.bar.scss';

interface ErrorBarState {
  message: string;
}

class ErrorBar extends Component<{}, ErrorBarState> {
  static displayName = 'ErrorBar';

  constructor(props) {
    super(props);
    this.state = { message: '' };
  }

  componentDidMount(): void {
    eventHelper.eventEmitter.on(Event.AppError.API, (message: string) => this.showMessage(message));
  }

  showMessage = (message: string) => {
    const showTimeDuration = 30000;
    this.setState({ message });
    setTimeout(() => this.setState({ message: '' }), showTimeDuration);
  };

  render() {
    if (!this.state.message) {
      return null;
    }
    return <div className="u-els-background-color-error u-els-padding-1o2 sng-error-bar">{this.state.message}</div>;
  }
}

export default ErrorBar;
