import { Messages } from 'lang';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { appHelper } from 'helpers';
import { appSelectors } from 'redux/ducks/app';

export interface IntlProviderWrapperProps {
  children: React.ReactNode;
  locale: string;
}

const mapStateToProps = (state) => ({
  locale: appSelectors.getLocale(state)
});

function IntlProviderWrapper({ children, locale }: IntlProviderWrapperProps) {
  return (
    <IntlProvider locale={appHelper.getLanguage(locale)} messages={Messages[locale]} fallbackOnEmptyString={false}>
      {children}
    </IntlProvider>
  );
}

const enhancers = [connect(mapStateToProps)];
export default compose(...enhancers)(IntlProviderWrapper);
