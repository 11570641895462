import moment from 'moment';
import { PatientRecord } from 'models/api-response';
import { FragmentType } from 'models/enum';
import { NAV_ID } from 'constants/app.constant';
import { getEmptyEhrDemo, isDemoEmptyEhr } from 'helpers/assignment.helper';
import { cacheService, chartService } from 'services';

const CHARTS_REQUIRE_PATIENT_CONTEXT = [
  NAV_ID.VITALSIGNS,
  NAV_ID.HEIGHT_WEIGHT,
  NAV_ID.LABORATORY,
  NAV_ID.ROLE_RELATIONSHIP,
  NAV_ID.HEALTH_HISTORY,
  NAV_ID.MUSCULOSKELETAL_ASSESSMENT,
  NAV_ID.GENITOURINARY_ASSESSMENT,
  NAV_ID.SENSORY_ASSESSMENT,
  NAV_ID.ACTIVITY,
  NAV_ID.NUTRITION_HYDRATION,
  NAV_ID.ELIMINATION,
  NAV_ID.SKIN_CARE,
  NAV_ID.WOUNDS,
  NAV_ID.GASTROINTESTINAL_ASSESSMENT,
  NAV_ID.SAFETY,
  NAV_ID.HYGIENE_DRESSINGS_COMFORT,
  NAV_ID.INJECTIONS,
  NAV_ID.RESPIRATORY_ASSESSMENT,
  NAV_ID.INTAKE_OUTPUT,
  NAV_ID.THERAPIES,
  NAV_ID.DIAGNOSTIC_TESTS_ORDER_ENTRY,
  NAV_ID.DIAGNOSTIC_TESTS,
  NAV_ID.OSTOMIES,
  NAV_ID.ADOLESCENT_RISK_ASSESSMENT,
  NAV_ID.ACTIVITIES_DAILY_LIVING,
  NAV_ID.ADMISSION_DATA,
  NAV_ID.NUTRITION,
  NAV_ID.MUSCULOSKELETAL_INTERVENTIONS,
  NAV_ID.MEDICAL_HISTORY,
  NAV_ID.GENERAL_ORDERS,
  NAV_ID.PAIN_INTERVENTIONS,
  NAV_ID.NEWBORN_ASSESSMENT,
  NAV_ID.NEWBORN_ASSESSMENT_SPECIAL,
  NAV_ID.ALCOHOL_SCREEN,
  NAV_ID.NUTRITION_SCREEN,
  NAV_ID.PLANNING_HOSPITAL_STAY,
  NAV_ID.PHYSICAL_EXAMINATION,
  NAV_ID.PSYCHOSOCIAL_SCREEN,
  NAV_ID.BALLARD_MATURATIONAL_ASSESSMENT,
  NAV_ID.PHARMACY
];

export const fetchPatientContext = (simChartAssessmentId: string) =>
  cacheService.withCache(`patientContext-${simChartAssessmentId}`, async () => {
    // If the emptyEhrDemo is set, that's mean instructor is demo and we return the patientInfo from the emptyEhrDemo
    // If the emptyEhrDemo is not set, then we are not in a case study
    const emptyEhrDemo = getEmptyEhrDemo();
    const instructorInDemoEmptyEhr = isDemoEmptyEhr();
    const patient = instructorInDemoEmptyEhr
      ? emptyEhrDemo?.patientInfo
      : await chartService
          .loadFragments({
            chartId: simChartAssessmentId,
            fragmentTypes: [FragmentType.CHARTING, FragmentType.AUTHORED],
            navIds: [NAV_ID.PATIENT]
          })
          .then(({ data }) => data[0]?.chartData as PatientRecord);

    // patient is empty when assignment just started not yet input clinicalSetup data
    // and when the user visiting is not instructor in a demo
    // return null for "patient", so it won't be cached.
    if (!patient) return { data: null };

    // clinical unit will be undefined if user accesses assignment submission page
    // for an empty EHR assignment not started
    const hospitalFloors = patient.clinicalUnit?.choices?.map((clinicalUnit) => clinicalUnit.value.toUpperCase());

    // today should be from start of day
    // so today and dob have the same hour baseline to calculate diff only based on day.
    const today = moment().startOf('day');

    // Explicit passing ISO_8601 because dob saved is converted to this standard.
    const dob = moment(patient.patientDateOfBirth, moment.ISO_8601);
    const ageInDays = today.diff(dob, 'days') || 1;

    return {
      data: {
        gender: patient.patientSex?.toUpperCase(),
        hospitalFloors: hospitalFloors || [],
        ageInDays
      }
    };
  });

// This will be moved to be a property of nav
export const isChartRequirePatientContext = (selectedNavId: string) => CHARTS_REQUIRE_PATIENT_CONTEXT.includes(selectedNavId);
