import { ChartFragment } from 'models/api-response';
import { NAV_ID } from 'constants/app.constant';
import { GeneralOrderType, ImportantDetails } from 'components/features/chart/order-entry/general-orders/constants';

const GENERAL_ORDER_TYPES_IN_RIBBON: string[] = [GeneralOrderType.ALERTS, GeneralOrderType.CODE_STATUS, GeneralOrderType.ISOLATION];

const IMPORTANT_ALERTS: string[] = [
  ImportantDetails.NO_BLOOD_PRESSURE_IN_LEFT_ARM,
  ImportantDetails.NO_BLOOD_PRESSURE_IN_RIGHT_ARM,
  ImportantDetails.SIGNS_AND_SYMPTOMS_OF_DELIRIUM_TREMENS
];

export const buildGeneralOrderRecords = (chartFragments: ChartFragment[]) =>
  chartFragments
    .filter(
      (chartFragment) =>
        chartFragment.navElementId === NAV_ID.GENERAL_ORDERS &&
        chartFragment.active &&
        GENERAL_ORDER_TYPES_IN_RIBBON.includes(chartFragment.chartData.orderDescription) &&
        (GeneralOrderType.ALERTS !== chartFragment.chartData.orderDescription || IMPORTANT_ALERTS.includes(chartFragment.chartData.orderDetails.value))
    )
    .map((fragment) => ({
      orderDescription: fragment.chartData.orderDescription,
      orderDetails: fragment.chartData.orderDetails.value
    }));
