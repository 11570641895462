import { LanguageKeys } from 'lang';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormField, ChartMetaFormFieldParams } from 'models/ui';
import { FormField as CommonFormField, Title } from '../shared/constants';

export enum FormField {
  ORDER_DESCRIPTION = 'orderDescription',
  ORDER_DETAILS = 'orderDetails',
  ORDER_DATE = 'orderDate',
  ORDER_TIME = 'orderTime'
}

export enum GeneralOrderType {
  ALERTS = 'Alerts',
  CODE_STATUS = 'Code Status',
  ISOLATION = 'Isolation'
}

export enum ImportantDetails {
  NO_BLOOD_PRESSURE_IN_LEFT_ARM = 'No blood pressure or sticks in left arm',
  NO_BLOOD_PRESSURE_IN_RIGHT_ARM = 'No blood pressure or sticks in right arm',
  SIGNS_AND_SYMPTOMS_OF_DELIRIUM_TREMENS = 'Watch for signs and symptoms of delirium tremens'
}

export function getGeneralOrderFormFieldBuilderItems({
  isAuthor,
  addNestedTextField
}: {
  isAuthor?: boolean;
  addNestedTextField: (chartField: ChartMetaFormField, formFieldMap: Map<string, ChartMetaFormField>) => void;
}): ChartMetaFormFieldParams[] {
  return [
    { name: FormField.ORDER_DESCRIPTION, type: FormFieldInputType.MULTI_SELECT_RADIO, label: LanguageKeys.GENERAL_ORDER.ORDER_DESCRIPTION },
    {
      name: FormField.ORDER_DETAILS,
      type: FormFieldInputType.CHECK_BOX,
      label: LanguageKeys.GENERAL_ORDER.ORDER_DETAILS,
      onChangePreValidation: addNestedTextField
    },
    { name: FormField.ORDER_DATE, type: FormFieldInputType.DATE, label: LanguageKeys.DATE_LABEL, isHidden: isAuthor },
    { name: FormField.ORDER_TIME, type: FormFieldInputType.TIME, label: LanguageKeys.GENERAL_ORDER.ORDER_TIME, defaultValue: '00:00', isHidden: isAuthor },
    { name: CommonFormField.ORDER_START_TIME_OFFSET, type: FormFieldInputType.TEXT_BOX, label: Title.START_TIME_OFFSET, value: '00:00', isHidden: !isAuthor }
  ];
}
