export const CaseStudyLanguageKeys = {
  CASE_STUDY_INFO: {
    WARNING_MARK_PHASE_1_DONE: 'INTAKE_OUTPUT_ROUTE_PATH'
  }
};

export type CaseStudyMessageType = Partial<Record<keyof typeof CaseStudyLanguageKeys, string>>;

export const CaseStudyEnUs: CaseStudyMessageType = {
  [CaseStudyLanguageKeys.CASE_STUDY_INFO.WARNING_MARK_PHASE_1_DONE]:
    'Once marked done, you will begin a short quiz and still have access to the EHR charts. Phase instructions will no longer be visible.'
};
export const CaseStudyEnAu: CaseStudyMessageType = {
  [CaseStudyLanguageKeys.CASE_STUDY_INFO.WARNING_MARK_PHASE_1_DONE]:
    'Once marked done, you will begin a short quiz and still have access to the EMR charts. Phase instructions will no longer be visible.'
};
