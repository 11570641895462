export const NutritionScreenLanguageKeys = {
  NUTRITION_SCREEN: {
    GENERA_NUTRITION_SCREEN: 'General Nutrition Screen',
    ILLNESS_INJURY_NUTRITION_SCREEN: 'Illness/Injury Nutrition Screen',
    FOOD_PREFERENCES_DIET_CONSIDERATIONS: 'Food Preferences/Diet Considerations',
    FOOD_PREFERENCES: 'Food preferences',
    FOOD_DISLIKES: 'Food dislikes',
    INDICATORS_NUTRITIONAL_RISK_DURING_PREGNANCY: 'Indicators of nutritional risk during pregnancy',
    FOOD_PREFERENCES_DIET_CONSIDERATIONS_OTHER_INPUT: 'Food Preferences/Diet Considerations Other Input',
    DIET_BEFORE_HOSPITALIZATION_INCLUDING_SNACKS: 'Diet before hospitalization, including snacks'
  }
};

export type NutritionScreenMessageType = Partial<Record<keyof typeof NutritionScreenLanguageKeys, string>>;

export const NutritionScreenEnUs: NutritionScreenMessageType = {
  [NutritionScreenLanguageKeys.NUTRITION_SCREEN.DIET_BEFORE_HOSPITALIZATION_INCLUDING_SNACKS]: 'Diet before hospitalization, including snacks'
};

export const NutritionScreenEnAu: NutritionScreenMessageType = {
  [NutritionScreenLanguageKeys.NUTRITION_SCREEN.DIET_BEFORE_HOSPITALIZATION_INCLUDING_SNACKS]: 'Diet before hospitalisation, including snacks'
};
