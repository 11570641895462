import { createSelector } from 'reselect';
import { AppConstant } from 'constants/app.constant';

const routerSelectors = {
  getLocation: createSelector(
    (state) => state[AppConstant.reduxResources.ROUTER],
    (state) => state?.location || {}
  )
};

export { routerSelectors };
