export const IntegumentaryAssessmentLanguageKeys = {
  INTEGUMENTARY_ASSESSMENT: {
    SKIN_ASSESSMENT: 'Skin Assessment',
    HAIR_ASSESSMENT: 'Hair Assessment',
    NAIL_ASSESSMENT: 'Nail Assessment',
    INTEGUMENTARY_ASSESSMENT: 'Integumentary Assessment',
    COLOR: 'Color',
    SKIN_TURGOR: 'Skin turgor',
    TEMPERATURE: 'Temperature',
    INTEGRITY: 'Integrity',
    MOISTURE: 'Moisture',
    DISTRIBUTION: 'Distribution',
    CHARACTERISTICS: 'Characteristics',
    CONFIGURATION: 'Configuration',
    CONDITION: 'Condition',
    OTHER_CONDITION: 'Other Condition',
    NAIL_BASE: 'Nail Base',
    OTHER_NAIL_BASE: 'Other Nail Base'
  }
};

export type IntegumentaryAssessmentMessageType = Partial<Record<keyof typeof IntegumentaryAssessmentLanguageKeys, string>>;

export const IntegumentaryAssessmentEnUs: IntegumentaryAssessmentMessageType = {};

export const IntegumentaryAssessmentEnAu: IntegumentaryAssessmentMessageType = {
  [IntegumentaryAssessmentLanguageKeys.INTEGUMENTARY_ASSESSMENT.COLOR]: 'Colour'
};
