export const RestraintsLanguageKeys = {
  RESTRAINTS: {
    RESTRAINTS: 'Restraints',
    MONITORING_INTERVENTIONS_AND_VISUAL_CHECK: 'Monitoring Interventions and Visual Check',
    ASSESSMENT_DURING_USE_OF_RESTRAINTS: 'Assessment During Use of Restraints',
    INITIATION: 'Initiation',
    DISCONTINUATION: 'Discontinuation',
    SPECIAL_PRECAUTIONS: 'Special Precautions',
    CRITERIA_MET_FOR_RESTRAINT: 'Criteria Met for Restraint',
    PROVIDER_NOTIFICATION: 'Provider Notification',
    ALTERNATIVE_METHODS_UTILIZED_PRIOR: 'Alternative Methods Utilized Prior to Restraint Use',
    TYPE_OF_RESTRAINT: 'Type of Restraint',
    RESTRAINT_NOTES: 'Restraint Notes',
    CONTINUED_NEED_FOR_RESTRAINTS: 'Continued Need for Restraints',
    SKIN_CIRCULATION: 'Skin/Circulation',
    FLUID_NUTRITION: 'Fluid/Nutrition',
    ELIMINATION: 'Elimination',
    BEHAVIORAL_MENTAL_STATUS: 'Behavioral/Mental Status',
    SPECIAL_PRECAUTIONS_RESTRAINTS_NOTES: 'Special Precautions/Restraints Notes',
    PROVIDER_NOTIFICATION_RADIO: 'Provider Notified of restraint need'
  }
};

export type RestraintsMessageType = Partial<Record<keyof typeof RestraintsLanguageKeys, string>>;

export const RestraintsEnUs: RestraintsMessageType = {};

export const RestraintsEnAu: RestraintsMessageType = {};
