import 'animate.css';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import { enableMapSet } from 'immer';
import { pick } from 'lodash';
import moment from 'moment';
import React, { Component, Suspense } from 'react';
import { Provider } from 'react-redux';
import { Redirect, Router, Switch } from 'react-router-dom';
import { ELSModalProvider } from '@els/els-component-modal-react';
import {
  ELSAdobeAnalyticService,
  ELSCommonConfig,
  ELSCommonUIConstants,
  ELSIdleProvider,
  ELSMouseFlowService,
  ELSPageLoader,
  ELSRouterHelper,
  ELSSecurityRoutes,
  ELSStorageHelper,
  ELSTokenHelper,
  ELSTokenServiceRegistrar
} from '@els/els-ui-common-react';
import { LinkingAction, UserRole } from 'models/enum';
import { User } from 'models/ui';
import { Analytics, AppConstant, RoutePath } from 'constants/app.constant';
import { appHelper } from 'helpers';
import { resetEmptyEhrDemoStorage } from 'helpers/assignment.helper';
import { isCaseStudyStep, resetCaseStudyDemoStorage } from 'helpers/caseStudy.helper';
import { getDateFormatByLocale } from 'helpers/datetime.helper';
import history from 'helpers/history';
import { appService, cookieService, logService } from 'services';
import { Store } from 'redux';
import { configureStore } from 'redux/app.store';
import { appActions } from 'redux/ducks/app';
import { instructorActions } from 'redux/ducks/instructor';
import { ErrorBoundary, withHTMLHeadSEO } from 'components/common';
import { ELSAccessibilityFocusState } from 'components/common/els';
import ErrorBar from 'components/common/errors/ErrorBar';
import IntlProviderWrapper from 'components/common/intl-provider-wrapper/IntlProviderWrapper';
import LocationChangeHandler from 'components/common/location-change/LocationChangeHandler';
import { appStateVersion, setDefaultAppConfig, tokenRefreshIntervalFactor } from 'config/app.config';
import { AppRoutes } from 'routes/app.routes';
import '../assets/main.scss';

enableMapSet();

class App extends Component {
  history: History;
  store: Store;

  constructor(props) {
    super(props);
    this.initialize();
  }

  componentDidMount() {
    this.history.listen(() => {
      this.clearCaseStudyDemo();
      this.clearStateOnLogin();
    });
    ELSMouseFlowService.start(AppConstant.MOUSE_FLOW_CDN);
  }

  initialize = () => {
    ELSTokenServiceRegistrar.initializeFromReload();
    this.history = history;
    this.clearOldStateIfUserInvalid();
    this.clearOldStateIfUpgraded();
    this.clearStateOnLogin();
    this.store = configureStore(this.history);

    const state = this.store?.getState();
    setDefaultAppConfig({
      i18n: {
        dateFormat: {
          primary: getDateFormatByLocale(state?.appState?.locale)
        }
      }
    });

    this.clearCaseStudyDemo();
    this.checkIntegrationLogin();
    this.setSelectedCourseForIntegration();
    ELSAdobeAnalyticService.registerAdobeAnalytic(
      `${ELSCommonConfig.buildUrl}/api/features/app/SIMSNG/key/ADOBE_ANALYTICS_DISABLED?defaultValue=true`,
      Analytics.defaultAnalyticsConfig
    );
    ELSTokenServiceRegistrar.registerTokenRefresh(tokenRefreshIntervalFactor);
    sessionStorage.setItem(AppConstant.RELOAD, 'true');
  };

  // if integration mode, courseId comes from user token
  // if backdoor mode, courseId comes from login form
  setSelectedCourseForIntegration = () => {
    const user = ELSTokenHelper.getUser();
    if (user?.appParams?.courseId) {
      this.store.dispatch(appActions.setSelectedCourse(user.appParams.courseId.toString()));
    }
  };

  clearOldStateIfUserInvalid = () => {
    const rootStateFromStorage = ELSStorageHelper.driver(ELSCommonUIConstants.security.Locker.DefaultDriver).namespace('').get(AppConstant.PERSIST_KEY);
    const appState = rootStateFromStorage ? rootStateFromStorage[AppConstant.reduxResources.APP_STATE] : null;
    const { user } = ELSTokenHelper.getUser();
    let isUserValid = true;
    if (appState && user?.userId) {
      isUserValid = user.userId === appState?.loggedInUser?.id;
    }
    if (!isUserValid) {
      this.resetStore();
    }
  };

  clearOldStateIfUpgraded = () => {
    const prevStateVersion = localStorage.getItem(AppConstant.STATE_VERSION_KEY);
    if (prevStateVersion !== appStateVersion) {
      localStorage.removeItem(AppConstant.PERSIST_KEY);
    }
    localStorage.setItem(AppConstant.STATE_VERSION_KEY, appStateVersion);
  };

  clearCaseStudyDemo = () => {
    const store = this.store.getState();
    if (store.instructorState?.caseStudyDemo && (!isCaseStudyStep(store.router?.location?.pathname) || !sessionStorage.getItem(AppConstant.RELOAD))) {
      resetCaseStudyDemoStorage();
      this.store.dispatch(instructorActions.setCaseStudyDemo(null));
    }
  };

  clearStateOnLogin = () => {
    if (this.history.location?.pathname === RoutePath.backdoor) {
      localStorage.removeItem(AppConstant.PERSIST_KEY);
      this.clearCookies();
      this.resetStore();
    }
  };

  resetStore = () => {
    if (this.store) {
      this.store.dispatch({ type: AppConstant.RESET_ACTION });
    }
  };

  clearCookies = () => {
    cookieService.remove(AppConstant.COOKIE_TOKEN_NAME);
    cookieService.remove(AppConstant.COOKIE_IS_AUTHOR);
  };

  checkIntegrationLogin = () => {
    const token = cookieService.get(AppConstant.COOKIE_TOKEN_NAME);
    const isAuthor = cookieService.get(AppConstant.COOKIE_IS_AUTHOR);
    const linkId = cookieService.get(AppConstant.COOKIE_LINK_ID);
    localStorage.setItem(AppConstant.COOKIE_LINK_ID, linkId);
    if (token) {
      const { user } = ELSTokenHelper.parseToken(token);
      const expiredTime = moment(user.expireDate);
      if (expiredTime.isBefore(moment())) {
        this.clearCookies();
        this.history.push(RoutePath.home);
      } else {
        const { firstName, lastName, emailAddress } = user;
        const appUser: User = { id: user.userId, firstName, lastName, emailAddress };
        this.store.dispatch(appActions.setLoggedInUser(appUser));
        this.store.dispatch(appActions.setUserRole(user.role));
        this.store.dispatch(appActions.setIsAuthor(isAuthor === 'true'));
        localStorage.setItem(AppConstant.COOKIE_LINK_ID, linkId);
        appHelper.saveToken(token);
        cookieService.remove(AppConstant.COOKIE_TOKEN_NAME);
      }
    }
  };

  initializeAppByAdminBackdoor = ({ eolsUser, courseId, roleId }) => {
    this.clearHistoryStateForBackdoor();
    const appUser: User = pick(eolsUser, ['id', 'firstName', 'lastName', 'emailAddress']);
    let promise = Promise.resolve(null);

    // workaround to check author, the response of getRedirectURL includes "isAuthor" cookie
    if (roleId === UserRole.INSTRUCTOR) {
      const actionPayload = {
        action: LinkingAction.INSTRUCTOR_ADD_ASSIGNMENT,
        courseId
      };
      promise = appService.getRedirectURL(actionPayload);
    }

    return promise.then(() => {
      const authorValue = cookieService.get(AppConstant.COOKIE_IS_AUTHOR);
      this.store.dispatch(appActions.setLoggedInUser(appUser));
      this.store.dispatch(appActions.setUserRole(roleId));
      this.store.dispatch(appActions.setIsAuthor(authorValue === 'true'));
      this.store.dispatch(appActions.setSelectedCourse(courseId));
      this.history.push(RoutePath.launch);
    });
  };

  clearHistoryStateForBackdoor = () => {
    cookieService.remove(AppConstant.COOKIE_LINK_ID);
    localStorage.removeItem(AppConstant.COOKIE_LINK_ID);
    resetEmptyEhrDemoStorage();
  };

  handleSessionTimeout = () => logService.log('implement soon');

  render() {
    const HTMLHeadSEOComponent = withHTMLHeadSEO(null)(null);
    return (
      <Provider store={this.store}>
        <IntlProviderWrapper>
          <ConnectedRouter history={this.history}>
            <ELSModalProvider>
              <>
                <LocationChangeHandler />
                <ErrorBoundary>
                  <HTMLHeadSEOComponent />
                  <Router history={this.history}>
                    <Suspense fallback={<ELSPageLoader />}>
                      <Switch>
                        {ELSSecurityRoutes.getSecurityRoutes(this.initializeAppByAdminBackdoor)
                          .concat(AppRoutes.getRoutes())
                          .map((route) => ELSRouterHelper.createRoute(route))}
                        <Redirect from="/" exact to={`/${ELSCommonUIConstants.security.States.NotAuthorized}`} />
                        <Redirect to={`/${ELSCommonUIConstants.security.States.PageNotFound}`} />
                      </Switch>
                    </Suspense>
                  </Router>
                  <ELSAccessibilityFocusState />
                  <ELSIdleProvider onSessionTimeout={this.handleSessionTimeout} timeout={Number(sessionStorage.getItem('simsng.timeout') || '59') * 60000} />
                  <ErrorBar />
                </ErrorBoundary>
              </>
            </ELSModalProvider>
          </ConnectedRouter>
        </IntlProviderWrapper>
      </Provider>
    );
  }
}

export default App;
