import { LanguageKeys } from 'lang';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormField, ChartMetaFormFieldParams } from 'models/ui';
import { FormField as CommonFormField, Title } from '../shared/constants';

export enum FormField {
  ORDER_ITEM = 'medication',
  ORDER_ITEM_OTHER = 'orderItemOther',
  FREQUENCY_TYPE = 'frequencyType',
  ORDER_TYPE = 'orderType',
  ROUTE = 'routeForAdministration',
  ROUTE_OTHER = 'routeForAdministrationOther',
  MEDICATION_DOSE = 'medicationDose',
  MEDICATION_DOSE_TEXTBOX = 'medicationDoseTextbox',
  SPECIAL_DOSE_DOSE = 'specialDoseDose',
  SPECIAL_DOSE_UNIT = 'specialDoseUnit',
  MEDICATION_FORM = 'medicationForm',
  MEDICATION_FORM_OTHER = 'medicationFormOther',
  FREQUENCY = 'frequencyOfAdministration',
  ORDER_START_DATE = 'orderStartDate',
  ORDER_START_TIME = 'orderStartTime',
  ORDER_STOP_DATE = 'orderStopDate',
  ORDER_STOP_TIME = 'orderStopTime',
  SPECIAL_INSTRUCTIONS = 'specialInstructions',
  HAS_STOP_DATE_TIME_DEFAULT = 'hasStopDateTimeDefault'
}

const fieldIdsToClearValue = [
  FormField.ORDER_TYPE,
  FormField.ROUTE,
  FormField.ROUTE_OTHER,
  FormField.MEDICATION_DOSE,
  FormField.MEDICATION_DOSE_TEXTBOX,
  FormField.SPECIAL_DOSE_DOSE,
  FormField.SPECIAL_DOSE_UNIT,
  FormField.MEDICATION_FORM,
  FormField.MEDICATION_FORM_OTHER,
  FormField.FREQUENCY,
  FormField.ORDER_START_DATE,
  FormField.ORDER_STOP_DATE,
  FormField.SPECIAL_INSTRUCTIONS
];

export const getPharmacyFormFieldBuilderItems = (
  isAuthor: boolean,
  handleChangeOrderItemPostValidation: (chartField: ChartMetaFormField, formFieldMap: Map<string, ChartMetaFormField>) => void,
  disableFrequency: (chartField: ChartMetaFormField, formFieldMap: Map<string, ChartMetaFormField>) => void
): ChartMetaFormFieldParams[] => [
  {
    name: FormField.ORDER_ITEM,
    type: FormFieldInputType.MULTI_SELECT_RADIO,
    label: LanguageKeys.PHARMACY.ORDER_ITEM,
    fieldIdsToClearValue,
    onChangePostValidation: handleChangeOrderItemPostValidation,
    isHidden: false
  },
  { name: FormField.ORDER_ITEM_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.OTHER, isHidden: false },
  { name: FormField.FREQUENCY_TYPE, type: FormFieldInputType.DROPDOWN },
  { name: FormField.ORDER_TYPE, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.PHARMACY.ORDER_TYPE, onChangePostValidation: disableFrequency, isHidden: false },
  { name: FormField.ROUTE, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.PHARMACY.ROUTE, isHidden: false },
  { name: FormField.ROUTE_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.OTHER, isHidden: false },
  { name: FormField.MEDICATION_DOSE, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.PHARMACY.MEDICATION_DOSE, isHidden: false },
  { name: FormField.MEDICATION_DOSE_TEXTBOX, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.PHARMACY.MEDICATION_DOSE, isHidden: false },
  { name: FormField.SPECIAL_DOSE_DOSE, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.PHARMACY.SPECIAL_DOSE_DOSE, isHidden: false },
  { name: FormField.SPECIAL_DOSE_UNIT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.PHARMACY.SPECIAL_DOSE_UNIT, isHidden: false },
  { name: FormField.MEDICATION_FORM, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.PHARMACY.MEDICATION_FORM, isHidden: false },
  { name: FormField.MEDICATION_FORM_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.OTHER, isHidden: false },
  { name: FormField.FREQUENCY, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.PHARMACY.FREQUENCY, isHidden: false },
  { name: FormField.ORDER_START_DATE, type: FormFieldInputType.DATE, label: LanguageKeys.DATE_LABEL, isHidden: isAuthor },
  { name: FormField.ORDER_STOP_DATE, type: FormFieldInputType.DATE, label: LanguageKeys.DATE_LABEL, isHidden: isAuthor },
  { name: FormField.ORDER_START_TIME, type: FormFieldInputType.TIME, label: LanguageKeys.TIME_LABEL, defaultValue: '00:00', isHidden: isAuthor },
  { name: FormField.ORDER_STOP_TIME, type: FormFieldInputType.TIME, label: LanguageKeys.TIME_LABEL, defaultValue: '00:00', isHidden: isAuthor },
  { name: CommonFormField.ORDER_START_TIME_OFFSET, type: FormFieldInputType.TEXT_BOX, label: Title.START_TIME_OFFSET, value: '00:00', isHidden: !isAuthor },
  { name: CommonFormField.ORDER_STOP_TIME_OFFSET, type: FormFieldInputType.TEXT_BOX, label: Title.STOP_TIME_OFFSET, isHidden: !isAuthor },
  { name: FormField.SPECIAL_INSTRUCTIONS, type: FormFieldInputType.TEXT_AREA, label: LanguageKeys.PHARMACY.SPECIAL_INSTRUCTIONS, isHidden: false }
];
