export const BloodGlucoseLanguageKeys = {
  BLOOD_GLUCOSE: {
    BLOOD_GLUCOSE: 'Blood Glucose',
    TIME_SINCE_LAST_MEAL: 'Time since last meal',
    GLUCOSE_READING_UNIT: 'mg/dL',
    GLUCOSE_READING_WITH_UNIT: 'Glucose Reading (mg/dL)',
    NURSING_ACTIONS: 'Nursing Actions',
    NOTES: 'Notes',
    BLOOD_GLUCOSE_LEVEL_MG_DL_Y_AXIS_LABEL: 'Blood Glucose Level (mg/dL)'
  }
};

export type BloodGlucoseMessageType = Partial<Record<keyof typeof BloodGlucoseLanguageKeys, string>>;

export const BloodGlucoseEnUs: BloodGlucoseMessageType = {};

export const BloodGlucoseEnAu: BloodGlucoseMessageType = {
  [BloodGlucoseLanguageKeys.BLOOD_GLUCOSE.GLUCOSE_READING_UNIT]: 'mmol/L',
  [BloodGlucoseLanguageKeys.BLOOD_GLUCOSE.GLUCOSE_READING_WITH_UNIT]: 'Glucose Reading (mmol/L)',
  [BloodGlucoseLanguageKeys.BLOOD_GLUCOSE.BLOOD_GLUCOSE_LEVEL_MG_DL_Y_AXIS_LABEL]: 'Blood Glucose Level (mmol/L)'
};
