import { cloneDeep, omit } from 'lodash';
import { ELSCommonUIConstants, ELSLoggingService } from '@els/els-ui-common-react';

const getBrowserDetails = () => omit(cloneDeep(navigator), 'mimeTypes');

export const ErrorService = {
  log: ({ err, previousState, previousStateParams }) => {
    const details = {
      error: err,
      browser: getBrowserDetails(),
      previousState,
      previousStateParams
    };
    ELSLoggingService.error('ErrorService', details);
  },

  redirect: ({ err, previousState, previousStateParams }) => {
    const details = {
      error: err,
      browser: getBrowserDetails(),
      previousState,
      previousStateParams,
      message: ELSCommonUIConstants.httpStatus.getStatusText(err.status) || 'The application has encountered an unknown error.'
    };
    ELSLoggingService.error('ErrorService', details);
  }
};
