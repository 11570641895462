export const PainAssessmentLanguageKeys = {
  DO_YOU_HAVE_PAIN_NOW: 'Do You Have Pain Now?',
  LOCATION_OF_PAIN_W_COLON: 'Location of Pain:',
  LOCATION_OF_PAIN: 'Location of Pain',
  FREQUENCY_OF_PAIN: 'Frequency of Pain',
  INTENSITY_OF_PAIN: 'Intensity of Pain',
  INTENSITY_OF_PAIN_ON_MOVEMENT: 'Intensity of Pain on Movement',
  PAIN_ASSESSMENT: 'Pain Assessment',
  BEHAVIORAL_ASSESSMENT_OF_PAIN_INTENSITY: 'Behavioral Assessment of Pain Intensity',
  NONVERBAL_CUES_OF_PAIN: 'Nonverbal Cues of Pain',
  QUALITY_OF_PAIN: 'Quality of Pain',
  RELIEVING_FACTORS: 'Relieving Factors',
  PHYSIOLOGICAL_CUES_OF_PAIN: 'Physiological Cues of Pain',
  AGGRAVATING_FACTORS: 'Aggravating Factors',
  MORE_INFORMATION: 'More Information',
  WONG_BAKER_FACES_PAIN_RATING_SCALE: 'Wong-Baker FACES Pain Rating Scale',
  WONG_BAKER_SCALE: 'Wong Baker Scale',
  WONG_BAKER_INTENSITY_OF_PAIN_ON_MOVEMENT_SCALE: 'Wong Baker Intensity of Pain on Movement Scale'
};

export type PainAssessmentMessageType = Partial<Record<keyof typeof PainAssessmentLanguageKeys, string>>;

export const PainAssessmentEnUs: PainAssessmentMessageType = {
  [PainAssessmentLanguageKeys.INTENSITY_OF_PAIN_ON_MOVEMENT]: ''
};

export const PainAssessmentEnAu: PainAssessmentMessageType = {
  [PainAssessmentLanguageKeys.INTENSITY_OF_PAIN]: 'Intensity of Pain At Rest',
  [PainAssessmentLanguageKeys.BEHAVIORAL_ASSESSMENT_OF_PAIN_INTENSITY]: 'Behavioural Assessment of Pain Intensity'
};
