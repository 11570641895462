export const NeurovascularAssessmentLanguageKeys = {
  NEUROVASCULAR_ASSESSMENT: {
    NEUROVASCULAR_ASSESSMENT: 'Neurovascular System Assessment',
    UPPER_LIMB: 'Upper Limb',
    LOWER_LIMB: 'Lower Limb',
    NOTES: 'Notes',
    PAIN: 'Pain',
    SKIN: 'Skin',
    MOVEMENT: 'Movement',
    SENSATION: 'Sensation',
    PULSES: 'Pulses',
    CAPILLARY_REFILL: 'Capillary Refill',
    SWELLING: 'Swelling',
    LOWER_LIMB_PAIN: 'Lower Limb Pain',
    UPPER_LIMB_PAIN: 'Upper Limb Pain',
    INTENSITY_OF_PAIN_AT_REST: 'Intensity of Pain at Rest',
    INTENSITY_OF_PAIN_ON_MOVEMENT: 'Intensity of Pain on Movement',
    ACTIONS_WITH_COLON: 'Actions:',
    ACTIONS: 'Actions',
    COMMENTS: 'Comments',
    SKIN_COLOUR: 'Skin Colour',
    SKIN_TEMPERATURE: 'Skin Temperature',
    PERONEAL: 'Peroneal',
    TIBIAL: 'Tibial',
    POSTERIOR_TIBIAL: 'Posterior tibial (PT)',
    DORSALIS_PEDIS: 'Dorsalis pedis (DP)',
    POPLITEAL: 'Popliteal',
    FEMORAL: 'Femoral',
    RADIAL: 'Radial',
    MEDIAN: 'Median',
    ULNAR: 'Ulnar',
    OTHER: 'Other'
  }
};

export type NeurovascularAssessmentMessageType = Partial<Record<keyof typeof NeurovascularAssessmentLanguageKeys, string>>;

export const NeurovascularAssessmentEnUs: NeurovascularAssessmentMessageType = {};

export const NeurovascularAssessmentEnAu: NeurovascularAssessmentMessageType = {};
