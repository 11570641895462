import { lazy } from 'react';
import { UserRole } from 'models/enum';
import { RoutePath } from 'constants/app.constant';
import { withAuthorize } from 'components/common';

const withStudentPermission = withAuthorize([UserRole.STUDENT]);
const withInstructorPermission = withAuthorize([UserRole.INSTRUCTOR, UserRole.ADMIN]);
const withAuthorizedPermission = withAuthorize([UserRole.STUDENT, UserRole.INSTRUCTOR, UserRole.ADMIN]);

export const withAuthorPath = (path: string) => [path, path.replace('/:assessmentId', '')];

export interface Route {
  path: string | string[];
  component: React.FC;
  isPrivate?: boolean;
  data?: {
    analytics: {
      pageType: 'AP-LP' | 'CP-CT' | 'NP-LD' | 'test';
      pageName: string;
    };
  };
  exact?: boolean;
}

export const AppRoutes = {
  getRoutes: (): Route[] => [
    {
      path: RoutePath.launch,
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "launch.page" */ 'components/pages/launch-page/LaunchPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'AP-LP',
          pageName: 'LaunchPage'
        }
      }
    },
    {
      path: RoutePath.student.assignmentSubmission,
      component: withStudentPermission(lazy(() => import(/* webpackChunkName: "assignment.submission.page" */ 'components/pages/assignment-submission/AssignmentSubmissionPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'test',
          pageName: 'AssignmentSubmissionPage'
        }
      }
    },
    {
      path: RoutePath.student.launchAssignmentEntryPoint,
      component: withStudentPermission(
        lazy(() => import(/* webpackChunkName: "launch.assignment.entrypoint.page" */ 'components/pages/student-assignment-entry-point/LaunchAssignmentEntryPointPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'LaunchAssignmentEntryPointPage'
        }
      }
    },
    {
      path: RoutePath.student.home,
      component: withStudentPermission(lazy(() => import(/* webpackChunkName: "student.home.page" */ 'components/pages/student-home/StudentHomePage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'test',
          pageName: 'StudentHomePage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.clinicalSetup),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "clinical.setup.page" */ 'components/pages/clinical-setup/ClinicalSetupPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'test',
          pageName: 'ClinicalSetupPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.authoring.metadataTool),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "metadata.tool.page" */ 'components/pages/metadata-tool-home/MetadataToolHomePage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'test',
          pageName: 'MetadataToolPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.vitalSigns.intakeOutput),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "vital.signs.intake.output.page" */ 'components/pages/vital-signs/IntakeOutputPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'IntakeOutputPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.vitalSigns.fluidBalanceChart),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "vital.signs.intake.output.page" */ 'components/pages/vital-signs/IntakeOutputPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'FluidBalanceChartPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.vitalSigns.heightWeight),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "vital.signs.height.weight.page" */ 'components/pages/vital-signs/HeightWeightPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'HeightWeightPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.vitalSigns.bloodGlucose),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "vital.signs.blood.glucose.page" */ 'components/pages/vital-signs/BloodGlucosePage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'BloodGlucosePage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.vitalSigns.vitalSigns),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "vital.signs.page" */ 'components/pages/vital-signs/VitalSignsPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'VitalSignsPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.vitalSigns.laborProgress),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "vital.signs.labor.progess.page" */ 'components/pages/vital-signs/LaborProgressPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'LaborProgressPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.preClinicalManager.diagnosis),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "pre.clinical.manager.diagnosis.page" */ 'components/pages/pre-clinical-manager/DiagnosisPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'DiagnosisPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.preClinicalManager.medications),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "pre.clinical.manager.medications.page" */ 'components/pages/pre-clinical-manager/MedicationsPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'MedicationsPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.preClinicalManager.laboratoryTests),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "pre.clinical.manager.laboratory.tests.page" */ 'components/pages/pre-clinical-manager/LaboratoryTestsPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'LaboratoryTestsPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.preClinicalManager.diagnosticTests),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "pre.clinical.manager.diagnostic.tests.page" */ 'components/pages/pre-clinical-manager/DiagnosticTestsPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'DiagnosticTestsPage'
        }
      }
    },
    {
      path: RoutePath.student.preClinicalManager.report,
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "pre.clinical.manager.report.page" */ 'components/pages/pre-clinical-manager/ReportPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'ReportPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.providerChart.providerOrder),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "provider.chart.page" */ 'components/pages/provider-chart/ProviderOrderPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'ProviderOrderPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.providerChart.historyAndPhysical.medicalHistory),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "provider.chart.medical.history.page" */ 'components/pages/provider-chart/MedicalHistoryPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'MedicalHistoryPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.providerChart.historyAndPhysical.obstetricHistory),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "provider.chart.Obstetric.history.page" */ 'components/pages/provider-chart/ObstetricHistoryPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'ObstetricHistoryPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.providerChart.historyAndPhysical.physicalExamination),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "provider.chart.physical.examination.page" */ 'components/pages/provider-chart/PhysicalExaminationPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'PhysicalExaminationPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.providerChart.historyAndPhysical.impression),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "provider.chart.impression.page" */ 'components/pages/provider-chart/ImpressionsPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'ImpressionsPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.providerChart.historyAndPhysical.activitiesDailyLiving),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "provider.chart.activitiesDailyLiving.page" */ 'components/pages/provider-chart/ActivitiesDailyLivingPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'ActivitiesDailyLivingPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.providerChart.historyAndPhysical.reviewOfSystems),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "provider.chart.reviewOfSystems.page" */ 'components/pages/provider-chart/ReviewOfSystemsPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'ReviewOfSystemsPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.providerChart.historyAndPhysical.psychiatricHistory),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "provider.chart.psychiatricHistory.page" */ 'components/pages/provider-chart/PsychiatricHistoryPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'PsychiatricHistoryPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.providerChart.historyAndPhysical.mentalStatus),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "provider.chart.mentalStatus.page" */ 'components/pages/provider-chart/MentalStatusPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'MentalStatusPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.providerChart.historyAndPhysical.landing),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "provider.chart.landing.page" */ 'components/pages/provider-chart/HistoryPhysicalPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'HistoryPhysicalPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.providerChart.progressNote),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "provider.chart.progress.note.page" */ 'components/pages/provider-chart/ProgressNotesPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'ProgressNotesPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.admissionHistory.admissionData),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "admission.history.1.page" */ 'components/pages/admission-history/AdmissionDataPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'AdmissionDataPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.admissionHistory.alcoholScreen),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "admission.history.2.page" */ 'components/pages/admission-history/AlcoholScreenPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'AlcoholScreenPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.admissionHistory.drugScreen),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "admission.history.3.page" */ 'components/pages/admission-history/DrugScreenPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'DrugScreenPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.admissionHistory.healthHistory),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "admission.history.4.page" */ 'components/pages/admission-history/HealthHistoryPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'HealthHistoryPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.admissionHistory.nutritionScreen),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "admission.history.5.page" */ 'components/pages/admission-history/NutritionScreenPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'NutritionScreenPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.admissionHistory.smokingScreen),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "admission.history.6.page" */ 'components/pages/admission-history/SmokingScreenPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SmokingScreenPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.admissionHistory.planningHospitalStay),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "admission.history.7.page" */ 'components/pages/admission-history/PlanningHospitalStayPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'PlanningHospitalStayPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.admissionHistory.immunizations),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "admission.history.8.page" */ 'components/pages/admission-history/ImmunizationsPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'ImmunizationsPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.admissionHistory.allergy),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "admission.history.9.page" */ 'components/pages/admission-history/AllergyInformationPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'AllergyInformationPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.admissionHistory.homeMedication),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "admission.history.10.page" */ 'components/pages/admission-history/HomeMedicationPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'HomeMedicationPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.admissionHistory.mobilityPhysicalTherapy),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "admission.history.11.page" */ 'components/pages/admission-history/MobilityPhysicalTherapyPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'MobilityPhysicalTherapyPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.admissionHistory.activityOccupationalTherapy),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "admission.history.12.page" */ 'components/pages/admission-history/ActivityOccupationalTherapyPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'ActivityOccupationalTherapyPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.admissionHistory.roleRelationship),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "admission.history.13.page" */ 'components/pages/admission-history/RoleRelationshipPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'RoleRelationshipPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.admissionHistory.childRiskAssessment),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "admission.history.14.page" */ 'components/pages/admission-history/ChildRiskAssessmentPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'ChildRiskAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.admissionHistory.adolescentRiskAssessment),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "admission.history.14.page" */ 'components/pages/admission-history/AdolescentRiskAssessmentPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'AdolescentRiskAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.admissionHistory.deliveryRoomSummary),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "admission.history.15.page" */ 'components/pages/admission-history/DeliveryRoomSummaryPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'DeliveryRoomSummaryPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.admissionHistory.infantToddlerAssessment),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "admission.history.16.page" */ 'components/pages/admission-history/InfantToddlerAssessmentPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'InfantToddlerAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.admissionHistory.developmentalScreen),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "admission.history.17.page" */ 'components/pages/admission-history/DevelopmentalScreenPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'DevelopmentalScreenPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.admissionHistory.psychosocialScreen),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "admission.history.18.page" */ 'components/pages/admission-history/PsychosocialScreenPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'PsychosocialScreenPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.admissionHistory.fallRiskAssessment),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "admission.history.19.page" */ 'components/pages/admission-history/FallRiskAssessmentPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'FallRiskAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.admissionHistory.landing),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "admission.history.landing.page" */ 'components/pages/admission-history/AdmissionHistoryPage'))),
      isPrivate: true
    },
    {
      path: withAuthorPath(RoutePath.student.systemAssessment.symptomAnalysis),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "system.assessment.1.page" */ 'components/pages/system-assessment/SymptomAnalysisPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SymptomAnalysisPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemAssessment.newbornAssessment),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "system.assessment.2.page" */ 'components/pages/system-assessment/NewbornAssessmentPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'NewbornAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemAssessment.obstetricAssessment),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "system.assessment.3.page" */ 'components/pages/system-assessment/ObstetricAssessmentPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'ObstetricAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemAssessment.postpartumAssessment),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "system.assessment.4.page" */ 'components/pages/system-assessment/PostpartumAssessmentPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'PostpartumAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemAssessment.cardiovascularAssessment),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "system.assessment.5.page" */ 'components/pages/system-assessment/CardiovascularAssessmentPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'CardiovascularAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemAssessment.respiratoryAssessment),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "system.assessment.6.page" */ 'components/pages/system-assessment/RespiratoryAssessmentPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'RespiratoryAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemAssessment.neurologicalAssessment),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "system.assessment.7.page" */ 'components/pages/system-assessment/NeurologicalAssessmentPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'NeurologicalAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemAssessment.integumentaryAssessment),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "system.assessment.8.page" */ 'components/pages/system-assessment/IntegumentaryAssessmentPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'IntegumentaryAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemAssessment.sensoryAssessment),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "system.assessment.9.page" */ 'components/pages/system-assessment/SensoryAssessmentPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SensoryAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemAssessment.musculoskeletalAssessment),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "system.assessment.10.page" */ 'components/pages/system-assessment/MusculoskeletalAssessmentPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'MusculoskeletalAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemAssessment.gastrointestinalAssessment),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "system.assessment.11.page" */ 'components/pages/system-assessment/GastrointestinalAssessmentPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'GastrointestinalAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemAssessment.genitourinaryAssessment),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "system.assessment.12.page" */ 'components/pages/system-assessment/GenitourinaryAssessmentPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'GenitourinaryAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemAssessment.painAssessment),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "system.assessment.13.page" */ 'components/pages/system-assessment/PainAssessmentPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'PainAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemAssessment.psychosocialAssessment),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "system.assessment.14.page" */ 'components/pages/system-assessment/PsychosocialAssessmentPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'PsychosocialAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemAssessment.safetyAssessment),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "system.assessment.15.page" */ 'components/pages/system-assessment/SafetyAssessmentPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SafetyAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemAssessment.specialPrecautionsIsolationAssessment),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "system.assessment.16.page" */ 'components/pages/system-assessment/SpecialPrecautionsIsolationAssessmentPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SpecialPrecautionsIsolationAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemAssessment.physicalAssessment),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "system.assessment.17.page" */ 'components/pages/system-assessment/CompletePhysicalAssessmentPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'CompletePhysicalAssessmentPage'
        }
      }
    },
    {
      path: RoutePath.student.systemAssessment.savedSystemAssessments,
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "system.assessment.18.page" */ 'components/pages/system-assessment/SavedSystemAssessmentsPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SavedPatientChartingPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemAssessment.skinAssessment),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "system.assessment.19.page" */ 'components/pages/system-assessment/SkinAssessmentPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SkinAssessmentPage'
        }
      }
    },
    {
      path: RoutePath.student.systemAssessment.neurovascularAssessment,
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "system.assessment.20.page" */ 'components/pages/system-assessment/NeurovascularAssessmentPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'NeurovascularAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemNursingInterventions.neurologicalInterventions),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "system.nursing.interventions.1.page" */ 'components/pages/system-nursing-interventions/NeurologicalInterventionsPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'NeurologicalInterventionsPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemNursingInterventions.cardiovascularPeripheralVascularInterventions),
      component: withAuthorizedPermission(
        lazy(
          () =>
            import(/* webpackChunkName: "system.nursing.interventions.2.page" */ 'components/pages/system-nursing-interventions/CardiovascularPeripheralVascularInterventionsPage')
        )
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'CardiovascularPeripheralVascularInterventionsPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemNursingInterventions.respiratoryInterventions),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "system.nursing.interventions.3.page" */ 'components/pages/system-nursing-interventions/RespiratoryInterventionsPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'RespiratoryInterventionsPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemNursingInterventions.musculoskeletalInterventions),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "system.nursing.interventions.4.page" */ 'components/pages/system-nursing-interventions/MusculoskeletalInterventionsPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'MusculoskeletalInterventionsPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemNursingInterventions.gastrointestinalInterventions),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "system.nursing.interventions.5.page" */ 'components/pages/system-nursing-interventions/GastrointestinalInterventionsPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'GastrointestinalInterventionsPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemNursingInterventions.genitourinaryInterventions),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "system.nursing.interventions.6.page" */ 'components/pages/system-nursing-interventions/GenitourinaryInterventionsPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'GenitourinaryInterventionsPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemNursingInterventions.integumenatryInterventions),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "system.nursing.interventions.7.page" */ 'components/pages/system-nursing-interventions/IntegumentaryInterventionsPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'IntegumenatryInterventionsPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemNursingInterventions.painInterventions),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "system.nursing.interventions.8.page" */ 'components/pages/system-nursing-interventions/PainInterventionsPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'PainInterventionsPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemNursingInterventions.psychosocialInterventions),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "system.nursing.interventions.9.page" */ 'components/pages/system-nursing-interventions/PsychosocialInterventionsPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'PsychosocialInterventionsPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.systemNursingInterventions.safetyInterventions),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "system.nursing.interventions.10.page" */ 'components/pages/system-nursing-interventions/SafetyInterventionsPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SafetyInterventionsPage'
        }
      }
    },
    {
      path: RoutePath.student.systemNursingInterventions.savedSystemNursingInterventions,
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "system.nursing.interventions.11.page" */ 'components/pages/system-nursing-interventions/SavedSystemNursingInterventionsPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SavedSystemNursingInterventionsPage'
        }
      }
    },
    {
      path: RoutePath.student.systemNursingInterventions.laborInterventions,
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "system.nursing.interventions.12.page" */ 'components/pages/system-nursing-interventions/LaborInterventionsPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'LaborInterventionsPage'
        }
      }
    },
    {
      path: RoutePath.student.systemNursingInterventions.postpartumInterventions,
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "system.nursing.interventions.13.page" */ 'components/pages/system-nursing-interventions/PostpartumInterventionsPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'PostpartumInterventionsPage'
        }
      }
    },
    {
      path: RoutePath.student.basicNursingCare.savedBasicNursingCare,
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "basic.nursing.care.1.page" */ 'components/pages/basic-nursing-care/SavedBasicNursingCarePage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SavedBasicNursingCarePage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.basicNursingCare.safety),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "basic.nursing.care.2.page" */ 'components/pages/basic-nursing-care/SafetyPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SafetyPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.basicNursingCare.activity),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "basic.nursing.care.3.page" */ 'components/pages/basic-nursing-care/ActivityPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'ActivityPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.basicNursingCare.hygieneDressingsComfort),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "basic.nursing.care.4.page" */ 'components/pages/basic-nursing-care/HygieneDressingsComfortPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'HygieneDressingsComfortPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.basicNursingCare.nutritionHydration),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "basic.nursing.care.5.page" */ 'components/pages/basic-nursing-care/NutritionHydrationPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'NutritionHydrationPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.basicNursingCare.elimination),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "basic.nursing.care.6.page" */ 'components/pages/basic-nursing-care/EliminationPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'EliminationPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.basicNursingCare.skinCare),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "basic.nursing.care.7.page" */ 'components/pages/basic-nursing-care/SkinCarePage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SkinCarePage'
        }
      }
    },
    {
      path: RoutePath.student.specialCharts.savedSpecialCharts,
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.0.page" */ 'components/pages/special-charts/SavedSpecialChartsPage'))),
      isPrivate: true
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.miscellaneousNursingNotes),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.1.page" */ 'components/pages/special-charts/MiscellaneousNursingNotesPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'MiscellaneousNursingNotesPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.sbarReport),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.2.page" */ 'components/pages/special-charts/SBARReportPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SBARReportPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.aims),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.3.page" */ 'components/pages/special-charts/AIMSPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'AIMSPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.ballardMaturationalAssessment),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.4.page" */ 'components/pages/special-charts/BallardMaturationalAssessmentPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'BallardMaturationalAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.bradenScale),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.5.page" */ 'components/pages/special-charts/BradenScalePage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'BradenScalePage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.ciwaAr),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.6.page" */ 'components/pages/special-charts/ClinicalInstituteScalePage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'ClinicalInstituteScalePage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.dailyPsychosocialAssessment),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.7.page" */ 'components/pages/special-charts/DailyPsychosocialAssessmentPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'DailyPsychosocialAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.deliveryRoomRecord),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.8.page" */ 'components/pages/special-charts/DeliveryRoomRecordPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'DeliveryRoomRecordPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.edinburghPostnatalDepressionScale),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "special.charts.9.page" */ 'components/pages/special-charts/EdinburghPostnatalDepressionScalePage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'EdinburghPostnatalDepressionScalePage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.flaccScale),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.10.page" */ 'components/pages/special-charts/FLACCScalePage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'FLACCScalePage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.functionalDementiaScale),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.11.page" */ 'components/pages/special-charts/FunctionalDementiaScalePage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'FunctionalDementiaScalePage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.geriatricDepressionScale),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.12.page" */ 'components/pages/special-charts/GeriatricDepressionScalePage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'GeriatricDepressionScalePage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.glasgowComaScale),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.13.page" */ 'components/pages/special-charts/GlasgowComaScalePage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'GlasgowComaScalePage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.hamiltonAnxietyRatingScale),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.14.page" */ 'components/pages/special-charts/HamiltonAnxietyRatingScalePage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'HamiltonAnxietyRatingScalePage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.humptyDumptyFallsScale),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.15.page" */ 'components/pages/special-charts/HumptyDumptyFallsScalePage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'HumptyDumptyFallsScalePage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.maniaQuestionnaire),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.16.page" */ 'components/pages/special-charts/ManiaQuestionnairePage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'ManiaQuestionnairePage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.moodDisorderQuestionnaire),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.17.page" */ 'components/pages/special-charts/MoodDisorderQuestionnairePage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'MoodDisorderQuestionnairePage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.modifiedBradenQScale),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.18.page" */ 'components/pages/special-charts/ModifiedBradenQScalePage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'ModifiedBradenQScalePage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.morseFallScale),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.19.page" */ 'components/pages/special-charts/MorseFallScalePage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'MorseFallScalePage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.neurologicalChecks),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.20.page" */ 'components/pages/special-charts/NeurologicalChecksPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'NeurologicalChecksPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.obstructiveSleepApnea),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.21.page" */ 'components/pages/special-charts/ObstructiveSleepApneaPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'ObstructiveSleepApneaPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.overtAggressionScale),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.22.page" */ 'components/pages/special-charts/OvertAggressionScalePage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'OvertAggressionScalePage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.pcaFlowsheet),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.24.page" */ 'components/pages/special-charts/PCAFlowsheetPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'PCAFlowsheetPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.pediatricGlasgowComaScale),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.25.page" */ 'components/pages/special-charts/PediatricGlasgowComaScalePage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'PediatricGlasgowComaScalePage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.restraints),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.26.page" */ 'components/pages/special-charts/RestraintsPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'RestraintsPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.slumsExamination),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.27.page" */ 'components/pages/special-charts/SLUMSExaminationPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SLUMSExaminationPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.seizureActivityDocumentation),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.28.page" */ 'components/pages/special-charts/SeizureActivityDocumentationPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SeizureActivityDocumentationPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.sleepDisorderScreening),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.29.page" */ 'components/pages/special-charts/SleepDisorderScreeningPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SleepDisorderScreeningPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.specialCharts.newbornAssessment),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "special.charts.30.page" */ 'components/pages/system-assessment/NewbornAssessmentPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'NewbornAssessmentPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.wounds.savedWounds),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "wounds.01.page" */ 'components/pages/wounds/SavedWoundsPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SavedWoundsPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.wounds.wounds),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "wounds.02.page" */ 'components/pages/wounds/WoundsPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'WoundsPage'
        }
      }
    },
    {
      path: RoutePath.student.ivs.savedIVs,
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "ivs.01.page" */ 'components/pages/ivs/SavedIVsPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SavedIVsPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.ivs.ivs),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "ivs.02.page" */ 'components/pages/ivs/IVsPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'IVsPage'
        }
      }
    },
    {
      path: RoutePath.student.drainsTubes.savedDrainsTubes,
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "drains.tubes.1.page" */ 'components/pages/drains-tubes/SavedDrainsTubesPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SavedDrainsTubesPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.drainsTubes.drainsTubes),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "drains.tubes.2.page" */ 'components/pages/drains-tubes/DrainsTubesPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'DrainsTubes'
        }
      }
    },
    {
      path: RoutePath.student.ostomies.savedOstomies,
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "ostomies.01.page" */ 'components/pages/ostomies/SavedOstomiesPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SavedOstomiesPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.ostomies.ostomies),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "ostomies.02.page" */ 'components/pages/ostomies/OstomiesPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'OstomiesPage'
        }
      }
    },
    {
      path: RoutePath.student.injections.savedInjections,
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "injections.01.page" */ 'components/pages/injections/SavedInjectionsPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SavedInjectionsPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.injections.injections),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "injections.02.page" */ 'components/pages/injections/InjectionsPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'InjectionsPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.orders.pharmacy),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "order.entry.pharmacy.page" */ 'components/pages/order-entry/PharmacyPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'PharmacyPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.orders.laboratory),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "order.entry.laboratory.page" */ 'components/pages/order-entry/LaboratoryPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'LaboratoryPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.orders.diagnosticTests),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "order.entry.diagnostic.tests.page" */ 'components/pages/order-entry/DiagnosticTestsPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'DiagnosticTestPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.orders.therapies),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "order.entry.therapies.page" */ 'components/pages/order-entry/TherapiesPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'TherapiesPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.orders.generalOrders),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "order.entry.general.orders.page" */ 'components/pages/order-entry/GeneralOrdersPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'GeneralOrdersPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.orders.nutrition),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "order.entry.nutrition.page" */ 'components/pages/order-entry/NutritionPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'NutritionPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.carePlan.carePlan),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "care.plan.02.page" */ 'components/pages/care-plan/CarePlanPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'CarePlan'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.mar),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "mar.page" */ 'components/pages/mar/MarPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'MarPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.patientCard),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "patient.card.page" */ 'components/pages/patient-card/PatientCardPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'PatientCardPage'
        }
      }
    },
    {
      path: RoutePath.student.patientTeaching.savedPatientTeaching,
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "patient.teaching.1.page" */ 'components/pages/patient-teaching/SavedPatientTeachingPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SavedPatientTeachingPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.patientTeaching.patientTeaching),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "patient.teaching.2.page" */ 'components/pages/patient-teaching/PatientTeachingPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'PatientTeachingPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.patientData.allergies),
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "patient.data.allergies.page" */ 'components/pages/patient-data/AllergiesPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'AllergiesPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.patientData.medicationReconciliation),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "patient.data.medicationReconciliation.page" */ 'components/pages/patient-data/MedicationReconciliationPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'MedicationReconciliationPage'
        }
      }
    },
    {
      path: withAuthorPath(RoutePath.student.patientData.homeMedication),
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "patient.data.home.medication.page" */ 'components/pages/patient-data/home-medication/HomeMedicationPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'HomeMedicationPage'
        }
      }
    },
    {
      path: RoutePath.student.summary,
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "summary.page" */ 'components/pages/summary/SummaryPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'SummaryPage'
        }
      }
    },
    {
      path: RoutePath.student.blankChartingEntryPoint,
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "blank.charting.instruction.page" */ 'components/pages/blank-charting-instruction/BlankChartingInstructionPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'BlankChartingInstructionPage'
        }
      }
    },
    {
      path: RoutePath.student.clinicalEntryPoint,
      component: withAuthorizedPermission(
        lazy(() => import(/* webpackChunkName: "clinical.entry.point.page" */ 'components/pages/student-assignment-entry-point/ClinicalEntryPointPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'ClinicalEntryPointPage'
        }
      }
    },
    {
      path: RoutePath.instructor.author,
      component: withInstructorPermission(
        lazy(() => import(/* webpackChunkName: "instructor.author.home.page" */ 'components/pages/instructor-author-home/InstructorAuthorHomePage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'InstructorAuthorHomePage'
        }
      }
    },
    {
      path: RoutePath.student.caseStudyLanding,
      component: withAuthorizedPermission(lazy(() => import(/* webpackChunkName: "case.study.landing.page" */ 'components/pages/case-study-landing/CaseStudyLandingPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'NP-LD',
          pageName: 'CaseStudyLandingPage'
        }
      }
    },
    {
      path: RoutePath.instructor.configureAssignment,
      component: withInstructorPermission(
        lazy(() => import(/* webpackChunkName: "instructor.configure.assignment.page" */ 'components/pages/create-assignment-workflow/ConfigureAssignmentPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'ConfigureAssignmentPage'
        }
      }
    },
    {
      path: RoutePath.instructor.contentSelection,
      component: withInstructorPermission(
        lazy(() => import(/* webpackChunkName: "instructor.content.selection.page" */ 'components/pages/create-assignment-workflow/ContentSelectionPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'ContentSelectionPage'
        }
      }
    },
    {
      path: RoutePath.instructor.grade,
      component: withInstructorPermission(lazy(() => import(/* webpackChunkName: "instructor.grading.progress.page" */ 'components/pages/grading-progress/GradingProgressPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'GradingProgressPage'
        }
      }
    },
    {
      path: RoutePath.instructor.studentAssessment,
      component: withInstructorPermission(lazy(() => import(/* webpackChunkName: "instructor.assessment.detail.page" */ 'components/pages/grading-progress/AssessmentDetailPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'AssessmentDetailPage'
        }
      }
    },
    {
      path: RoutePath.instructor.home,
      component: withInstructorPermission(
        lazy(() => import(/* webpackChunkName: "instructor.assignment.selection.page" */ 'components/pages/create-assignment-workflow/AssignmentSelectionPage'))
      ),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'AssignmentSelectionPage'
        }
      },
      exact: true
    },
    {
      path: RoutePath.instructor.blankCharting,
      component: withInstructorPermission(lazy(() => import(/* webpackChunkName: "instructor.blank.charting.page" */ 'components/pages/blank-charting/BlankChartingPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'BlankChartingPage'
        }
      }
    },
    {
      path: RoutePath.instructor.caseStudyDemo,
      component: withInstructorPermission(lazy(() => import(/* webpackChunkName: "instructor.case.study.demo.page" */ 'components/pages/case-study-demo/CaseStudyDemoPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'CaseStudyDemoPage'
        }
      }
    },
    {
      path: RoutePath.instructor.editAssignment,
      component: withInstructorPermission(lazy(() => import(/* webpackChunkName: "instructor.edit.assignment.page" */ 'components/pages/edit-assignment/EditAssignmentPage'))),
      isPrivate: true,
      data: {
        analytics: {
          pageType: 'CP-CT',
          pageName: 'EditAssignmentPage'
        }
      }
    },
    {
      path: RoutePath.authoring.caseStudyMetadata,
      component: withInstructorPermission(
        lazy(() => import(/* webpackChunkName: "case.study.metadata.authoring.page" */ 'components/pages/case-study-authoring/CaseStudyMetadataAuthoringPage'))
      ),
      isPrivate: true
    },
    {
      path: RoutePath.redirect,
      component: lazy(() => import(/* webpackChunkName: "redirect.page" */ 'components/pages/redirect-page/RedirectPage')),
      data: {
        analytics: {
          pageType: 'AP-LP',
          pageName: 'RedirectLaunchPage'
        }
      }
    }
  ]
};
