export const SBARReportLanguageKeys = {
  SBAR_REPORT: {
    SBAR_REPORT: 'SBAR Report',
    IDENTIFY: 'Identify',
    INTRODUCE_SELF_AND_INTRODUCE_PATIENT: 'Introduce self (name, role) and introduce patient (name, DOB, diagnosis)',
    SITUATION: 'Situation',
    SUMMARY_OF_THE_SITUATION: 'Summary of the situation',
    OBSERVATIONS: 'Observations',
    MOST_RECENT_VITAL_SIGNS_INTRAVENOUS_LINES_OTHER_DEVICES: 'Most recent vital signs. Intravenous lines, other devices (drains)',
    BACKGROUND: 'Background',
    PERTINENT_BACKGROUND_OF_THE_SITUATION: 'Pertinent background of the situation',
    ASSESSMENT: 'Assessment',
    ASSESSMENT_OF_THE_SITUATION: 'Assessment of the situation',
    RECOMMENDATION: 'Recommendation',
    RECOMMENDATIONS_AND_OR_REQUEST_FOR_ORDERS: 'Recommendations and/or request for orders'
  }
};

export type SBARReportMessageType = Partial<Record<keyof typeof SBARReportLanguageKeys, string>>;

export const SBARReportEnUs: SBARReportMessageType = {
  [SBARReportLanguageKeys.SBAR_REPORT.SBAR_REPORT]: 'SBAR Report'
};

export const SBARReportEnAu: SBARReportMessageType = {
  [SBARReportLanguageKeys.SBAR_REPORT.SBAR_REPORT]: 'ISOBAR Report'
};
