export const NutritionOrderEntryLanguageKeys = {
  NUTRITION_ORDER: {
    NUTRITION_ORDER: 'Nutrition Order',
    CHART_INPUTS: 'Chart Inputs',
    DIET: 'Diet',
    ML: 'mL',
    DATE_TIME_FIELD: 'Order Start',
    FLUID_RESTRICTION: 'Fluid Restriction',
    FLUID_RESTRICTION_PARAMETERS: 'Fluid Restriction Parameters',
    NUTRITION: 'Nutrition',
    DIET_TYPE: 'Diet Type',
    CONSISTENCY: 'Consistency',
    TUBE_FEEDING: 'Tube Feeding',
    NOTHING_BY_MOUNT: 'Nothing By Mouth',
    CALORIE_COUNT: 'Calorie Count',
    TYPE_MODEL: 'Type / Model',
    DAY_SHIFT: 'Day Shift',
    EVENING_SHIFT: 'Evening Shift',
    NIGHT_SHIFT: 'Night Shift',
    SPECIAL_DIET: 'Special Diet',
    TOTAL_HOUR: '24 Hour Total',
    FORMULA: 'Formula',
    ORDERS: 'Orders',
    ORDER_START: 'Order Start',
    ORDER_TYPE: 'Order Type'
  }
};

export type NutritionOrderEntryMessageType = Partial<Record<keyof typeof NutritionOrderEntryLanguageKeys, string>>;

export const NutritionOrderEntryEnUs: NutritionOrderEntryMessageType = {};

export const NutritionOrderEntryEnAu: NutritionOrderEntryMessageType = {};
