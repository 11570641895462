export const PatientChartLanguageKeys = {
  LABEL: {
    STUDENT_INFO: 'Student info'
  }
};

export type CaseStudyMessageType = Partial<Record<keyof typeof PatientChartLanguageKeys, string>>;

export const PatientChartEnUs: CaseStudyMessageType = {
  [PatientChartLanguageKeys.LABEL.STUDENT_INFO]: 'Student info'
};
export const PatientChartEnAu: CaseStudyMessageType = {
  [PatientChartLanguageKeys.LABEL.STUDENT_INFO]: 'Nurse info'
};
