export const HeightWeightLanguageKeys = {
  HEIGHT_WEIGHT: {
    HEIGHT_WEIGHT: 'Height - Weight',
    HEIGHT_DISPLAY: 'Height display',
    WEIGHT_DISPLAY: 'Weight display',
    Y_LINE_IN_THE_HEIGHT_WEIGHT_GRAPH: 'Y line in the height weight graph',
    WEIGHT_IN_POUNDS: 'Weight in Pounds',
    WEIGHT: 'Weight',
    HEIGHT: 'Height',
    FEET: 'Feet',
    INCHES: 'Inches',
    CM: 'Cm',
    POUNDS: 'Pounds',
    OUNCES: 'Ounces',
    KGS: 'Kgs',
    GRAMS: 'Grams',
    METHOD: 'Method',
    ANTERIOR_FONTANEL: 'Anterior Fontanel',
    POSTERIOR_FONTANEL: 'Posterior Fontanel',
    NOTES: 'Notes',
    TOTAL_HEIGHT: 'totH',
    TOTAL_WEIGHT: 'totW',
    CHART_INPUTS: 'Chart Inputs',
    INFANT_MEASUREMENTS: 'Infant Measurements',
    ABDOMINAL_GIRTH: 'Abdominal Girth',
    OR: 'OR',
    HEAD_CIRCUMFERENCE: 'Head Circumference',
    CHEST_CIRCUMFERENCE: 'Chest Circumference',
    ABDOMINAL_GIRTH_IN_CM: 'Abdominal Girth in cm'
  }
};

export type HeightWeightMessageType = Partial<Record<keyof typeof HeightWeightLanguageKeys, string>>;

export const HeightWeightEnUs: HeightWeightMessageType = {
  [HeightWeightLanguageKeys.HEIGHT_WEIGHT.HEIGHT_WEIGHT]: 'Height - Weight',
  [HeightWeightLanguageKeys.HEIGHT_WEIGHT.WEIGHT_DISPLAY]: '{poundsAndOunces} / {kilogramsAndGrams}',
  [HeightWeightLanguageKeys.HEIGHT_WEIGHT.Y_LINE_IN_THE_HEIGHT_WEIGHT_GRAPH]: '{pounds}',
  [HeightWeightLanguageKeys.HEIGHT_WEIGHT.WEIGHT_IN_POUNDS]: 'Weight in Pounds',
  [HeightWeightLanguageKeys.HEIGHT_WEIGHT.HEIGHT_DISPLAY]: '{feetAndInches}'
};

export const HeightWeightEnAu: HeightWeightMessageType = {
  [HeightWeightLanguageKeys.HEIGHT_WEIGHT.HEIGHT_WEIGHT]: 'Height / Weight',
  [HeightWeightLanguageKeys.HEIGHT_WEIGHT.WEIGHT_DISPLAY]: '{kilogramsAndGrams}',
  [HeightWeightLanguageKeys.HEIGHT_WEIGHT.Y_LINE_IN_THE_HEIGHT_WEIGHT_GRAPH]: '{kilograms}',
  [HeightWeightLanguageKeys.HEIGHT_WEIGHT.WEIGHT_IN_POUNDS]: 'Weight in Kgs / Grams',
  [HeightWeightLanguageKeys.HEIGHT_WEIGHT.HEIGHT_DISPLAY]: '{centimeters} cm'
};
