import { connectRouter, routerMiddleware } from 'connected-react-router';
import { ELSCommonUIConstants, ELSStorageHelper } from '@els/els-ui-common-react';
import { AppConstant } from 'constants/app.constant';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { appActions, reducer as appReducer } from './ducks/app';
import { instructorActions, reducer as instructorReducer } from './ducks/instructor';
import { studentActions, reducer as studentReducer } from './ducks/student';

const restoreState = (store) => {
  const rootStateFromStorage = ELSStorageHelper.driver(ELSCommonUIConstants.security.Locker.DefaultDriver).namespace('').get(AppConstant.PERSIST_KEY);

  if (!rootStateFromStorage) return;
  const appState = rootStateFromStorage[AppConstant.reduxResources.APP_STATE];

  /* istanbul ignore else */
  if (appState) {
    appState.authoringData = undefined;
    store.dispatch(appActions.restoreState(appState));
  }

  const studentState = rootStateFromStorage[AppConstant.reduxResources.STUDENT_STATE];
  /* istanbul ignore else */
  if (studentState) {
    store.dispatch(studentActions.restoreStudentState(studentState));
  }

  const instructorState = rootStateFromStorage[AppConstant.reduxResources.INSTRUCTOR_STATE];
  /* istanbul ignore else */
  if (instructorState) {
    store.dispatch(instructorActions.restoreInstructorState(instructorState));
  }
};

const createDefaultReducers = (history) =>
  combineReducers({
    [AppConstant.reduxResources.ROUTER]: connectRouter(history),
    [AppConstant.reduxResources.APP_STATE]: appReducer,
    [AppConstant.reduxResources.STUDENT_STATE]: studentReducer,
    [AppConstant.reduxResources.INSTRUCTOR_STATE]: instructorReducer
  });

const actionTypesWhitelist = ['@@router/LOCATION_CHANGE', 'SIMS/SET_FILTER_CONFIG', 'SIMS/SET_LOCALE'];
const storageMiddleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    const result = next(action);
    if (actionTypesWhitelist.includes(action.type)) {
      ELSStorageHelper.driver(ELSCommonUIConstants.security.Locker.DefaultDriver).namespace('').put(AppConstant.PERSIST_KEY, getState());
    }
    return result;
  };

const configureStore = (history) => {
  const middlewares = [thunkMiddleware, routerMiddleware(history), storageMiddleware];

  if (process.env.NODE_ENV === 'development') {
    middlewares.push(createLogger({ collapsed: true }));
  }

  const enhancers = [applyMiddleware(...middlewares)];
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const defaultReducers = createDefaultReducers(history);
  const rootReducer = (state, action) => {
    if (action.type === AppConstant.RESET_ACTION) {
      // eslint-disable-next-line no-param-reassign
      state = undefined;
    }

    return defaultReducers(state, action);
  };

  const store = createStore(rootReducer, {}, composeEnhancers(...enhancers));
  restoreState(store);

  return store;
};

export { configureStore };
