export const AssignmentSelectionLanguageKeys = {
  ASSIGNMENT_SELECTION: {
    EMPTY_EHR_SIMSCHART: 'Empty EHR (SimChart)',
    EMPTY_EHR_SIMSCHART_DESCRIPTION: 'Students practice simulated EHR without a provided patient scenario'
  }
};

export type AssignmentSelectionMessageType = Partial<Record<keyof typeof AssignmentSelectionLanguageKeys, string>>;

export const AssignmentSelectionEnUs: AssignmentSelectionMessageType = {};

export const AssignmentSelectionEnAu: AssignmentSelectionMessageType = {
  [AssignmentSelectionLanguageKeys.ASSIGNMENT_SELECTION.EMPTY_EHR_SIMSCHART]: 'Empty EMR (SimChart)',
  [AssignmentSelectionLanguageKeys.ASSIGNMENT_SELECTION.EMPTY_EHR_SIMSCHART_DESCRIPTION]: 'Students practice simulated EMR without provided patient scenario'
};
