export const TherapiesOrderEntryLanguageKeys = {
  THERAPIES_ORDER_ENTRY: {
    ORDER_TYPE: 'Therapy Order Type',
    ORDER_DESCRIPTION: 'Order Description',
    ORDER_DESCRIPTION_TEXT: 'Order Description Detail',
    ORDER_PRIORITY: 'Order Priority',
    TIMES_PER_DAY: 'Times per Day',
    TRANSPORTATION: 'Transportation',
    IV_REQUIRED: 'IV Required',
    OXYGEN_REQUIRED: 'Oxygen Required',
    TIME: 'Time',
    SPECIAL_INSTRUCTIONS: 'Special Instructions',
    FUNCTIONAL_ASSESSMENT: 'Functional Assessment',
    OXYGEN_SATURATION_ROOM_AIR: 'Oxygen Saturation - Room Air',
    OXYGEN_SATURATION_OXYGEN_SUPP: 'Oxygen Saturation - Oxygen Supp',
    OXYGEN_THERAPY_DETAIL: 'Oxygen Therapy with Details',
    ANTERIAL_BLOOD_DASES: 'Anterial Blood Dases',
    AEROSOL_TREATMENT: 'Aerosol Treatment',
    BILEVEL_POSITIVE_AIRWAY_PRESSURE: 'Bilevel Positive Airway Pressure (BI-PAP)',
    CONTINUOUS_POSITIVE_AIRWAY_PRESSURE: 'Continuous Positive Airway Pressure (CPAP)',
    CHEST_PHYSIOTHERAPY_POSTURAL_DRAINAGE: 'Chest Physiotherapy - Postural Drainage (CPT-PD)',
    INCENTIVE_SPIROMETER: 'Incentive Spirometer',
    INDUCED_SPUTUM: 'Induced Sputum',
    INHALER: 'Inhaler',
    POSITIVE_EXPIRATORY_PRESSURE_THERAPY: 'Positive Expiratory Pressure (PEP) Therapy',
    FUNCTION_TEST: 'Function Test (PFT)',
    SUCTIONING: 'Suctioning',
    VENTILATOR: 'Ventilator',
    TREATMENT: 'Treatment',
    PATIENT_FAMILY_TEACHING: 'Patient - Family Teaching',
    RESPIRATORY_THERAPIST_NAME: 'Respiratory Therapist Name',
    RESPIRATORY_THERAPIST_SIGNATURE: 'Respiratory Therapist Signature',
    EATING: 'Eating',
    GROOMING: 'Grooming',
    UPPER_EXTREMITY_BATH: 'Upper Extremity Bath',
    LOWER_EXTREMITY_BATH: 'Lower Extremity Bath',
    UPPER_EXTRIMITY_DRESS: 'Upper Extremity Dress',
    LOWER_EXTRIMITY_DRESS: 'Lower Extemity Dress',
    TOILETING: 'Toileting',
    SHOWER_TUB: 'Shower - Tub',
    LOCATION: 'Location',
    THERAPUTIC_EXERCISES: 'Theraputic Exercises',
    OCCUPATIONAL_THERAPIST_NAME: 'Occupational Therapist Name',
    OCCUPATIONAL_THERAPIST_SIGNATURE: 'Occupational Therapist Signature',
    FUNCTIONAL_MOBILITY: 'Functional Mobility',
    LEFT: 'Left',
    RIGHT: 'Right',
    SIT_SUPINE: 'Sit <-> Supine',
    SIT_STAND: 'Sit <-> Stand',
    PIVOL: 'Pivol',
    DEVIATIONS: 'Deviations',
    STAIRS: 'Stairs',
    DEVICE: 'Device',
    ASSISTANCE: 'Assistance',
    WEIGHT_BEARING: 'Weight Bearing',
    DISTANCE: 'Distance',
    PHYSICAL_THERAPIST_NAME: 'Physical Therapist Name',
    PHYSICAL_THERAPIST_SIGNATURE: 'Physical Therapist Signature',
    CONSULTATION_TYPE: 'Consultation Type',
    REASON_CONSULTATION: 'Reason for Consultation',
    REFERRAL_SOURCE: 'Referral Source',
    HISTORY_PRESENT_ILLNESS: 'History of Present Illness',
    FINDINGS: 'Findings',
    IMPRESSIONS: 'Impressions',
    PLAN: 'Plan',
    SPEECH_THERAPIST_NAME: 'Speech Therapist Name',
    SPEECH_THERAPIST_SIGNATURE: 'Speech Therapist Signature',
    COMPLETE: 'Complete'
  }
};

export type TherapiesOrderEntryMessageType = Partial<Record<keyof typeof TherapiesOrderEntryLanguageKeys, string>>;

export const TherapiesOrderEntryEnUs: TherapiesOrderEntryMessageType = {};

export const TherapiesOrderEntryEnAu: TherapiesOrderEntryMessageType = {};
