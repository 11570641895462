export const LaboratoryLanguageKeys = {
  LABORATORY: {
    CATEGORY: 'Category',
    ORDER_DESCRIPTION: 'Order Description',
    ORDER_PRIORITY: 'Order Priority',
    ORDER_FREQUENCY: 'Order Frequency',
    SPECIMEN: 'Specimen',
    SPECIAL_INSTRUCTIONS: 'Special Instructions',
    ORDER_START_DATE: 'Order Start Date',
    ORDER_START_TIME: 'Order Start Time',
    RESULTS_RECEIVED_DATE: 'Results Received Date',
    RESULTS_RECEIVED_TIME: 'Results Received Time',
    PENDING: 'Pending',
    COMPLETED: 'Completed'
  }
};

export type LaboratoryMessageType = Partial<Record<keyof typeof LaboratoryLanguageKeys, string>>;

export const LaboratoryEnUs: LaboratoryMessageType = {};

export const LaboratoryEnAu: LaboratoryMessageType = {};
