import { FeatureRS } from 'models/api-response';
import { FeatureName } from 'constants/app.constant';
import { apiHelper } from 'helpers';
import { cacheService } from 'services';

export const getFeature = (featureName: string, defaultValue = 'false'): Promise<FeatureRS> =>
  apiHelper.eolsApi.get(`/api/features/app/SIMSNG/key/${featureName}?defaultValue=${defaultValue}`);

const getFeatureValue = (featureName: string): Promise<boolean> =>
  getFeature(featureName)
    .then((res) => res.data)
    .then((feature) => feature.featureValue === 'true')
    .catch(() => false);

export const isShowAssignmentStudents = (): Promise<boolean> => getFeatureValue(FeatureName.SHOW_ASSIGNMENT_STUDENTS);

export const isShowClinicalUnit = (): Promise<boolean> => cacheService.withCache('showClinicalUnit', () => getFeatureValue(FeatureName.SHOW_CLINICAL_UNIT));

export const isAllowToCompletePastDueAssignment = (): Promise<boolean> => getFeatureValue(FeatureName.ALLOW_COMPLETE_PAST_DUE_ASSIGNMENT);

export const isShowPendoHeaderUS = (): Promise<boolean> => getFeatureValue(FeatureName.SHOW_PENDO_HEADER_US);

export const isShowPendoHeaderANZ = (): Promise<boolean> => getFeatureValue(FeatureName.SHOW_PENDO_HEADER_ANZ);

export const isInProgressGradingEnabled = (): Promise<boolean> => getFeatureValue(FeatureName.ENABLE_IN_PROGRESS_GRADING);
