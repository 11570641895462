import { RoutePath } from 'constants/app.constant';

export const SummaryLanguageKeys = {
  SUMMARY: {
    INTAKE_OUTPUT_ROUTE_PATH: 'INTAKE_OUTPUT_ROUTE_PATH',
    SUMMARY: 'Summary',
    PATIENT_MONITORING: 'Patient Monitoring',
    VITAL_SIGNS: 'SUMMARY_VITAL_SIGNS',
    PAIN: 'Pain',
    BLOOD_GLUCOSE: 'Blood Glucose',
    INTAKE: 'Intake',
    OUTPUT: 'Output',
    PAIN_SUMMARY: 'PAIN_SUMMARY',
    BLOOD_GLUCOSE_SUMMARY: 'BLOOD_GLUCOSE_SUMMARY'
  }
};

export type SummaryMessageType = Partial<Record<keyof typeof SummaryLanguageKeys, string>>;

export const SummaryEnUs: SummaryMessageType = {
  [SummaryLanguageKeys.SUMMARY.INTAKE_OUTPUT_ROUTE_PATH]: RoutePath.student.vitalSigns.intakeOutput,
  [SummaryLanguageKeys.SUMMARY.VITAL_SIGNS]: 'Vital Signs',
  [SummaryLanguageKeys.SUMMARY.PAIN_SUMMARY]: '{chartingAt} - Numeric pain level: {numericPainLevel}',
  [SummaryLanguageKeys.SUMMARY.BLOOD_GLUCOSE_SUMMARY]: '{chartingAt} - {glucoseReading} {unit}'
};

export const SummaryEnAu: SummaryMessageType = {
  [SummaryLanguageKeys.SUMMARY.INTAKE_OUTPUT_ROUTE_PATH]: RoutePath.student.vitalSigns.fluidBalanceChart,
  [SummaryLanguageKeys.SUMMARY.VITAL_SIGNS]: 'A to E Assessment',
  [SummaryLanguageKeys.SUMMARY.PAIN_SUMMARY]: '{chartingAt} — Rest: {numericPainLevel}, Movement: {numericPainLevelOnMovement}',
  [SummaryLanguageKeys.SUMMARY.BLOOD_GLUCOSE_SUMMARY]: '{chartingAt} - {glucoseReading} {unit}'
};
