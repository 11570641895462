export const CardiovascularInterventionsLanguageKeys = {
  CARDIOVASCULAR_INTERVENTIONS: {
    CARDIOVASCULAR_PERIPHERAL_INTERVENTIONS: 'Cardiovascular - Peripheral Vascular Interventions',
    CARDIOVASCULAR_PERIPHERAL_CARE: 'Cardiovascular - Peripheral Vascular Care',
    CARDIOVASCULAR_CARE: 'Cardiovascular Care',
    DVT_SUPPRESSION: 'Deep Vein Thrombosis (DVT) Suppression',
    DRAIN_OR_TUBE_CARE: 'Drain or Tube Care',
    PATIENT_RESPONSE: 'Patient Response',
    CARE_NOTES: 'Cardiovascular - Peripheral Vascular Care Notes'
  }
};

export type CardiovascularInterventionsMessageType = Partial<Record<keyof typeof CardiovascularInterventionsLanguageKeys, string>>;

export const CardiovascularInterventionsEnUs: CardiovascularInterventionsMessageType = {};

export const CardiovascularInterventionsEnAu: CardiovascularInterventionsMessageType = {
  [CardiovascularInterventionsLanguageKeys.CARDIOVASCULAR_INTERVENTIONS.DVT_SUPPRESSION]: 'Deep Vein Thrombosis (DVT) Prevention'
};
