import { LaunchParam, LaunchRS } from 'models/api-response';
import { AppConstant } from 'constants/app.constant';
import { apiHelper } from 'helpers';
import { applinkService, cookieService } from 'services';
import { ELSTokenHelper } from 'components/common/els';

export const getRedirectURL = (payload: LaunchParam, returnData?: string): Promise<LaunchRS> => {
  // Get the linkId from cookie linking to Courseware
  const linkId: string | null = localStorage.getItem(AppConstant.COOKIE_LINK_ID);

  cookieService.remove(AppConstant.COOKIE_IS_DEMO);
  if (linkId !== 'undefined' && linkId !== null) {
    return applinkService.getSourceReturnLinkAppLink(linkId, returnData); // for courseware
  }
  // This heads back to Sherpath if there is no linkId - Temp fix
  const launchParam = { ...payload, token: ELSTokenHelper.getToken() };
  return apiHelper.facadeApi.post('/assignment/launch', { data: launchParam });
};
