import { isEqual } from 'lodash';
import { PureComponent } from 'react';
import Helmet from 'react-helmet';
import { ELSCommonConfig } from '@els/els-ui-common-react';
import { NewRelicBrowserService } from 'services/newrewlic.service';

/**
 * This is a workaround while waiting the official release.
 * Helmet uses deep-equal and sometimes crashes on circular objects.
 * See: https://github.com/nfl/react-helmet/issues/373
 * The fix is in 6.x beta. Wait for official release
 */
// eslint-disable-next-line func-names
Helmet.prototype.shouldComponentUpdate = function (nextProps) {
  return !isEqual(this.props, nextProps);
};

export interface SEOData {
  title?: string;
}

const withHTMLHeadSEO =
  (data: SEOData = {}) =>
  (BaseComponent) => {
    class SEOComponent extends PureComponent {
      render() {
        const title = data?.title ? `SimChart - ${data.title}` : 'SimChart';
        const headScripts = [];
        try {
          const { licenseKey, applicationID } = JSON.parse(getNewRelicBrowserConfig());

          if (licenseKey && applicationID) {
            headScripts.push({
              type: 'text/javascript',
              innerHTML: NewRelicBrowserService.registerNewRelicBrowser({ licenseKey, applicationID })
            });
          }
        } catch (e) {
          // eslint-disable-next-line sonarjs/no-duplicate-string
          ELSCommonConfig.getLogger().debug('New Relic Browser is not enabled for the current environment');
        }

        return (
          <>
            <Helmet title={title} script={headScripts}>
              <html lang="en" />
            </Helmet>
            {BaseComponent && <BaseComponent {...this.props} />}
          </>
        );
      }
    }

    return SEOComponent;
  };

export default withHTMLHeadSEO;
