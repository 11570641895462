import { SubmitAnswerPayload } from 'models/api-response';
import { facadeApi } from 'helpers/api.helper';

export const getCaseStudies = (courseId: string) => facadeApi.get(`/content/case-study/content/getFull?courseId=${courseId}`);

export const getCaseStudy = (caseStudyId: string) => facadeApi.get(`/assignment/case-study/content/${caseStudyId}`);

export const getSequences = (assessmentId: number) => facadeApi.get(`/assignment/case-study/management/sequence?assessmentId=${assessmentId}`);

export const getCurrentOrFirstSequenceChunk = (assessmentId: number) => facadeApi.get(`/assignment/case-study/management/sequence-chunk/current?assessmentId=${assessmentId}`);

export const getCurrentStepChunk = (assessmentId: number) => facadeApi.put(`/assignment/case-study/management/step-chunk/current?assessmentId=${assessmentId}`);

export const startPhase = (assessmentId: number) => facadeApi.post(`/assignment/case-study/management/sequence-chunk/start?assessmentId=${assessmentId}`);

export const completePhase = (assessmentId: number) => facadeApi.post(`/assignment/case-study/management/sequence-chunk/complete?assessmentId=${assessmentId}`);

export const submitAnswer = (payload: SubmitAnswerPayload) => facadeApi.post('/assignment/case-study/management/step-chunk/submit-answer', { data: payload });

export const prepareDemoCaseStudy = (payload) => facadeApi.post('/assignment/case-study/prepare-demo', { data: payload });

export const getCaseStudyCatalog = (courseId: string) => facadeApi.get(`/content/case-study/catalog/${courseId}`);
