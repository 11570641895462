export const OrderDiagnosticTestsLanguageKeys = {
  ORDER_DIAGNOSTIC_TESTS: {
    DIAGNOSTIC_TESTS: 'Diagnostic Tests',
    CATEGORY: 'Category',
    ORDER_DESCRIPTION: 'Order Description',
    ORDER_PRIORITY: 'Order Priority',
    TRANSPORTATION: 'Transportation',
    IV_REQUIRED: 'IV Required',
    OXYGEN_REQUIRED: 'Oxygen Required',
    SPECIAL_INSTRUCTIONS: 'Special Instructions',
    EXAM_DATE: 'Exam Date (mm-dd-yyyy)',
    REPORT: 'Report',
    IMPRESSION: 'Impression',
    RECOMMENDATION: 'Recommendation',
    NAME: 'Name',
    SIGNATURE: 'Signature',
    RESULTS_RECEIVED_DATE: 'Results Received Date',
    RESULTS_RECEIVED_TIME: 'Results Received Time'
  }
};

export type OrderDiagnosticTestsMessageType = Partial<Record<keyof typeof OrderDiagnosticTestsLanguageKeys, string>>;

export const OrderDiagnosticTestsEnUs: OrderDiagnosticTestsMessageType = {
  [OrderDiagnosticTestsLanguageKeys.ORDER_DIAGNOSTIC_TESTS.EXAM_DATE]: 'Exam Date (mm-dd-yyyy)'
};

export const OrderDiagnosticTestsEnAu: OrderDiagnosticTestsMessageType = {
  [OrderDiagnosticTestsLanguageKeys.ORDER_DIAGNOSTIC_TESTS.EXAM_DATE]: 'Exam Date (DD/MM/YYYY)'
};
