/* eslint-disable sonarjs/cognitive-complexity */
import { delay } from 'lodash';
import moment from 'moment';
import { v4 } from 'uuid';
import { ChartSharedProps, Duration, InitialStep, Step } from 'models/ui';
import { AppConstant, DATE_FORMAT, Event, Locales, ParamKeys, SECONDS_OF_THE_DAY, SECONDS_OF_THE_HOUR, SECONDS_OF_THE_MINUTE, TimeSpentMsg } from 'constants/app.constant';
import { apiHelper, eventHelper } from 'helpers';
import { cookieService } from 'services';

// Depreciated:
// Will use Time Spent API for calculations on assignments created AFTER Time Spent END calls were implemented
// Will use getDuration to get total time on assignments created BEFORE Time Spent END calls were implemented
export const getDuration = (durationTime: number, useHourUnit = false): Duration => {
  if (durationTime < SECONDS_OF_THE_MINUTE) {
    return {
      dateString: `${durationTime}s`,
      detail: [
        {
          value: durationTime,
          unit: 's'
        }
      ]
    };
  }
  if (useHourUnit && durationTime >= SECONDS_OF_THE_HOUR) {
    const hour = Math.floor(durationTime / SECONDS_OF_THE_HOUR);
    const min = Math.floor((durationTime % SECONDS_OF_THE_HOUR) / SECONDS_OF_THE_MINUTE);
    const dateString = min ? `${hour} hour ${min} min` : `${hour} hour`;
    const detail = [
      {
        value: hour,
        unit: 'hour'
      }
    ];

    if (min) {
      detail.push({
        value: min,
        unit: 'min'
      });
    }

    return { dateString, detail };
  }
  if (durationTime >= SECONDS_OF_THE_MINUTE && durationTime < SECONDS_OF_THE_DAY) {
    const min = Math.floor(durationTime / SECONDS_OF_THE_MINUTE);
    return {
      dateString: `${min} min`,
      detail: [
        {
          value: min,
          unit: 'min'
        }
      ]
    };
  }
  if (durationTime >= SECONDS_OF_THE_DAY) {
    const day = Math.floor(durationTime / SECONDS_OF_THE_DAY);
    const min = Math.floor((durationTime % SECONDS_OF_THE_DAY) / SECONDS_OF_THE_MINUTE);
    const dateString = min ? `${day} day ${min} min` : `${day} day`;
    const detail = [
      {
        value: day,
        unit: 'day'
      }
    ];

    if (min) {
      detail.push({
        value: min,
        unit: 'min'
      });
    }

    return { dateString, detail };
  }
  return null;
};

export const timeSpentFormatter = (time: number): string => {
  const minutes = time % 60;
  const hours = Math.floor(time / 60);
  const formattedTime = `${hours} hrs ${minutes} min`;

  if (hours === 0 && minutes === 0) {
    return TimeSpentMsg.LESS_THAN_ONE_MINUTE;
  }
  return formattedTime;
};

export const toSinglePlural = (numberValue: number, singleText: string, pluralText: string) => (numberValue === 1 ? singleText : pluralText);

/**
 * @deprecated use dateToAge in datetime.helper.ts
 * age > 2 years -> Age: X years
 * age > 1 year and <= 2 years -> Age: X months
 * age > 1 month and <= 1 year -> Age: X months Y days
 * age <= 1 month -> Age: X days
 * @param dateString MM-DD-YYYY string
 * @param dateToCompare date to compare, moment object, default is current time
 */
export const dateToAge = (dateString: string, dateToCompare = moment()) => {
  let days;
  const valid = moment(dateString, DATE_FORMAT, true).isValid();
  if (!valid) {
    return '';
  }
  const date = moment(dateString, DATE_FORMAT, true);
  date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  dateToCompare.set({ hour: 23, minute: 59, second: 59, millisecond: 0 });

  const years = date.diff(dateToCompare, 'years') * -1;
  if (years > 2) {
    return `${years} years`;
  }
  if (years === 2) {
    const newDateToCompare = dateToCompare.clone().subtract(years, 'year');
    const daysDiff = date.diff(newDateToCompare, 'days') * -1;
    if (daysDiff > 0) {
      return `${years} years`;
    }
  }

  const months = date.diff(dateToCompare, 'months') * -1;
  if (months > 12) {
    return `${months} months`;
  }
  if (months === 12) {
    const newDateToCompare = dateToCompare.clone().subtract(12, 'month');
    days = date.diff(newDateToCompare, 'days') * -1;
    if (days > 0) {
      return `${months} months`;
    }
  }

  if (months > 1) {
    const newDateToCompare = dateToCompare.clone().subtract(months, 'month');
    days = date.diff(newDateToCompare, 'days') * -1;
    return days > 0 ? `${months} months ${days} ${toSinglePlural(days, 'day', 'days')}` : `${months} months`;
  }
  if (months === 1) {
    const newDateToCompare = dateToCompare.clone().subtract(months, 'month');
    days = date.diff(newDateToCompare, 'days') * -1;
    if (days > 0) {
      return `${months} month ${days} ${toSinglePlural(days, 'day', 'days')}`;
    }
  }

  days = date.diff(dateToCompare, 'days') * -1;
  return `${days} ${toSinglePlural(days, 'day', 'days')}`;
};

export const getWindowMode = () => {
  const { innerWidth } = window;
  const mode = { mobile: false, tablet: false, desktop: false, wideScreen: false };
  if (innerWidth < 600) {
    return { ...mode, mobile: true };
  }
  if (innerWidth >= 600 && innerWidth < 900) {
    return { ...mode, tablet: true };
  }
  if (innerWidth >= 900 && innerWidth < 1200) {
    return { ...mode, desktop: true };
  }
  if (innerWidth >= 1200) {
    return { ...mode, wide: true };
  }
  return mode;
};

export const isIEBrowser = () => !!document.documentMode;

export const isEdgeBrowser = () => !isIEBrowser() && !!window.StyleMedia;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSafari = () => !!(window as any).safari;

export const doChartScroll = (top = 0, selector = '.chart') => {
  const chartElement: HTMLElement = document.querySelector(selector);

  // some charts do not have chart container having chart css class, like clinical setup
  const elementDoScroll = chartElement || window;
  if (elementDoScroll) {
    if (isIEBrowser() || isEdgeBrowser() || isSafari()) {
      elementDoScroll.scrollTo(0, top);
    } else {
      elementDoScroll.scrollTo({ top, behavior: 'smooth' });
    }
  }
};

export const scrollTo = (arg: string | number, adjustTop = 0, selector = '.chart') => {
  if (typeof arg === 'number') {
    doChartScroll(arg, selector);
  }

  if (typeof arg === 'string') {
    const ele: HTMLElement = document.querySelector(arg);
    if (ele) {
      doChartScroll(ele.offsetTop + adjustTop, selector);
    }
  }
};

export const scrollTop = () => delay(() => scrollTo(0), 0);

export const newLine2Br = (message) => (message ? message.replace(/(\r\n|\n\r|\r|\n)/g, '<br />') : '');

export const saveToken = (token) => {
  const unQuotedToken = token.replace(/"/g, '');
  localStorage.setItem(AppConstant.TOKEN_KEY, `"${unQuotedToken}"`);
};

export const isLocalhost = (): boolean => ['localhost', '127.0.0.1', 'local.apps.els-ols.com'].includes(window.location.hostname);

export const createSteps = (initialStepData: InitialStep[], currentRoute: string, hideActionButtonsOnFirstStep = true): Step[] =>
  initialStepData.map((currentStep, index) => ({
    ...currentStep,
    id: `step-${index}`,
    heading: `Step ${index + 1}: ${currentStep.title}`,
    isActive: currentRoute === currentStep.route,
    showActionButtons: hideActionButtonsOnFirstStep ? index !== 0 : true
  }));

export const getStepInfo = (
  steps: Step[]
): {
  prevStep: Step;
  activeStep: Step;
  nextStep: Step;
  isLastStep: boolean;
} => {
  const activeStepIndex = steps.findIndex((step) => step.isActive);

  return {
    prevStep: steps[activeStepIndex - 1],
    activeStep: steps[activeStepIndex],
    nextStep: steps[activeStepIndex + 1],
    isLastStep: activeStepIndex === steps.length - 1
  };
};

export const copyToClipboard = (content) => navigator.clipboard.writeText(content);

/**
 * Round number up.
 *
 * Ex:
 * 0.1 -> up to 0.5
 * 0.6 -> up to 1
 * @param {number} number
 */
export const roundUp = (number: number) => Math.ceil(number * 2) / 2;

interface LoaderOption {
  tracker?: string;
  errorMessage?: string;
}

// show loader via PageLoader component and hide loader after the promise completed
export const useLoader = (promise: Promise<any>, options?: LoaderOption): Promise<any> => {
  const actionName = options?.tracker || `action-${v4()}`;
  eventHelper.eventEmitter.emit(Event.BusyAction.ADD, actionName);
  let p = promise;
  if (options?.errorMessage) {
    p = promise.catch((err) => apiHelper.showApiError(`${options?.errorMessage} ${err}`));
  }
  return p.finally(() => eventHelper.eventEmitter.emit(Event.BusyAction.REMOVE, actionName));
};

export const isDemoMode = () => cookieService.get(AppConstant.COOKIE_IS_DEMO) === 'true';

export const formatNumberToString = (number: number): string => {
  if (number < 10) {
    return `0${number}`;
  }
  return `${number}`;
};

export const formatNumber = (num: number) => {
  // Format a number to 4 decimal places only if there are already decimals
  return Math.round(num * 10000) / 10000;
};

export const getChartSharedProps = (props): ChartSharedProps => {
  return {
    pushUnsavedChangesList: props.pushUnsavedChangesList
  };
};

export const getPlaceholder = (label: string) => `Type ${label}`;

export const getLanguage = (locale: string) => {
  if (Object.values(Locales).includes(locale as Locales)) {
    return locale.substring(0, 2);
  }

  return Locales.EN_US;
};

export const getPaginationConfig = (
  totalItems: number,
  pageSize: number,
  currentPage: number
): {
  realizedPage: number;
  sliceStart: number;
  sliceEnd: number;
} => {
  const totalPages = totalItems ? Math.ceil(totalItems / pageSize) : 1;
  const realizedPage = totalPages < currentPage ? totalPages : currentPage;
  const sliceStart = (realizedPage - 1) * pageSize;
  const sliceEnd = sliceStart + pageSize;

  return {
    realizedPage,
    sliceStart,
    sliceEnd
  };
};

/**
 * Get params by key from search params
 * @param searchParams
 * @param keys
 */
export const getParamByKeys = <R extends object>(searchParams: string, keys: ParamKeys[]): R => {
  const searchObject = new URLSearchParams(searchParams);

  return keys.reduce((acc: R, key: ParamKeys): R => {
    if (searchObject.has(key)) {
      return {
        ...acc,
        [key]: searchObject.get(key)
      };
    }
    return acc;
  }, {} as R);
};
