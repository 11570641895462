export const SkinAssessmentLanguageKeys = {
  SKIN_ASSESSMENT: {
    SKIN_ASSESSMENT: 'Skin Assessment',
    BRADEN_SCALE_COMPLETED: 'Braden Scale Completed',
    PATIENT_HAS_EXISTING_WOUNDS: 'Patient has existing wounds',
    LOCATION_OR_LOCATIONS: 'Location(s)',
    WOUND_CARE_PLAN_COMPLETED: 'Wound care plan completed',
    PRESSURE_INJURY_PREVENTION: 'Pressure Injury Prevention',
    EXISTING_WOUNDS: 'Existing Wounds',
    WOUND_CARE_PLAN: 'Wound care plan'
  }
};

export type SkinAssessmentMessageType = Partial<Record<keyof typeof SkinAssessmentLanguageKeys, string>>;

export const SkinAssessmentEnUs: SkinAssessmentMessageType = {};

export const SkinAssessmentEnAu: SkinAssessmentMessageType = {};
