import { ELSFormFieldConfig } from '@els/els-component-form-field-react';
import { ELSCommonConfig, ELSCommonUIConstants } from '@els/els-ui-common-react';
import { AppConfig, DateFormatConfig } from 'models/els';
import { DATE_FORMAT } from 'constants/app.constant';
import { ServerConstants } from './server.constant';

// version must be increased if redux store schema changed
export const appStateVersion = '19';

// token's expired time is 1 hour, so set 0.75 to refresh at 45 min
// if refresh failed, it will has enough time to retry
export const tokenRefreshIntervalFactor = 0.75;

export const tokenRefreshIntervalInMinutes = 45;

export const setDateFormatConfig = (dateFormat: DateFormatConfig) => {
  ELSFormFieldConfig.setI18nConfig({
    dateFormat
  });
};

export const setDefaultAppConfig = (
  config: AppConfig = {
    i18n: {
      dateFormat: {
        primary: DATE_FORMAT
      }
    }
  }
) => {
  // support EKS, the API_GATEWAY_URL is passed as env variables so we don't override that setting
  if (!ELSCommonConfig.buildUrl) {
    ELSCommonConfig.setBuildUrl(sessionStorage.getItem('localGateway') || ServerConstants[ServerConstants.DataSource].gatewayBaseURL);
  }

  ELSCommonConfig.setLoginPath('/login-service/login');
  ELSCommonConfig.setUserServicePath('/apiv1/eolsUser/list');
  ELSCommonConfig.setTokenServicePath('/token-service');
  ELSCommonConfig.setStorageDriver(ELSCommonUIConstants.security.Locker.DefaultDriver);
  setDateFormatConfig(config.i18n.dateFormat);
};

export const getCookieDomain = () => ServerConstants[ServerConstants.DataSource].cookieDomain;
