export const MobilityPhysicalTherapyLanguageKeys = {
  MOBILITY_PHYSICAL_THERAPY: {
    MOBILITY_PHYSICAL_THERAPY_SCREEN: 'Mobility and Physical Therapy Screen',
    PHYSICAL_THERAPY_PROBLEMS: 'New Physical Therapy Problems',
    ORDERS_PHYSICAL_THERAPY: 'Does patient have orders for physical therapy ?',
    PHYSICAL_THERAPY_PROBLEMS_CONDITION:
      'Does patient have a condition/orders that restricts participation in PT? If Yes, patient must be medically cleared for physical therapy screen',
    PATIENT_USUALLY_REQUIRES_ASSISTANCE_WITH: 'Patient usually requires assistance with',
    FALLS_RISK: 'Falls Risk',
    PATIENT_MARK_ALL_THAT_APPLY: 'Patient: (Mark all that apply)',
    ADDITIONAL_COMMENTS: 'Additional Comments',
    ADDITIONAL_INFORMATION: 'Additional information',
    REASON_FOR_REFERRAL: 'Reason for referral',
    PHYSIOTHERAPIST_REASON_FOR_REFERRAL: 'Physiotherapist - reason for referral',
    OCCUPATIONAL_THERAPIST_REASON_FOR_REFERRAL: 'Occupational Therapist - reason for referral'
  }
};

export type MobilityPhysicalTherapyMessageType = Partial<Record<keyof typeof MobilityPhysicalTherapyLanguageKeys, string>>;

export const MobilityPhysicalTherapyEnUs: MobilityPhysicalTherapyMessageType = {};

export const MobilityPhysicalTherapyEnAu: MobilityPhysicalTherapyMessageType = {};
