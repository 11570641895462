import { Component } from 'react';
import { connect } from 'react-redux';
import { ELSPendoService } from '@els/els-ui-common-react';
import { User } from 'models/ui';
import { analyticsService, navigationService } from 'services';
import { appSelectors } from 'redux/ducks/app';

interface AuthorizeProps {
  loggedInUser: User;
  userRole: string;
}

const withAuthorize =
  (allowRoles = []) =>
  (WrappedComponent) => {
    class Authorize extends Component<AuthorizeProps> {
      constructor(props) {
        super(props);
        const allowAccess = this.props.loggedInUser && allowRoles.includes(this.props.userRole);
        const pendoApiKey = '47092e59-aeb7-489b-6244-628ccfd3a621';

        /* istanbul ignore else */
        if (!allowAccess) {
          navigationService.navigateToHome();
        } else {
          const { userRole } = this.props;
          analyticsService.setUserPageData({ ...this.props.loggedInUser, userRole });

          // If user switch pages fast, pendo doesn't enough time to initilize,
          // isReady will not be defined which cause issue.
          // So that check to make sure isReady exist before calling. The disadvantage
          // is it can initialize twice but make sure pendo installed correctly
          // and reducing the risk to break the app.
          const pendoInstalled = window.pendo?.isReady ? window.pendo.isReady() : false;
          if (!pendoInstalled) {
            ELSPendoService.start(pendoApiKey);
          }
        }
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    const mapStateToProps = (state) => ({
      loggedInUser: appSelectors.getLoggedInUser(state),
      userRole: appSelectors.getUserRole(state)
    });

    return connect(mapStateToProps)(Authorize);
  };

export default withAuthorize;
