import { AdmissionDataEnAu, AdmissionDataEnUs, AdmissionDataLanguageKeys } from './admissionData';
import { AssignmentSelectionEnAu, AssignmentSelectionEnUs, AssignmentSelectionLanguageKeys } from './assignmentSelection';
import { BloodGlucoseEnAu, BloodGlucoseEnUs, BloodGlucoseLanguageKeys } from './bloodGlucose';
import { CardiovascularAssessmentEnAu, CardiovascularAssessmentEnUs, CardiovascularAssessmentLanguageKeys } from './cardiovascularAssessment';
import { CardiovascularInterventionsEnAu, CardiovascularInterventionsEnUs, CardiovascularInterventionsLanguageKeys } from './cardiovascularInterventions';
import { CaseStudyEnAu, CaseStudyEnUs, CaseStudyLanguageKeys } from './caseStudy';
import { ClinicalSetupEnAu, ClinicalSetupEnUs, ClinicalSetupLanguageKeys } from './clinicalSetup';
import { CommonEnAu, CommonEnUs, CommonLanguageKeys } from './common';
import { DeliveryRoomSummaryEnAu, DeliveryRoomSummaryEnUs, DeliveryRoomSummaryLanguageKeys } from './deliveryRoomSummary';
import { GastrointestinalAssessmentEnAu, GastrointestinalAssessmentEnUs, GastrointestinalAssessmentLanguageKeys } from './gastrointestinalAssessment';
import { GeneralOrderEnAu, GeneralOrderEnUs, GeneralOrderLanguageKeys } from './generalOrders';
import { GenitourinaryAssessmentEnAu, GenitourinaryAssessmentEnUs, GenitourinaryAssessmentLanguageKeys } from './genitourinaryAssessment';
import { HeightWeightEnAu, HeightWeightEnUs, HeightWeightLanguageKeys } from './heightWeight';
import { IntakeOutputEnAu, IntakeOutputEnUs, IntakeOutputLanguageKeys } from './intakeOutput';
import { IntegumentaryAssessmentEnAu, IntegumentaryAssessmentEnUs, IntegumentaryAssessmentLanguageKeys } from './integumentaryAssessment';
import { LaboratoryEnAu, LaboratoryEnUs, LaboratoryLanguageKeys } from './laboratory';
import { MobilityPhysicalTherapyEnAu, MobilityPhysicalTherapyEnUs, MobilityPhysicalTherapyLanguageKeys } from './mobilityPhysicalTherapy';
import { MusculoskeletalAssessmentEnAu, MusculoskeletalAssessmentEnUs, MusculoskeletalAssessmentLanguageKeys } from './musculoskeletalAssessment';
import { NeurologicalAssessmentEnAu, NeurologicalAssessmentEnUs, NeurologicalAssessmentLanguageKeys } from './neurologicalAssessment';
import { NeurovascularAssessmentEnAu, NeurovascularAssessmentEnUs, NeurovascularAssessmentLanguageKeys } from './neurovascularAssessment';
import { NutritionOrderEntryEnAu, NutritionOrderEntryEnUs, NutritionOrderEntryLanguageKeys } from './nutritionOrderEntry';
import { NutritionScreenEnAu, NutritionScreenEnUs, NutritionScreenLanguageKeys } from './nutritionScreen';
import { OrderDiagnosticTestsEnAu, OrderDiagnosticTestsEnUs, OrderDiagnosticTestsLanguageKeys } from './orderDiagnosticTests';
import { PainAssessmentEnAu, PainAssessmentEnUs, PainAssessmentLanguageKeys } from './painAssessment';
import { PatientChartEnAu, PatientChartEnUs, PatientChartLanguageKeys } from './patientCharting';
import { PharmacyEnAu, PharmacyEnUs, PharmacyLanguageKeys } from './pharmacy';
import { RespiratoryAssessmentEnAu, RespiratoryAssessmentEnUs, RespiratoryAssessmentLanguageKeys } from './respiratoryAssessment';
import { RestraintsEnAu, RestraintsEnUs, RestraintsLanguageKeys } from './restraints';
import { SafetyEnAu, SafetyEnUs, SafetyLanguageKeys } from './safety';
import { SBARReportEnAu, SBARReportEnUs, SBARReportLanguageKeys } from './sbarReport';
import { SkinAssessmentEnAu, SkinAssessmentEnUs, SkinAssessmentLanguageKeys } from './skinAssessment';
import { SummaryEnAu, SummaryEnUs, SummaryLanguageKeys } from './summary';
import { TherapiesOrderEntryEnAu, TherapiesOrderEntryEnUs, TherapiesOrderEntryLanguageKeys } from './therapiesOrderEntry';
import { VitalSignsEnAu, VitalSignsEnUs, VitalSignsLanguageKeys } from './vitalSigns';
import { Locales } from '../constants/app.constant';

export const LanguageKeys = {
  ...AssignmentSelectionLanguageKeys,
  ...AdmissionDataLanguageKeys,
  ...BloodGlucoseLanguageKeys,
  ...CardiovascularAssessmentLanguageKeys,
  ...CardiovascularInterventionsLanguageKeys,
  ...ClinicalSetupLanguageKeys,
  ...CommonLanguageKeys,
  ...DeliveryRoomSummaryLanguageKeys,
  ...GastrointestinalAssessmentLanguageKeys,
  ...GeneralOrderLanguageKeys,
  ...GenitourinaryAssessmentLanguageKeys,
  ...HeightWeightLanguageKeys,
  ...IntakeOutputLanguageKeys,
  ...IntegumentaryAssessmentLanguageKeys,
  ...LaboratoryLanguageKeys,
  ...MobilityPhysicalTherapyLanguageKeys,
  ...MusculoskeletalAssessmentLanguageKeys,
  ...NeurologicalAssessmentLanguageKeys,
  ...NutritionOrderEntryLanguageKeys,
  ...NutritionScreenLanguageKeys,
  ...NeurovascularAssessmentLanguageKeys,
  ...OrderDiagnosticTestsLanguageKeys,
  ...PainAssessmentLanguageKeys,
  ...PharmacyLanguageKeys,
  ...RespiratoryAssessmentLanguageKeys,
  ...RestraintsLanguageKeys,
  ...SafetyLanguageKeys,
  ...SBARReportLanguageKeys,
  ...SkinAssessmentLanguageKeys,
  ...SummaryLanguageKeys,
  ...TherapiesOrderEntryLanguageKeys,
  ...VitalSignsLanguageKeys,
  ...PatientChartLanguageKeys,
  ...CaseStudyLanguageKeys
};

export const Messages: Record<Locales, Partial<Record<keyof typeof LanguageKeys, string>>> = {
  [Locales.EN_US]: {
    ...AssignmentSelectionEnUs,
    ...AdmissionDataEnUs,
    ...BloodGlucoseEnUs,
    ...CardiovascularAssessmentEnUs,
    ...CardiovascularInterventionsEnUs,
    ...ClinicalSetupEnUs,
    ...CommonEnUs,
    ...DeliveryRoomSummaryEnUs,
    ...GastrointestinalAssessmentEnUs,
    ...GeneralOrderEnUs,
    ...GenitourinaryAssessmentEnUs,
    ...HeightWeightEnUs,
    ...IntakeOutputEnUs,
    ...IntegumentaryAssessmentEnUs,
    ...LaboratoryEnUs,
    ...MobilityPhysicalTherapyEnUs,
    ...MusculoskeletalAssessmentEnUs,
    ...NeurologicalAssessmentEnUs,
    ...NutritionOrderEntryEnUs,
    ...NutritionScreenEnUs,
    ...NeurovascularAssessmentEnUs,
    ...OrderDiagnosticTestsEnUs,
    ...PainAssessmentEnUs,
    ...PharmacyEnUs,
    ...RespiratoryAssessmentEnUs,
    ...RestraintsEnUs,
    ...SafetyEnUs,
    ...SBARReportEnUs,
    ...SkinAssessmentEnUs,
    ...SummaryEnUs,
    ...TherapiesOrderEntryEnUs,
    ...VitalSignsEnUs,
    ...PatientChartEnUs,
    ...CaseStudyEnUs
  },
  [Locales.EN_AU]: {
    ...AssignmentSelectionEnAu,
    ...AdmissionDataEnAu,
    ...BloodGlucoseEnAu,
    ...CardiovascularAssessmentEnAu,
    ...CardiovascularInterventionsEnAu,
    ...ClinicalSetupEnAu,
    ...CommonEnAu,
    ...DeliveryRoomSummaryEnAu,
    ...GastrointestinalAssessmentEnAu,
    ...GeneralOrderEnAu,
    ...GenitourinaryAssessmentEnAu,
    ...HeightWeightEnAu,
    ...IntakeOutputEnAu,
    ...IntegumentaryAssessmentEnAu,
    ...LaboratoryEnAu,
    ...MobilityPhysicalTherapyEnAu,
    ...MusculoskeletalAssessmentEnAu,
    ...NeurologicalAssessmentEnAu,
    ...NutritionOrderEntryEnAu,
    ...NutritionScreenEnAu,
    ...NeurovascularAssessmentEnAu,
    ...OrderDiagnosticTestsEnAu,
    ...PainAssessmentEnAu,
    ...PharmacyEnAu,
    ...RespiratoryAssessmentEnAu,
    ...RestraintsEnAu,
    ...SafetyEnAu,
    ...SBARReportEnAu,
    ...SkinAssessmentEnAu,
    ...SummaryEnAu,
    ...TherapiesOrderEntryEnAu,
    ...VitalSignsEnAu,
    ...PatientChartEnAu,
    ...CaseStudyEnAu
  }
};
