import { CourseRS, CourseStudentRS } from 'models/api-response';
import { apiHelper } from 'helpers';

export const getCourse = (courseId: string): Promise<CourseRS> => apiHelper.eolsApi.get(`/api/courseManagement/courseSectionId/${courseId}`);

export const getStudents = (courseId: string): Promise<CourseStudentRS> => apiHelper.facadeApi.get(`/assignment/course/${courseId}/students`);

export const getCourseSectionById = (courseId: string) => apiHelper.facadeApi.get(`/assignment/course/courseSection/${courseId}`);

export const getCourseSectionIsbn = (courseId: string) => apiHelper.facadeApi.get(`/assignment/course/courseSectionIsbn/${courseId}`);
