import { omit, sortBy } from 'lodash';
import { NavigationItem } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';

export const transformNavigation = (item: NavigationItem) => omit(item, ['createdAt', 'updatedAt', 'idPath']);

export const transformNavigations = (items: NavigationItem[]) => {
  const chartsOnTop = [
    NAV_ID.SAVED_SYSTEM_ASSESSMENT,
    NAV_ID.SAVED_SPECIAL_CHART,
    NAV_ID.SAVED_WOUNDS,
    NAV_ID.SAVED_BASIC_NURSING_CARE,
    NAV_ID.SAVED_SYSTEM_NURSING_INTERVENTIONS,
    NAV_ID.SAVED_INTRAVENOUS,
    NAV_ID.SAVED_DRAINS_TUBES,
    NAV_ID.SAVED_OSTOMIES,
    NAV_ID.SAVED_INJECTIONS,
    NAV_ID.SAVED_PATIENT_TEACHING
  ];
  return sortBy(items, 'displayOrder').map((item) => {
    const newItem = transformNavigation(item);
    if (item.children?.length > 0) {
      const newChildren = item.children.map((child) => {
        const parentFullPath = item.fullPath || item.path;
        const fullPath = `${parentFullPath}${child.path}`;
        const parentText = item.text;
        const parentId = item.id;
        return { ...child, path: fullPath, parentId, parentText };
      });
      newItem.children = transformNavigations(newChildren);
    }
    return { ...newItem, isTopChart: chartsOnTop.includes(newItem.id) };
  });
};
