import { createSelector } from 'reselect';
import Immutable from 'seamless-immutable';
import { AppConstant } from 'constants/app.constant';
import { createAction, handleActions } from 'redux-actions';

const initialState = Immutable({
  assignment: null,
  assignmentType: '',
  assignmentDemoSource: '',
  assignmentDemoUrl: '',
  authorId: null,
  chartingTime: '',
  fullAssignment: null,
  offset: null,
  phaseIndex: null,
  selectedOrderDescription: ''
});

const actions = {
  restoreStudentState: createAction('STUDENT/RESTORE_STATE'),
  setAssignment: createAction('STUDENT/SET_ASSIGNMENT'),
  setAssignmentType: createAction('STUDENT/SET_ASSIGNMENT_TYPE'),
  setAssignmentDemoSource: createAction('STUDENT/SET_ASSIGNMENT_DEMO_SOURCE'),
  setAssignmentDemoUrl: createAction('STUDENT/SET_ASSIGNMENT_DEMO_URL'),
  setAuthorId: createAction('STUDENT/SET_AUTHOR_ID'),
  setChartingTime: createAction('STUDENT/SET_CHARTING_TIME'),
  setFullAssignment: createAction('STUDENT/SET_FULL_ASSIGNMENT'),
  setOffset: createAction('STUDENT/SET_OFFSET'),
  setPhaseIndex: createAction('STUDENT/SET_PHASE_INDEX'),
  setSelectedOrderDescription: createAction('STUDENT/SET_SELECTED_ORDER_DESCRIPTION')
};

const reducer = handleActions(
  {
    [actions.restoreStudentState]: (state, { payload }) => state.merge(payload),
    [actions.setAssignment]: (state, { payload }) => state.set('assignment', payload),
    [actions.setAssignmentType]: (state, { payload }) => state.set('assignmentType', payload),
    [actions.setAssignmentDemoSource]: (state, { payload }) => state.set('assignmentDemoSource', payload),
    [actions.setAssignmentDemoUrl]: (state, { payload }) => state.set('assignmentDemoUrl', payload),
    [actions.setAuthorId]: (state, { payload }) => state.set('authorId', payload),
    [actions.setChartingTime]: (state, { payload }) => state.set('chartingTime', payload),
    [actions.setFullAssignment]: (state, { payload }) => state.set('fullAssignment', payload),
    [actions.setOffset]: (state, { payload }) => state.set('offset', payload),
    [actions.setPhaseIndex]: (state, { payload }) => state.set('phaseIndex', payload),
    [actions.setSelectedOrderDescription]: (state, { payload }) => state.set('selectedOrderDescription', payload)
  },
  initialState
);

const getStudentState = (state) => state[AppConstant.reduxResources.STUDENT_STATE];

const selectors = {
  getAssignment: createSelector(getStudentState, (state) => state.assignment),
  getAssignmentType: createSelector(getStudentState, (state) => state.assignmentType),
  getAssignmentDemoSource: createSelector(getStudentState, (state) => state.assignmentDemoSource),
  getAssignmentDemoUrl: createSelector(getStudentState, (state) => state.assignmentDemoUrl),
  getAuthorId: createSelector(getStudentState, (state) => state.authorId),
  getChartingTime: createSelector(getStudentState, (state) => state.chartingTime),
  getFullAssignment: createSelector(getStudentState, (state) => state.fullAssignment),
  getOffset: createSelector(getStudentState, (state) => state.offset),
  getPhaseIndex: createSelector(getStudentState, (state) => state.phaseIndex),
  getSelectedOrderDescription: createSelector(getStudentState, (state) => state.selectedOrderDescription)
};

export { initialState, actions as studentActions, reducer, selectors as studentSelectors };
