export const CardiovascularAssessmentLanguageKeys = {
  EDEMA: 'Edema',
  PERFUSION_EDEMA: 'Perfusion / Edema',
  ANKLE_BRACHIAL_INDEX_TEST: 'Ankle-brachial Index Test',
  CAPILLARY_REFILL: 'Capillary Refill',
  CARDIAC_ASSESSMENT: 'Cardiac Assessment',
  CARDIAC_MONITOR: 'Cardiac Monitor',
  CARDIAC_RHYTHM: 'Cardiac rhythm',
  CARDIOVASCULAR_ASSESSMENT: 'Cardiovascular Assessment',
  CAROTID_PERIPHERAL_PULSES: 'Carotid / Peripheral Pulses',
  INTERPRETATION: 'Interpretation',
  LEFT_ANKLE_SYSTOLIC_PRESSURE_DORSALIS_PEDIS: 'Left ankle systolic pressure dorsalis pedis',
  LEFT_ANKLE_SYSTOLIC_PRESSURE_POSTERIOR_TIBIAL: 'Left ankle systolic pressure posterior tibial',
  LEFT_ARM_SYSTOLIC_BLOOD_PRESSURE: 'Left arm systolic blood pressure',
  RIGHT_ANKLE_SYSTOLIC_PRESSURE_DORSALIS_PEDIS: 'Right ankle systolic pressure dorsalis pedis',
  RIGHT_ANKLE_SYSTOLIC_PRESSURE_POSTERIOR_TIBIAL: 'Right ankle systolic pressure posterior tibial',
  RIGHT_ARM_SYSTOLIC_BLOOD_PRESSURE: 'Right arm systolic blood pressure',
  PERIPHERAL_VASCULAR_GENERAL: 'Peripheral vascular, general',
  PULSES: 'Pulses',
  TELEMETRY: 'Telemetry',
  TISSUE_PERFUSION: 'Tissue Perfusion',
  APICAL: 'Apical',
  MURMUR_NOTED: 'Murmur noted',
  MURMUR_DESCRIPTION: 'Murmur Description',
  LOCATION: 'Location',
  CALCULATE_ANKLE_BRACHIAL_INDEX_TEST: 'Calculate Ankle-brachial Index Test',
  INDEX_SCORE: 'Index score',
  INDEX_TEST_DIRECTIONS: 'Divide the highest systolic pressure in each leg by the highest systolic brachial pressure',
  INFO_TEXT_FILL_OUT_DATA: 'Fill out data on each Carotid/Peripheral Pulse location and use the "Add Location" button before saving the entire chart.',
  OTHER: 'Other',
  LEFT_HAND: 'Left hand',
  RIGHT_HAND: 'Right hand',
  LEFT_FOOT: 'Left foot',
  RIGHT_FOOT: 'Right foot',
  MUCOUS_MEMBRANES_COLOR: 'Mucous membranes color',
  MUCOUS_MEMBRANES_MOISTURE: 'Mucous membranes moisture',
  UPPER_EXTREMITIES: 'Upper extremities',
  LOWER_EXTREMITIES: 'Lower extremities',
  CONTINUOUS_TELEMETRY: 'Continuous telemetry',
  UPPER_LIMIT: 'Upper limit',
  LOWER_LIMIT: 'Lower limit',
  ALARMS_TESTED: 'Alarms tested'
};

export type CardiovascularAssessmentMessageType = Partial<Record<keyof typeof CardiovascularAssessmentLanguageKeys, string>>;

export const CardiovascularAssessmentEnUs: CardiovascularAssessmentMessageType = {};

export const CardiovascularAssessmentEnAu: CardiovascularAssessmentMessageType = {
  [CardiovascularAssessmentLanguageKeys.EDEMA]: 'Oedema',
  [CardiovascularAssessmentLanguageKeys.PERFUSION_EDEMA]: 'Perfusion / Oedema'
};
