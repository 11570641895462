import { ELSCommonConfig } from '@els/els-ui-common-react';

const stageGatewayURL = 'https://eolsapi-stage.apps.els-ols.com';
const qaContentBaseURL = 'https://content-qa.els-ols.com';
const defaultCookieDomain = '.apps.els-ols.com';

// TokenServiceEnabled toggle for enabling token service integration
// CORSAllowCredentials toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
export const ServerConstants = {
  DataSource: ELSCommonConfig.appProfile,
  dev: {
    gatewayBaseURL: 'https://eolsapi-dev.apps.els-ols.com',
    contentBaseURL: 'https://content-dev.els-ols.com/dev',
    cookieDomain: defaultCookieDomain,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  },
  qa: {
    gatewayBaseURL: 'https://qa-apigateway-eols.elsevier.com',
    contentBaseURL: qaContentBaseURL,
    cookieDomain: defaultCookieDomain,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  },
  demo: {
    gatewayBaseURL: 'https://eolsapi-demo.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookieDomain: defaultCookieDomain,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  },
  test1: {
    gatewayBaseURL: 'https://eolsapi-test1.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookieDomain: defaultCookieDomain,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  },
  test2: {
    gatewayBaseURL: 'https://eolsapi-test2.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookieDomain: defaultCookieDomain,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  },
  test3: {
    gatewayBaseURL: 'https://eolsapi-test3.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookieDomain: defaultCookieDomain,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  },
  test4: {
    gatewayBaseURL: 'https://eolsapi-test4.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookieDomain: defaultCookieDomain,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  },
  test5: {
    gatewayBaseURL: 'https://eolsapi-test5.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookieDomain: defaultCookieDomain,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  },
  test6: {
    gatewayBaseURL: 'https://eolsapi-test6.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookieDomain: defaultCookieDomain,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  },
  test7: {
    gatewayBaseURL: 'https://eolsapi-test7.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookieDomain: defaultCookieDomain,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  },
  'ck-cert': {
    gatewayBaseURL: 'https://eolsapi-test7.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookieDomain: defaultCookieDomain,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  },
  'cd-staging': {
    gatewayBaseURL: stageGatewayURL,
    contentBaseURL: qaContentBaseURL,
    cookieDomain: defaultCookieDomain,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  },
  staging: {
    gatewayBaseURL: stageGatewayURL,
    contentBaseURL: qaContentBaseURL,
    cookieDomain: defaultCookieDomain,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  },
  stage: {
    gatewayBaseURL: stageGatewayURL,
    contentBaseURL: qaContentBaseURL,
    cookieDomain: defaultCookieDomain,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  },
  stage1: {
    gatewayBaseURL: 'https://eolsapi-stage1.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookieDomain: defaultCookieDomain,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  },
  stage2: {
    gatewayBaseURL: 'https://eolsapi-stage2.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookieDomain: defaultCookieDomain,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  },
  validate: {
    gatewayBaseURL: 'https://eolsapi-validate.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookieDomain: defaultCookieDomain,
    eolsEnv: 'validate',
    config: {
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  },
  vendor: {
    gatewayBaseURL: 'https://eolsapi-vendor.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookieDomain: defaultCookieDomain,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  },
  perf: {
    gatewayBaseURL: 'https://eolsapi-perf.apps.elsevier.education',
    contentBaseURL: 'https://content-perf.apps.elsevier.education',
    cookieDomain: defaultCookieDomain,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  },
  betaprod: {
    gatewayBaseURL: 'https://eolsapi-betaprod.apps.elsevier.education',
    contentBaseURL: 'https://content-betaprod.apps.elsevier.education',
    cookieDomain: defaultCookieDomain,
    eolsEnv: '',
    config: {
      PushEventsEnabled: true,
      PushEventsUsesGateway: true,
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  },
  prod: {
    gatewayBaseURL: 'https://eolsapi.elsevier.com',
    contentBaseURL: 'https://eolscontent.elsevier.com',
    cookieDomain: '.elsevier.com',
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  }
};
