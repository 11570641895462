export const GeneralOrderLanguageKeys = {
  GENERAL_ORDER: {
    ORDER_START: 'Order Start',
    ORDER_DESCRIPTION: 'Order Description',
    ORDER_DETAILS: 'Order Details',
    ORDER_TIME: 'Time'
  }
};

export type GeneralOrderMessageType = Partial<Record<keyof typeof GeneralOrderLanguageKeys, string>>;

export const GeneralOrderEnUs: GeneralOrderMessageType = {};

export const GeneralOrderEnAu: GeneralOrderMessageType = {};
