import { createSelector } from 'reselect';
import Immutable from 'seamless-immutable';
import { AppConstant } from 'constants/app.constant';
import { createAction, handleActions } from 'redux-actions';

const initialState = Immutable({
  newAssignment: {
    assignmentType: '',
    title: '',
    availableDate: '',
    dueDate: '',
    assignmentGradeType: 'NOT_GRADED',
    assignmentTopics: [],
    objectives: '',
    instructions: '',
    students: [],
    isAllStudents: true
  },
  savedAssignment: {
    assignmentId: '',
    assignmentType: ''
  },
  caseStudyDemo: null,
  dueDateError: '',
  emptyEhrDemo: null,
  emptyEhrDemoInstructions: '',
  emptyEhrDemoObjectives: '',
  isEdit: false,
  isBookOrganized: false,
  prevCreateAssignmentStep: ''
});

const actions = {
  restoreInstructorState: createAction('INSTRUCTOR/RESTORE_STATE'),
  setNewAssignment: createAction('INSTRUCTOR/SET_NEW_ASSIGNMENT'),
  resetNewAssignment: createAction('INSTRUCTOR/RESET_NEW_ASSIGNMENT'),
  setSavedAssignment: createAction('INSTRUCTOR/SET_SAVED_ASSIGNMENT'),
  setCaseStudyDemo: createAction('INSTRUCTOR/SET_CASE_STUDY_DEMO'),
  setDueDateError: createAction('INSTRUCTOR/SET_DUE_DATE_ERROR'),
  setIsEdit: createAction('INSTRUCTOR/SET_IS_EDIT'),
  setIsBookOrganized: createAction('INSTRUCTOR/SET_IS_BOOK_ORGANIZED'),
  setEmptyEhrDemo: createAction('INSTRUCTOR/SET_EMPTY_EHR_DEMO'),
  setEmptyEhrDemoInstructions: createAction('INSTRUCTOR/SET_EMPTY_EHR_DEMO_INSTRUCTIONS'),
  setEmptyEhrDemoObjectives: createAction('INSTRUCTOR/SET_EMPTY_EHR_DEMO_OBJECTIVES'),
  setPrevCreateAssignmentStep: createAction('INSTRUCTOR/SET_PREV_CREATE_ASSIGNMENT_STEP')
};

const reducer = handleActions(
  {
    [actions.restoreInstructorState]: (state, { payload }) => state.merge(payload),
    [actions.setNewAssignment]: (state, { payload }) => state.merge({ newAssignment: { ...payload } }, { deep: true }),
    [actions.resetNewAssignment]: (state) => state.set('newAssignment', initialState.newAssignment),
    [actions.setSavedAssignment]: (state, { payload }) => state.merge({ savedAssignment: { ...payload } }, { deep: true }),
    [actions.setCaseStudyDemo]: (state, { payload }) => state.set('caseStudyDemo', payload),
    [actions.setDueDateError]: (state, { payload }) => state.set('dueDateError', payload),
    [actions.setEmptyEhrDemo]: (state, { payload }) => state.set('emptyEhrDemo', payload),
    [actions.setEmptyEhrDemoInstructions]: (state, { payload }) => state.set('emptyEhrDemoInstructions', payload),
    [actions.setEmptyEhrDemoObjectives]: (state, { payload }) => state.set('emptyEhrDemoObjectives', payload),
    [actions.setIsEdit]: (state, { payload }) => state.set('isEdit', payload),
    [actions.setIsBookOrganized]: (state, { payload }) => state.set('isBookOrganized', payload),
    [actions.setPrevCreateAssignmentStep]: (state, { payload }) => state.set('prevCreateAssignmentStep', payload)
  },
  initialState
);

const getInstructorState = (state) => state[AppConstant.reduxResources.INSTRUCTOR_STATE];
const getNewAssignment = createSelector(getInstructorState, (state) => state.newAssignment);
const getSavedAssignment = createSelector(getInstructorState, (state) => state.savedAssignment);
const getAssignmentType = createSelector(getNewAssignment, (newAssignment) => newAssignment.assignmentType);
const getAssignmentTopics = createSelector(getNewAssignment, (newAssignment) => newAssignment.assignmentTopics);
const getCaseStudyDemo = createSelector(getInstructorState, (state) => state.caseStudyDemo);
const getObjectives = createSelector(getNewAssignment, (newAssignment) => newAssignment.objectives);
const getInstructions = createSelector(getNewAssignment, (newAssignment) => newAssignment.instructions);
const getDueDateError = createSelector(getInstructorState, (state) => state.dueDateError);
const getIsEdit = createSelector(getInstructorState, (state) => state.isEdit);
const getIsBookOrganized = createSelector(getInstructorState, (state) => state.isBookOrganized);
const getEmptyEhrDemoInstructions = createSelector(getInstructorState, (state) => state.emptyEhrDemoInstructions);
const getEmptyEhrDemoObjectives = createSelector(getInstructorState, (state) => state.emptyEhrDemoObjectives);
const getPrevCreateAssignmentStep = createSelector(getInstructorState, (state) => state.prevCreateAssignmentStep);

const selectors = {
  getNewAssignment,
  getSavedAssignment,
  getAssignmentType,
  getAssignmentTopics,
  getCaseStudyDemo,
  getObjectives,
  getInstructions,
  getDueDateError,
  getIsEdit,
  getIsBookOrganized,
  getEmptyEhrDemoInstructions,
  getEmptyEhrDemoObjectives,
  getPrevCreateAssignmentStep
};

export { initialState, actions as instructorActions, reducer, selectors as instructorSelectors };
