export const NeurologicalAssessmentLanguageKeys = {
  NEUROLOGICAL_ASSESSMENT: 'Neurological Assessment',
  LEVEL_CONSCIOUSNESS_ORIENTATION: 'Level of Consciousness/Orientation',
  EMOTIONAL_STATE: 'Emotional State',
  HAND_GRIP: 'Hand Grip',
  FOOT_PUMPS: 'Foot Pumps',
  CENTRAL_NERVOUS_SYSTEM_ASSESSMENT: 'Central Nervous System Assessment (CNS)',
  EXTRAPYRAMIDAL_SIDE_EFFECTS: 'Extrapyramidal Side Effects (EPS)',
  AKATHISIA: 'Akathisia',
  ACUTE_DYSTONIA: 'Acute Dystonia',
  PSEUDOPARKINSONISM: 'Pseudoparkinsonism',
  TARDIVE_DYSKINESIA: 'Tardive Dyskinesia',
  NEUROLEPTIC_MALIGNANT_SYNDROME: 'Neuroleptic Malignant Syndrome (NMS)',
  PUPILS: 'Pupils',
  LEFT: 'Left',
  RIGHT: 'Right',
  REACTIVITY: 'Reactivity',
  SIZE: 'Size',
  BILATERAL_ASSESSMENT: 'Bilateral assessment',
  LEFT_HAND_GRIP_STRENGTH: 'Left hand grip strength',
  RIGHT_HAND_GRIP_STRENGTH: 'Right hand grip strength',
  LEFT_FOOT_STRENGTH: 'Left foot strength',
  RIGHT_FOOT_STRENGTH: 'Right foot strength',
  OTHER: 'Other',
  AKATHISIA_OTHER: 'Akathisia Other',
  ACUTE_DYSTONIA_OTHER: 'Acute Dystonia Other',
  PSEUDOPARKINSONISM_OTHER: 'Pseudoparkinsonism Other',
  TARDIVE_DYSKINESIA_OTHER: 'Tardive Dyskinesia Other'
};

export type NeurologicalAssessmentMessageType = Partial<Record<keyof typeof NeurologicalAssessmentLanguageKeys, string>>;

export const NeurologicalAssessmentEnUs: NeurologicalAssessmentMessageType = {};

export const NeurologicalAssessmentEnAu: NeurologicalAssessmentMessageType = {};
