export const DeliveryRoomSummaryLanguageKeys = {
  DELIVERY_ROOM_SUMMARY: {
    DELIVERY_ROOM_SUMMARY: 'Delivery Room Summary',
    DAY: 'Day',
    TIME: 'Time',
    SEX: 'Sex',
    TYPE_DELIVERY: 'Type of Delivery',
    TYPE_DELIVERY_OTHER: 'Other',
    COMPLICATIONS_DURING_DELIVERY: 'Any complications during delivery?',
    COMPLICATIONS_DURING_DELIVERY_TEXT: 'Explain complications during delivery',
    COMMENTS: 'Comments',
    MEDICATIONS_TIME: 'Time',
    MEDICATIONS_SITE: 'Site',
    MEDICATIONS_INITIALS: 'Initials',
    MINUTES: 'Minutes',
    HOURS: 'Hours',
    AMOUNT: 'Amount',
    FORMULA_TYPE: 'Type',
    FIRST_WEEK: 'First Week',
    FIRST_STOOL: 'First Stool',
    DESCRIBE: 'Describe',
    NOTES: 'Notes',
    DELIVERY_PLACENTA: 'Delivery of placenta',
    PROVIDER: 'Provider/CNM',
    TYPE: 'Type',
    CORD: 'Cord',
    DELIVERY: 'Delivery',
    DURATION_OF_STAGE_OF_LABOR: 'Duration of Stage of Labor',
    INITIAL_CARE_NEW_BORN: 'Initial Care of New Born',
    INTAKE_OUTPUT: 'Intake/Output',
    GENERAL_NOTES: 'General Notes',
    APGAR_SCALE: 'Apgar Scale',
    HEART_RATE: 'Heart Rate',
    RESPIRATORY_RATE: 'Respiratory Rate',
    MUSCLE_TONE: 'Muscle tone',
    REFLEX_IRRITABILITY: 'Reflex irritability',
    COLOR: 'Color',
    TOTAL_APGAR_SCALE: 'Total Apgar Scale',
    TOTAL_SCORE: 'Total Score',
    MEDICATIONS: 'Medications',
    EYE_PROPHYLACTIC: 'Eye Prophylactic',
    RESUSCITATION: 'Resuscitation',
    ORDER_OF_STAGES: 'Order of Stages',
    VITAMIN_K: 'Vitamin K',
    NALOXONE: 'Naloxone',
    HEPATITIS_B_VACCINE: 'Hepatitis B Vaccine',
    HEPATITIS_B_IMMUNE_GLOBULIN: 'Hepatitis B Immune Globulin (HBIG)',
    NUTRITION: 'Nutrition',
    ELIMINATION: 'Elimination'
  }
};

export type DeliveryRoomSummaryMessageType = Partial<Record<keyof typeof DeliveryRoomSummaryLanguageKeys, string>>;

export const DeliveryRoomSummaryEnUs: DeliveryRoomSummaryMessageType = {
  [DeliveryRoomSummaryLanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN]: 'Initial Care of New Born',
  [DeliveryRoomSummaryLanguageKeys.DELIVERY_ROOM_SUMMARY.APGAR_SCALE]: 'Apgar Scale',
  [DeliveryRoomSummaryLanguageKeys.DELIVERY_ROOM_SUMMARY.FIRST_WEEK]: 'First Week',
  [DeliveryRoomSummaryLanguageKeys.DELIVERY_ROOM_SUMMARY.ORDER_OF_STAGES]: '{order} Stage'
};

export const DeliveryRoomSummaryEnAu: DeliveryRoomSummaryMessageType = {
  [DeliveryRoomSummaryLanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN]: 'Initial Care of Newborn',
  [DeliveryRoomSummaryLanguageKeys.DELIVERY_ROOM_SUMMARY.APGAR_SCALE]: 'Apgar Score',
  [DeliveryRoomSummaryLanguageKeys.DELIVERY_ROOM_SUMMARY.FIRST_WEEK]: 'First Urine',
  [DeliveryRoomSummaryLanguageKeys.DELIVERY_ROOM_SUMMARY.ORDER_OF_STAGES]: '{order} Stage'
};
