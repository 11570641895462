import { LastElement } from 'constants/app.constant';

// shows if save button is disabled/enabled in areas using sidebar component
// Note: if sidebar doesn't use a disabled save button, use constant ENABLED_SAVE_BUTTON from app.constant
export const updateLastFocusableElement = (hasUnsavedChanges: boolean) => {
  let element: number;

  if (!hasUnsavedChanges) {
    element = LastElement.DISABLED_SAVE_BUTTON;
  } else {
    element = LastElement.ENABLED_SAVE_BUTTON;
  }
  return element;
};

export const handleEscapeKey = (event, action: Function): Function | null => {
  // action - what happens when escape key is pressed
  if (event.key === 'Escape') {
    return action(event);
  }
  return null;
};
