export const RespiratoryAssessmentLanguageKeys = {
  RESPIRATORY_ASSESSMENT: {
    AIRWAY_IN_USE: 'Airway in use',
    AIRWAY_OTHER: 'Airway other',
    AIRWAY_TYPE: 'Airway type',
    AMOUNT_IN_LMIN: 'Amount in L/min',
    AMOUNT_IN_PERCENT: 'Amount in %',
    AMOUNT_OTHER: 'Amount other',
    AMOUNT: 'Amount',
    ARTIFICIAL_AIRWAY: 'Artificial Airway',
    BREATH_SOUNDS: 'Mark the breath sounds heard at this location',
    BREATHS_MIN: 'Breaths/min',
    CHEST_TUBE: 'Chest Tube',
    COLOR_OTHER: 'Color other',
    COLOR: 'Color',
    COMMENTS: 'Comments',
    CONSISTENCY_OTHER: 'Consistency other',
    CONSISTENCY: 'Consistency',
    COUGH_STRENGTH: 'Cough strength',
    COUGH_TYPE: 'Cough type',
    COUGH: 'Cough',
    DOCUMENT_ASSESSMENT_AND_CARE_ON_GRAPHICAL_BODY_DRAINS_TUBES: 'Document assessment and care on graphical body: Drains/Tubes',
    GAG_REFLEX: 'Gag Reflex',
    INFO_TEXT: 'Fill out data on each Lung Auscultation location and use the "Add Location" button before saving the entire chart.',
    INSPECTION: 'Inspection',
    LOCATION: 'Location',
    LUNG_AUSCULTATION: 'Lung Auscultation',
    ODOR_OTHER: 'Odor other',
    ODOR: 'Odor',
    OTHER: 'Other',
    OXYGEN_DELIVERY_SYSTEM: 'Oxygen delivery system',
    OXYGEN_IN_USE: 'Oxygen in use',
    OXYGEN_SATURATION_SPO2: 'Oxygen saturation SpO₂',
    OXYGENATION_EVALUATION: 'Oxygenation evaluation',
    OXYGENATION_INCENTIVE: 'Incentive spirometer best effort (mL)',
    OXYGENATION: 'Oxygenation',
    POSITION: 'Position',
    PRODUCTIVE_COUGH_ASSESSMENT: 'Productive Cough Secretions Assessment',
    RESPIRATORY_ASSESSMENT: 'Respiratory Assessment',
    RESPIRATORY_EFFORT: 'Respiratory Effort',
    RESPIRATORY_PATTERN: 'Respiratory Pattern',
    RESPIRATORY_RATE: 'Respiratory Rate',
    RESPIRATORY_SUPPORT: 'Respiratory/breathing support',
    SECRETION_ODOR: 'Secretion odor',
    SIZE: 'Size',
    SUCTIONING: 'Suctioning',
    TYPE_OF_AIRWAY: 'Type of airway',
    WHEEZE_DESCRIPTION: 'Wheeze Description'
  }
};

export type RespiratoryAssessmentMessageType = Partial<Record<keyof typeof RespiratoryAssessmentLanguageKeys, string>>;

export const RespiratoryAssessmentEnUs: RespiratoryAssessmentMessageType = {
  [RespiratoryAssessmentLanguageKeys.RESPIRATORY_ASSESSMENT.AIRWAY_IN_USE]: '',
  [RespiratoryAssessmentLanguageKeys.RESPIRATORY_ASSESSMENT.ARTIFICIAL_AIRWAY]: 'Artificial Airway',
  [RespiratoryAssessmentLanguageKeys.RESPIRATORY_ASSESSMENT.COLOR_OTHER]: 'Color other',
  [RespiratoryAssessmentLanguageKeys.RESPIRATORY_ASSESSMENT.COLOR]: 'Color',
  [RespiratoryAssessmentLanguageKeys.RESPIRATORY_ASSESSMENT.ODOR_OTHER]: 'Odor other',
  [RespiratoryAssessmentLanguageKeys.RESPIRATORY_ASSESSMENT.ODOR]: 'Odor',
  [RespiratoryAssessmentLanguageKeys.RESPIRATORY_ASSESSMENT.SECRETION_ODOR]: 'Secretion odor'
};

export const RespiratoryAssessmentEnAu: RespiratoryAssessmentMessageType = {
  [RespiratoryAssessmentLanguageKeys.RESPIRATORY_ASSESSMENT.AIRWAY_IN_USE]: 'Airway in use',
  [RespiratoryAssessmentLanguageKeys.RESPIRATORY_ASSESSMENT.ARTIFICIAL_AIRWAY]: 'Airway',
  [RespiratoryAssessmentLanguageKeys.RESPIRATORY_ASSESSMENT.COLOR_OTHER]: 'Colour other',
  [RespiratoryAssessmentLanguageKeys.RESPIRATORY_ASSESSMENT.COLOR]: 'Colour',
  [RespiratoryAssessmentLanguageKeys.RESPIRATORY_ASSESSMENT.ODOR_OTHER]: 'Odour other',
  [RespiratoryAssessmentLanguageKeys.RESPIRATORY_ASSESSMENT.ODOR]: 'Odour',
  [RespiratoryAssessmentLanguageKeys.RESPIRATORY_ASSESSMENT.SECRETION_ODOR]: 'Secretion odour'
};
