export const GastrointestinalAssessmentLanguageKeys = {
  GASTROINTESTINAL_ASSESSMENT: {
    GUMS_AND_MOUTH: 'Gums and mouth',
    TEETH: 'Teeth',
    TENDER_ON_PALPATION: 'If tender on palpation, indicate region',
    BOWEL_SOUNDS_OTHER: 'Other',
    DATE_OF_LAST_BOWEL_MOVEMENT: 'Date of last bowel movement',
    CONTINENCE_OF_BOWEL: 'Continence of bowel',
    GASTROINTESTINAL_ASSESSMENT: 'Gastrointestinal Assessment',
    MOUTH_GUMS_TEETH: 'Mouth/Gums/Teeth',
    ESOPHAGEAL_ASSESSMENT: 'Esophageal Assessment',
    ABDOMEN: 'Abdomen',
    GASTROINTESTINAL: 'Gastrointestinal',
    INTESTINAL: 'Intestinal',
    BOWEL_SOUNDS: 'Bowel Sounds',
    INTESTINAL_ASSESSMENT: 'Intestinal Assessment',
    RECTUM_CHARACTERISTICS: 'Rectum characteristics',
    STOOL_CHARACTERISTICS: 'Stool characteristics',
    INTESTINAL_DIVERSION: 'Intestinal diversion'
  }
};

export type GastrointestinalAssessmentMessageType = Partial<Record<keyof typeof GastrointestinalAssessmentLanguageKeys, string>>;

export const GastrointestinalAssessmentEnUs: GastrointestinalAssessmentMessageType = {
  [GastrointestinalAssessmentLanguageKeys.GASTROINTESTINAL_ASSESSMENT.ESOPHAGEAL_ASSESSMENT]: 'Esophageal Assessment'
};

export const GastrointestinalAssessmentEnAu: GastrointestinalAssessmentMessageType = {
  [GastrointestinalAssessmentLanguageKeys.GASTROINTESTINAL_ASSESSMENT.ESOPHAGEAL_ASSESSMENT]: 'Oesophageal Assessment'
};
