import { ELSAdobeAnalyticService, ELSCommonConfig } from '@els/els-ui-common-react';
import { Analytics, AppConstant } from 'constants/app.constant';
import { facadeApi } from 'helpers/api.helper';
import { cacheService, logService } from 'services';
import { ELSTokenHelper } from 'components/common/els';

export const setUserPageData = (userData) => {
  window.pageData.visitor.details = userData.userRole ? userData.userRole.toLowerCase() : '';
  window.pageData.visitor.userId = `eols:${userData.id}`;

  const adobeUrl = `/content/adobe/`;
  const token = localStorage.getItem(AppConstant.TOKEN_KEY);
  const { user } = ELSTokenHelper.parseToken(token);
  let accountName = 'eols:';
  let accountId = 'eols:';

  if (user?.appParams?.courseId) {
    cacheService.withCache('adobeAccount', () =>
      facadeApi
        .get(adobeUrl + user.appParams.courseId)
        .then((result) => {
          accountName += result.data.institution.name;
          accountId += result.data.institution.id;

          window.pageData.visitor.accountId = accountId;
          window.pageData.visitor.accountName = accountName;

          ELSAdobeAnalyticService.registerAdobeAnalytic(`${ELSCommonConfig.buildUrl}/api/features/app/EXAMPREP/key/ADOBE_ANALYTICS_DISABLED`, {
            ...Analytics.defaultAnalyticsConfig,
            userId: `eols:${userData.id}`,
            accountId,
            accountName
          });
          return result;
        })
        .catch((err) => {
          logService.log(`call adobe error ${err}`);
          return { res: { data: null } };
        })
    );
  }
};
