export enum UserRole {
  STUDENT = 'Stu',
  INSTRUCTOR = 'Ins',
  ADMIN = 'Adm'
}

export enum LinkingAction {
  SIMSNG_BACK_SHERPATH = 'SIMSNG_BACK_SHERPATH',
  SIMSNG_BACK_COURSEWARE = 'SIMSNG_BACK_COURSEWARE',
  STUDENT_LANDING_PAGE = 'STUDENT_LANDING_PAGE',
  STUDENT_LAUNCH_ASSIGNMENT = 'STUDENT_LAUNCH_ASSIGNMENT',
  STUDENT_ASSIGNMENT_ACCESS_CLINICAL = 'STUDENT_ASSIGNMENT_ACCESS_CLINICAL',
  INSTRUCTOR_ADD_ASSIGNMENT = 'INSTRUCTOR_ADD_ASSIGNMENT',
  INSTRUCTOR_VIEW_ASSIGNMENT = 'INSTRUCTOR_VIEW_ASSIGNMENT',
  INSTRUCTOR_EDIT_ASSIGNMENT = 'INSTRUCTOR_EDIT_ASSIGNMENT',
  INSTRUCTOR_GRADE_ASSIGNMENT_LANDING = 'INSTRUCTOR_GRADE_ASSIGNMENT_LANDING',
  INSTRUCTOR_AUTHOR_LANDING_PAGE = 'INSTRUCTOR_AUTHOR_LANDING_PAGE'
}

export enum AssignmentType {
  CASE_STUDY = 'CASE_STUDY',
  EMPTY_EHR = 'EMPTY_EHR',
  SIMULATION = 'SIMULATION'
}

export enum HOSPITAL_FLOOR {
  MEDSURG = 'MEDSURG',
  PEDIATRIC = 'PEDIATRIC',
  PSYCH = 'PSYCH',
  WOMAN_HEALTH = 'WOMANHEALTH'
}

export enum GradingOption {
  NOT_GRADED = 'NOT_GRADED',
  PASS_FAIL = 'PASS_FAIL',
  SCORED = 'SCORED'
}

export enum GradingResult {
  PASS = 'PASS',
  FAIL = 'FAIL',
  NA = 'NA'
}

export enum AssignmentStatus {
  STARTED = 'STARTED',
  PAST_DUE = 'PAST_DUE'
}

export enum AssessmentStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  COMPLETED_LATE = 'COMPLETED_LATE',
  PAST_DUE = 'PAST_DUE'
}

export enum FormFieldInputType {
  CONTAINER = 'CONTAINER',
  CHECK_BOX = 'CHECK_BOX',
  DATE = 'DATE',
  DROPDOWN = 'DROPDOWN',
  MULTISELECT_DROPDOWN = 'MULTISELECT_DROPDOWN',
  RADIO_CHOICE = 'RADIO_CHOICE',
  TEXT_AREA = 'TEXT_AREA',
  TEXT_BLOCK = 'TEXT_BLOCK',
  TEXT_BOX = 'TEXT_BOX',
  TIME = 'TIME',
  MULTI_SELECT_RADIO = 'MULTI_SELECT_RADIO',
  SCALE = 'SCALE',
  IMAGE = 'IMAGE'
}

export enum FormFieldDataType {
  CHOICE = 'CHOICE',
  NESTED_CHOICE = 'NESTED_CHOICE',
  INFO = 'INFO',
  DECORATOR = 'DECORATOR',
  SCALE = 'SCALE',
  SCALE_QUESTION = 'SCALE_QUESTION',
  SCALE_QUESTION_CONTEXT = 'SCALE_QUESTION_CONTEXT',
  SCALE_ROLL_UP = 'SCALE_ROLL_UP',
  SCALE_ROLL_UP_HIGHLIGHT = 'SCALE_ROLL_UP_HIGHLIGHT'
}

export enum ValidationActionType {
  DISABLE = 'DISABLE',
  HIDE = 'HIDE',
  VALIDATION = 'VALIDATION',
  ERROR = 'ERROR',
  ALERT = 'ALERT',
  WARNING = 'WARNING',
  REQUIRED = 'REQUIRED',
  PREFILL = 'PREFILL'
}

export enum ValidationRuleType {
  CHART_CONTENT_SELECTED = 'CHART_CONTENT_SELECTED',
  CHART_CONTENT_NOT_SELECTED = 'CHART_CONTENT_NOT_SELECTED',
  RANGE = 'RANGE',
  NOT_IN_RANGE = 'NOT_IN_RANGE',
  REGEX_MATCH = 'REGEX_MATCH',
  REGEX_NOT_MATCH = 'REGEX_NOT_MATCH',
  EMPTY = 'EMPTY',
  NON_EMPTY = 'NON_EMPTY',
  ALWAYS_TRUE = 'ALWAYS_TRUE',
  ALWAYS_FALSE = 'ALWAYS_FALSE',
  TIME_BEFORE = 'TIME_BEFORE',
  TIME_AFTER = 'TIME_AFTER',
  IS_FUTURE_DATE = 'IS_FUTURE_DATE',
  IS_PAST_DATE = 'IS_PAST_DATE',
  NOT_FUTURE_DATE = 'NOT_FUTURE_DATE'
}

export enum MultiSelectType {
  SINGLE = 'single',
  MULTIPLE = 'multiple'
}

export enum PhaseType {
  OVERVIEW = 'OVERVIEW',
  PHASE = 'PHASE'
}

export enum PhaseProgress {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED'
}

export enum LocationType {
  OVERVIEW = 'OVERVIEW_INSTRUCTIONS',
  PHASE_QUESTION = 'PHASE_QUESTION',
  PHASE_CHART = 'PHASE_CHART',
  OVERALL_ASSIGNMENT = 'OVERALL_ASSIGNMENT'
}

export enum EventType {
  START = 'START',
  END = 'END'
}

export enum StepProgress {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED'
}

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female'
}

export enum GenderPatientContext {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export enum QuestionType {
  MULTIPLE_CHOICES_SINGLE_ANSWER = 'MCSA',
  MULTIPLE_CHOICES_MULTIPLE_ANSWERS = 'MCMA'
}

export enum ScaleDirection {
  HORIZONTAL = 'horizontal',
  VERTICALLY = 'vertically'
}

export enum FragmentType {
  CHARTING = 'CHARTING',
  AUTHORED = 'AUTHORED',
  GRADE = 'GRADE',
  STATUS = 'STATUS'
}

export enum DemoSource {
  CASE_STUDY_DEMO = 'CASE_STUDY_DEMO',
  ASSIGNMENT_CREATION_QUICK_VIEW = 'ASSIGNMENT_CREATION_QUICK_VIEW'
}

export enum FragmentStatus {
  ACTIVE = 'Active',
  DISCONTINUED = 'Discontinued',
  HEALED = 'Healed',
  REINFORCED = 'Reinforced',
  COMPLETED = 'Complete',
  INITIAL = 'Initial',
  GIVEN = 'Given'
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export enum AssignmentTargetType {
  COURSE = 'COURSE',
  STUDENT = 'STUDENT'
}

export enum FallRiskLevel {
  LOW_RISK = 'Low Risk',
  MEDIUM_RISK = 'Medium Risk',
  HIGH_RISK = 'High Risk'
}
