import cx from 'classnames';
import { ReactNode } from 'react';

interface BoxProps {
  id?: string;
  className?: string;
  children?: ReactNode;
  m?: boolean;
  mt?: boolean;
  mr?: boolean;
  mb?: boolean;
  ml?: boolean;
  mx?: boolean;
  my?: boolean;
  m2?: boolean;
  mt2?: boolean;
  mr2?: boolean;
  mb2?: boolean;
  ml2?: boolean;
  mx2?: boolean;
  my2?: boolean;
  m3?: boolean;
  mt3?: boolean;
  mr3?: boolean;
  mb3?: boolean;
  ml3?: boolean;
  mx3?: boolean;
  my3?: boolean;
  mt4?: boolean;
  mb4?: boolean;
  p?: boolean;
  pt?: boolean;
  pr?: boolean;
  pb?: boolean;
  pl?: boolean;
  px?: boolean;
  py?: boolean;
  p2?: boolean;
  pt2?: boolean;
  pr2?: boolean;
  pb2?: boolean;
  pl2?: boolean;
  px2?: boolean;
  py2?: boolean;
  p3?: boolean;
  pt3?: boolean;
  pr3?: boolean;
  pb3?: boolean;
  pl3?: boolean;
  px3?: boolean;
  py3?: boolean;
}

const Box = (props: BoxProps) => {
  const classNames = cx({
    'u-els-margin': props.m,
    'u-els-margin-top': props.mt,
    'u-els-margin-right': props.mr,
    'u-els-margin-bottom': props.mb,
    'u-els-margin-left': props.ml,
    'u-els-margin-left u-els-margin-right': props.mx,
    'u-els-margin-top u-els-margin-bottom': props.my,

    'u-els-margin-2x': props.m2,
    'u-els-margin-top-2x': props.mt2,
    'u-els-margin-right-2x': props.mr2,
    'u-els-margin-bottom-2x': props.mb2,
    'u-els-margin-left-2x': props.ml2,
    'u-els-margin-left-2x u-els-margin-right-2x': props.mx2,
    'u-els-margin-top-2x u-els-margin-bottom-2x': props.my2,

    'u-els-margin-3x': props.m3,
    'u-els-margin-top-3x': props.mt3,
    'u-els-margin-right-3x': props.mr3,
    'u-els-margin-bottom-3x': props.mb3,
    'u-els-margin-left-3x': props.ml3,
    'u-els-margin-left-3x u-els-margin-right-3x': props.mx3,
    'u-els-margin-top-3x u-els-margin-bottom-3x': props.my3,

    'u-els-margin-top-4x': props.mt4,
    'u-els-margin-bottom-4x': props.mb4,

    'u-els-padding': props.p,
    'u-els-padding-top': props.pt,
    'u-els-padding-right': props.pr,
    'u-els-padding-bottom': props.pb,
    'u-els-padding-left': props.pl,
    'u-els-padding-left u-els-padding-right': props.px,
    'u-els-padding-top u-els-padding-bottom': props.py,

    'u-els-padding-2x': props.p2,
    'u-els-padding-top-2x': props.pt2,
    'u-els-padding-right-2x': props.pr2,
    'u-els-padding-bottom-2x': props.pb2,
    'u-els-padding-left-2x': props.pl2,
    'u-els-padding-left-2x u-els-padding-right-2x': props.px2,
    'u-els-padding-top-2x u-els-padding-bottom-2x': props.py2,

    'u-els-padding-3x': props.p3,
    'u-els-padding-top-3x': props.pt3,
    'u-els-padding-right-3x': props.pr3,
    'u-els-padding-bottom-3x': props.pb3,
    'u-els-padding-left-3x': props.pl3,
    'u-els-padding-left-3x u-els-padding-right-3x': props.px3,
    'u-els-padding-top-3x u-els-padding-bottom-3x': props.py3
  });
  return (
    <div id={props.id} className={cx(classNames, props.className)}>
      {props.children}
    </div>
  );
};

Box.displayName = 'Box';
export default Box;
